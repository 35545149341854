import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth.module';
import { affiliate } from '@/store/affiliates.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    project: sessionStorage.getItem('projectName'),
    projectIcon: sessionStorage.getItem('projectIcon'),
    projectId: sessionStorage.getItem('projectId'),
    uniqueEvent: sessionStorage.getItem('uniqueEventId'),
  },
  mutations: {
    setProject(state, newProject) {
      state.project = newProject.text;
      state.projectIcon = newProject.icon;
      state.projectId = newProject.value;
      sessionStorage.setItem('projectName', newProject.text);
      sessionStorage.setItem('projectIcon', newProject.icon);
      sessionStorage.setItem('projectId', newProject.value);
    },
    clearProject(state) {
      state.project = null;
      state.projectIcon = null;
      state.projectId = null;
      sessionStorage.removeItem('projectName');
      sessionStorage.removeItem('projectIcon');
      sessionStorage.removeItem('projectId');
    },
    setUniqueEventId(state, newUniqueEvent) {
      state.uniqueEvent = newUniqueEvent;
      sessionStorage.setItem('uniqueEventId', newUniqueEvent);
    },
  },
  actions: {
  },
  modules: {
    auth,
    affiliates: affiliate,
  },
});
