<!-- Vue template view for accesibility -->
<template>
    <v-container class="accesibility-container">
        <section class="main-container">
            <div class="bg-image">
                <img src="@/assets/images/fondo-movil.svg" alt="">
            </div>
            <div class="main-container--inner pt-3">
                <v-card :elevation="$getCardOptions('elevation')"
                    class="card dashboard_box full-card dashboard_column_grid colorGreen">
                    <v-card-text class="text-center ">
                        <img alt="App" src="../../assets/logo.png" style="height: 100px" />
                    </v-card-text>
                </v-card>
                <v-card :elevation="$getCardOptions('elevation')"
                    class="card dashboard_box full-card dashboard_column_grid colorGreen">
                    <v-card-text class="text-center ">
                        Financiado con fondos Next Generation EU de la Unión Europea. <br>
                    </v-card-text>
                </v-card>
                <v-card :elevation="$getCardOptions('elevation')"
                    class="card dashboard_box full-card dashboard_column_grid colorGreen">
                    <v-card-text class="text-center ">
                        <img src="@/assets/images/LOGO_COLOR.jpg" alt="" style="height: 200px;" />
                    </v-card-text>
                </v-card>
                <v-card :elevation="$getCardOptions('elevation')"
                    class="card dashboard_box full-card dashboard_column_grid colorGreen">
                    <v-card-text class="text-center ">
                        <img src="@/assets/images/ES Financiado por la Unión Europea_PANTONE.jpg"
                        style="height: 100px;"  alt="" />
                    </v-card-text>
                </v-card>
                <!-- footer container for footer links -->
            </div>
        </section>
        <!-- vuetify container for accesibility long text (texto en español) -->
        <v-row>
            <v-col cols="12" md="12">

                <!-- div container for display two images in a row (flex)-->

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'kit-digital-view',
};
</script>

<style scoped></style>
