<template>
  <div>
    <div class="cantidad-noticias-container">
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon :color="$getColor('vuetifyColor1')" x-large>celebration</v-icon>
        <span class="noticias-total">{{ summaryData.total }}</span>
        <span class="noticias-text">Total de Eventos</span>
      </div>
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <v-icon :color="$getColor('vuetifyColor1')" x-large>calendar_month</v-icon>
        <span class="noticias-total">{{ summaryData.lastYear }}</span>
        <span class="noticias-text">Eventos en los últimos 12 meses</span>
      </div>
      <div class="dashboard_box small-box box-1 two-col one-row noticias-box">
        <img alt="App" src="../../assets/logo.png"
              style="height: 50px" />
      </div>
    </div>
    <div class="charts-grid">
      <div class="chart-noticias">
        <div class="dashboard_box tabs">
          <v-tabs background-color="transparent" grow :color="$getColor('vuetifyColor1')">
            <v-tab> Últimos 12 meses </v-tab>
          </v-tabs>
        </div>
        <div class="dashboard_box chart" id="HistoricChart">
          <StandardYearChart ref="inscriptionHistoricChart" />
        </div>
      </div>
      <div class="chart-evaluadas">
        <div class="dashboard_box tabs">
          <v-tabs v-model="tabRight" background-color="transparent"
          :color="$getColor('vuetifyColor1')" grow>
            <v-tab :key="0">Tipo</v-tab>
            <v-tab :key="1">Cupones</v-tab>
          </v-tabs>
        </div>
      <div class="dashboard_box chart">
          <StandardPieChart ref="pieChart" containerId="pieChartId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';
import StandardYearChart from '@/components/Charts/AppointmentsHistoricChart.vue';
import StandardPieChart from '@/components/Charts/StandardPieChart.vue';

const axios = require('axios');

export default {
  name: 'appointment-statistics-panel',
  data() {
    return {
      tabRight: 0,
      summaryData: {
        total: 0,
        lastYear: 0,
        lastMonth: 0,
        currentMonth: 0,
      },
      inscriptionHistoricData: {
        totalAfiliados: [],
        totalSimpatizantes: [],
        totalColaboradores: [],
        totalNull: [],
      },
      populationData: null,
      couponsData: null,

      newsHistoricData: {
        totalNoticias: [],
      },
      datasets: [],
      info: null,
      loading: true,
      errored: false,
      userId: null,
      userRole: null,
      userName: null,
      user: [],
    };
  },
  computed: {
    newsLastYear() {
      return this.newsHistoricData.totalNoticias.reduce((a, b) => a + b, 0);
    },
    newsLastMonth() {
      const currentMonth = new Date().getMonth();
      return this.newsHistoricData.totalNoticias[currentMonth];
    },
  },
  components: {
    StandardYearChart,
    StandardPieChart,
  },

  mounted() {
    /*
    if (this.$store.state.project != null) {
      this.userId = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).affiliate;
      this.userRole = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).rol;
      this.userName = authService.getDecodedJwtToken(
        this.$store.state.auth.user,
      ).sub;
      this.loadData();
    } else {
      this.$router.push('/projects');
    }
    */
    this.userId = authService.getDecodedJwtToken(
      this.$store.state.auth.user,
    ).affiliate;
    this.userRole = authService.getDecodedJwtToken(
      this.$store.state.auth.user,
    ).rol;
    this.userName = authService.getDecodedJwtToken(
      this.$store.state.auth.user,
    ).sub;
    this.loadData();
  },
  methods: {
    loadData() {
      this.getTypeData();
      this.loadHistoricData();
      this.getCouponsData();
    },
    getType(type) {
      const options = this.$getInscriptionTypeValues(type);
      return options;
    },
    getRequestParams() {
      const params = {};
      return params;
    },

    getCouponsData() {
      const params = {};
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/stats/coupons/`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.couponsData = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTypeData() {
      const params = {};
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/stats/type/`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.populationData = response.data;
          this.summaryData.total = response.data.reduce((a, b) => a + b[1], 0);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.loadTypeData();
        });
    },

    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getVariationColor(color, variation) {
      const letters = '0123456789ABCDEF';
      let newColor = '#';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        const index = letters.indexOf(color[i]);
        const newIndex = index + variation;
        if (newIndex < 0) {
          newColor += letters[0];
        } else if (newIndex > 15) {
          newColor += letters[15];
        } else {
          newColor += letters[newIndex];
        }
      }
      return newColor;
    },

    loadCouponsData() {
      const values = [];
      const labels = [];
      const colors = [];
      this.couponsData.forEach((element, index) => {
        if (element[0]) {
          const options = element[0];
          values.push(element[1]);
          labels.push(options.code);
          colors.push(this.getVariationColor(this.$getColor('vuetifyColor5'), index + 10));
        } else {
          values.push(element[1]);
          labels.push('No Disponible');
          colors.push('#ccc');
        }
      });
      this.$refs.pieChart.updateValues(values, labels, colors);
    },

    loadTypeData() {
      const values = [];
      const labels = [];
      const colors = [];
      this.populationData.forEach((element) => {
        if (element[0]) {
          const options = this.$getAppointmentTypeValues(element[0]);
          values.push(element[1]);
          labels.push(options.label);
          colors.push(options.color);
        } else {
          values.push(element[1]);
          labels.push('Not Available');
          colors.push('#ccc');
        }
      });
      this.$refs.pieChart.updateValues(values, labels, colors);
    },

    loadHistoricData() {
      const params = {};
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/stats/historic/`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          const allOptions = this.$getValues('appointmentType');
          Object.values(allOptions).forEach((option) => {
            this.datasets.push({
              label: option.label,
              backgroundColor: option.color,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              borderColor: option.color,
              borderWidth: 0,
            });
          });

          response.data.forEach((element) => {
            const claveMonth = element[0];
            const claveInscription = element[1];
            const valor = element[2];
            if (claveMonth) {
              // find in datasets the index of the inscriptionType
              const index = this.datasets.findIndex(
                (dataset) => dataset.label
                === this.$getAppointmentTypeValues(claveInscription).label,
              );
              this.datasets[index].data[claveMonth - 1] = valor;
            }
          });
          this.$refs.inscriptionHistoricChart.updateDatasets(this.datasets);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.getCounts();
        });
    },
    moveArray(arr, offset) {
      return arr.slice(offset).concat(arr.slice(0, offset));
    },
    getCounts() {
      // get current month
      const currentMonth = new Date().getMonth() + 1;
      let newDataset = [];
      newDataset = this.datasets;

      newDataset.forEach((element) => {
        const newValues = this.moveArray(element.data, currentMonth);
        // eslint-disable-next-line no-param-reassign
        element.data = newValues;
      });
      // loop through datasets
      newDataset.forEach((dataset) => {
        // add data of current month to summaryData.currentMonth
        this.summaryData.currentMonth += dataset.data[currentMonth];
        // add data from last item of array to summaryData.currentMonth
        this.summaryData.currentMonth += dataset.data[dataset.data.length - 1];
        // add data of current month to summaryData.lastMonth
        this.summaryData.lastMonth += dataset.data[currentMonth - 1];
        // add data of current month to summaryData.total
        this.summaryData.lastYear += dataset.data.reduce((a, b) => a + b, 0);
      });
    },
  },

  watch: {
    tabRight(val) {
      if (val === 1) {
        this.loadCouponsData();
      } else if (val === 0) {
        this.loadTypeData();
      }
    },
  },
};
</script>

<style scoped>
.item {
  margin-bottom: 2rem;
}
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}
.card-filter-button:hover {
  background-color: #fff;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
</style>
<style lang="scss" scoped>
.charts-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;
  height: 100%;
}
.dashboard_box.tabs {
  margin-bottom: 1em;
}

.noticias-box {
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;

  .noticias-total {
    font-size: 1.71em;
    color: var(--bodyColor);
  }
  .noticias-text {
    line-height: 1.1;
    // max-width: 8em;
    text-align: center;
  }
}
.chart-noticias {
  grid-column: 1 / span 8;
  height: 100%;

  .chart {
    padding: 1em;
  }
}
.chart-evaluadas {
  grid-column: 9 / span 4;
  height: 100%;

  .chart {
    padding: 2em;
  }
}
.cantidad-noticias-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1em;
  & > div {
    grid-column: span 4;
    min-height: 150px;
  }
  // flex-direction: column;
  // justify-content: space-between;
  // align-content:space-between;
}
// * {
//   border: solid 1px blue;
// }
</style>
