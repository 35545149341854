<template>
  <v-dialog v-model="modal" class="dialog-wrapper" width="75%" scrollable max-width="450px">
    <v-card>
      <v-card-title> <span class="text-h5 mb-2">Editar datos del evento</span></v-card-title>
      <!-- <section-header first="Editar" second="Los datos del evento" /> -->
      <v-card-text style="height: 400px; position: relative">
        <v-form action="">
          <div class="input-field">
            <label class="input-field--label">Fecha del evento: </label>
            <v-dialog
              ref="datePicker"
              v-model="datePicker"
              :return-value.sync="datePickerModel"
              persistent
              width="300px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDisplayDate"
                  placeholder="Modifica la fecha del evento"
                  hide-details
                  outlined
                  dense
                  color="black"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datePickerModel" locale="es" first-day-of-week="1">
                <v-spacer></v-spacer>
                <div style="display: flex; width: 100%">
                  <v-btn style="flex: 1" text color="primary" @click="datePicker = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    style="flex: 1"
                    text
                    color="primary"
                    @click="$refs.datePicker.save(datePickerModel)"
                  >
                    OK
                  </v-btn>
                </div>
              </v-date-picker>
            </v-dialog>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Nombre de la pareja: </label>
            <v-text-field
              placeholder="Nombre de la pareja"
              outlined
              dense
              color="black"
              v-model="user.namePartners"
              :rules="[$v.user.namePartners.required]"
              :disabled="disabled"
              hide-details
            ></v-text-field>
          </div>

          <div class="input-field">
            <label class="input-field--label">Lugar del evento: </label>
            <v-text-field
              placeholder="Lugar del evento"
              outlined
              dense
              color="black"
              v-model="user.namePlace"
              :rules="[$v.user.namePlace.required]"
              :disabled="disabled"
              hide-details
            ></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Dirección de la finca: </label>
            <v-text-field
              placeholder="Dirección de la finca"
              outlined
              dense
              color="black"
              v-model="user.streetPlace"
              :rules="[$v.user.streetPlace.required]"
              :disabled="disabled"
              hide-details
            ></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Teléfono de contacto el día de la boda: </label>
            <v-text-field
              placeholder="Teléfono de contacto el día de la boda"
              outlined
              dense
              color="black"
              v-model="user.phone2"
              :rules="[$v.user.phone2.required]"
              :disabled="disabled"
              type="number"
              hide-spin-buttons
              hide-details
            ></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Número aproximado de invitados: </label>
            <v-text-field
              placeholder="Número aproximado de invitados"
              outlined
              dense
              color="black"
              v-model="user.guestsNumber"
              :rules="[$v.user.guestsNumber.required]"
              :disabled="disabled"
              type="number"
              hide-details
            ></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label">Hora aproximada: </label>
            <v-text-field
              placeholder="Hora aproximada"
              outlined
              dense
              color="black"
              v-model="user.time"
              :disabled="disabled"
              hide-details
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="navigation-buttons">
          <primary-button
            appearance="text"
            text="Cancelar"
            size="small"
            v-on:click.native="openModal"
            :enabled="true"
          />
          <primary-button
            appearance="solid"
            text="Guardar"
            size="small"
            v-on:click.native="updateInfo"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
// import SectionHeader from '@/components/Headers/SectionHeader.vue';

import authHeader from '@/services/auth-header';
import axios from 'axios';

export default {
  name: 'update-info-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // SectionHeader,
    PrimaryButton,
  },
  data: () => ({
    modal: false,
    time: false,
    datePicker: false,
    user: {
      namePartners: null,
      lastName1: null,
      namePlace: null,
      streetPlace: null,
      phone2: null,
      guestsNumber: null,
      time: null,
      appointmentId: null,
      eventDate: null,
    },
    testDate: '2022-12-12',
  }),
  validations() {
    const valObj = {
      user: {
        namePartners: { required },
        lastName1: { required },
        namePlace: { required },
        streetPlace: { required },
        phone2: { required },
        guestsNumber: { required },
      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
  },
  watch: {
    user: {
      handler() {
        this.updatePersonalData();
      },
      deep: true,
    },
    userValues: {
      handler() {
        this.user = this.userValues;
      },
      deep: true,
    },
  },
  computed: {
    datePickerModel: {
      get() {
        // Retorna solo la fecha en formato YYYY-MM-DD para el DatePicker
        return this.user.eventDate ? this.user.eventDate.split('T')[0] : '';
      },
      set(value) {
        // Actualiza user.eventDate con solo la fecha, sin hora
        this.user.eventDate = `${value}`;
      },
    },
    formattedDisplayDate() {
      if (!this.user.eventDate) return '';
      const date = new Date(this.user.eventDate).toLocaleDateString('es-ES', {
        timeZone: 'Europe/Madrid',
      });
      return date;
    },
  },
  methods: {
    openModal() {
      this.modal = !this.modal;
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updatePersonalData() {
      this.$emit('updateData', this.user);
      this.updatePersonalValid();
    },
    updatePersonalValid() {
      this.$emit('updateValid', this.$v);
    },

    updateInfo() {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/info/${this.user.appointmentId}`,
          {
            namePartners: this.user.namePartners,
            phone: this.user.phone2,
            guestsNumber: this.user.guestsNumber,
            name: this.user.namePlace,
            streetPlace: this.user.streetPlace,
            time: this.user.time,
            direction: this.user.direction,
            eventDate: new Date(this.datePickerModel),
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.modal = false;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push('/inscripcion/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// *{
//   border: solid 1px red;
// }

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}
</style>
