<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Cuarto paso" second="Extras" />
        <step-nav :activeStep="4" />
        <preview-canvas :previewImg="previewImg" v-if="isMobile">
        </preview-canvas>
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for="">Añade algunos extras:</label>
          <div class="field-cards-grid">
            <selection-card v-for="item in optionsExtras" :key="item.id" :name="item.name" size="small"
              :price="item.price" :img="item.img" v-on:click.native="setValue(item)" :selected="checkValue(item)" />
          </div>
        </div>
        <prices-component v-if="isMobile && this.prices" :prices="this.prices" :isMobile="true"
          :isPaid="isPaid"></prices-component>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button v-if="!this.$v.$invalid" appearance="solid" text="Siguiente" size="small"
            v-on:click.native="nextStep" />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <preview-canvas :previewImg="previewImg"></preview-canvas>
      <prices-component v-if="this.prices" :prices="this.prices" :isPaid="isPaid"></prices-component>
    </section>
  </div>
  <!--
    //////////////////////////////////////////

    <v-select
      v-model="data.extras"
      :items="optionsExtras"
      label="Add extras"
      :color="$getColor('vuetifyColor1')"
      outlined
      @change="updateData"
      multiple
    ></v-select>
    <v-row>
      <v-img
      v-for="item in optionsExtras"
      :key="item.id"
      :src="item.img"
      :alt="item.text"
      max-width="200"
      max-height="200"
      :class="checkValue(item)"
      @click="setValue(item)"
    >
        <span>{{item.text}}</span>
      </v-img>
    </v-row>
    -->
</template>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import authHeader from '@/services/auth-header';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import StepNav from '@/components/Nav/StepNav.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';

import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import PricesComponent from '@/components/Cards/Prices.vue';

export default {
  name: 'extras-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionHeader,
    PrimaryButton,
    StepNav,
    SelectionCard,
    PreviewCanvas,
    PricesComponent,
  },
  data: () => ({
    modal: false,
    data: {
      extras: [],
    },
    optionsExtras: null,
    prices: null,
  }),
  validations() {
    const valObj = {
      data: {
        // extras: { required },
      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
    this.updateData();
    this.getItems('Extras');
  },
  watch: {
    data: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    previewImg() {
      return this.data.extras.length > 0
        ? this.data.extras[this.data.extras.length - 1].img
        : null;
    },
  },
  methods: {
    updatePrices(val) {
      this.prices = val;
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    setValue(item) {
      if (this.data.extras.some((extra) => extra.id === item.id)) {
        this.data.extras = this.data.extras.filter((extra) => extra.id !== item.id);
      } else {
        this.data.extras.push(item);
      }
    },
    checkValue(item) {
      // Check if includes by extra.id
      return !!(this.data.extras && this.data.extras.some((extra) => extra.id === item.id));
    },
    updateData() {
      this.$emit('updateData', this.data);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    getItems(typeV) {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: typeV,
          },
        }).then((response) => this.setOptions(response.data.content));
    },
    setOptions(response) {
      // map response to options
      this.optionsExtras = response.map((item) => ({
        name: item.name,
        value: item.id,
        img: item.imageUrl,
        id: item.id,
        price: item.price,
      }));
    },
  },

};
</script>
<style scoped>
/*add selected with vuetifyColor*/
.selected {
  border: 2px solid var(--color1);
}
</style>
<style lang="scss" scoped>
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}

.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorRed);
  }

}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorRedRGB),0) 0%, rgba(var(--colorRedRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
