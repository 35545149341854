<template>
  <div>
    <!-- Add progress bar -->
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <div class="download mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on"
            @click="addNew()">
            <v-icon left>add</v-icon>
            Añadir nuevo
          </v-btn>
        </template>
        <span>Añadir nuevo elemento del configurador</span>
      </v-tooltip>
    </div>
    <div>
      <v-tabs v-model="tabMain" background-color="transparent" :color="$getColor('vuetifyColor1')"
        class="dashboard_box users_panel-tab" grow>
        <v-tab v-for="tab in eventTypesArray" :key="tab.name">
          {{ tab.value.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabMain">
        <v-tab-item v-for="item in eventTypesArray" :key="item.name" eager>
          <v-card :elevation="0">
            <v-card-text class="p-0">
              <AppointmentsPropsTable currentSortProp="asc" :ref="'table_' + item.name" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import AppointmentsPropsTable from '@/components/Tables/AppointmentsPropsTable.vue';

export default {
  name: 'appointment-props',
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      eventTypesArray: Object.keys(this.$getValues('appointmentPropsType')).map((key) => ({
        name: key,
        value: this.$getValues('appointmentPropsType')[key],
      })),
      tabMain: null,
      chart: {
        labels: [],
        values: [],
        colors: [],
        icons: [],
      },
    };
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    if (!this.adminAvailable) {
      this.$router.push('/acceso-denegado');
    } else {
      this.tabMain = 0;
      // update all refs
      this.eventTypesArray.forEach((item) => {
        const tabName = item.name;
        const tabRef = this.$refs[`table_${tabName}`];
        // update table
        tabRef[0].updateTypeSelected(tabName);
        // eslint-disable-next-line no-param-reassign
        item.value.loaded = true;
      });
    }
  },
  methods: {
    addNew() {
      this.$router.push({ name: 'Appointment Prop Register' });
    },
  },
  watch: {
    tabMain(val) {
      // get name of tab
      if (!this.eventTypesArray[val].value.loaded) {
        const tabName = this.eventTypesArray[val].name;
        // get ref of tab
        const tabRef = this.$refs[`table_${tabName}`];
        // update table
        tabRef[0].updateTypeSelected(tabName);
        this.eventTypesArray[val].value.loaded = true;
      }
    },
  },
  components: {
    AppointmentsPropsTable,
  },
};
</script>
<style lang="scss" scoped>
.users_panel-tab {
  margin-bottom: 1em;
}

.users_panel-filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  .filters-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--bodyColor);
    margin-right: 1em;
  }
}

.usuarios-totales {
  height: calc(3em + 3px);
  color: var(--bodyColor);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  // margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;

  .usuarios-totales-text {
    font-size: 20px;
  }
}
</style>
