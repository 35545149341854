<template>
  <div>
    <Edit-user
    class="download mb-4"
    title="Añadir usuario"
    icon="mdi-plus">
    </Edit-user>
    <div class="users_panel-grid">
      <div class="left-side">
        <v-tabs
          background-color="transparent"
          :color="$getColor('vuetifyColor1')"
          class="dashboard_box users_panel-tab"
          grow
        >
          <v-tab> Usuarios </v-tab>
        </v-tabs>
        <div class="users_panel-filters">
          <div class="filters-text">Filtrar:</div>
          <v-text-field
            dense
            label="User"
            :color="$getColor('vuetifyColor1')"
            placeholder="email"
            outlined
            hide-details
            v-model="filter.username"
          ></v-text-field>
          <property-type-select propertyValue="roles" label="Rol" icon="admin_panel_settings"
                  @updateParent="updateRoleFilter" ref="roleSelect" :dense="true">
          </property-type-select>
          <v-btn text color="rgba(0,0,0,0.6)" @click="resetFilter()">
            <v-icon class="mr-2"> mdi-close</v-icon>Borrar filtros</v-btn
          >
        </div>
        <div class="users_panel-table">
          <users
            @emitCount="updateTotalUsers"
            :username="filter.username"
            :role="filter.role"
          ></users>
        </div>
      </div>
      <div class="right-side">
         <v-tabs
          background-color="transparent"
          :color="$getColor('vuetifyColor1')"
          class="dashboard_box users_panel-tab"
          grow
        >
          <v-tab> Roles </v-tab>
        </v-tabs>
        <div class="chart_box">
          <div class="usuarios-totales dashboard_box">
            <v-icon
              :color="$getColor('vuetifyColor1')"
              class="usuarios-totale-icon mr-2"
              large
              >mdi-account-group</v-icon
            >
            <span class="usuarios-totales-text">
              Total usuarios: {{ totalUsers }}
            </span>
          </div>
          <div class="usuarios-chart dashboard_box">
            <RoleChart :colors="[$getColor('vuetifyColor2'),$getColor('vuetifyColor3'),
            $getColor('vuetifyColor4'),$getColor('vuetifyColor5'),$getColor('vuetifyColor1')]" ref="roleChart" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import authHeader from '@/services/auth-header';
import Users from '@/components/Tables/Users.vue';
import RoleChart from '@/components/Charts/RoleChart.vue';
import EditUser from '@/components/Forms/EditUser.vue';
import PropertyTypeSelect from '@/components/Forms/PropertiesSelect.vue';

const axios = require('axios');

export default {
  name: 'users-manager',
  data() {
    return {
      totalUsers: null,
      filter: {
        username: null,
        role: null,
      },
      roleData: {
        totalUser: 0,
        totalEnlace: 0,
        totalResponsable: 0,
        totalAdmin: 0,
        totalSuperAdmin: 0,
      },
    };
  },
  components: {
    EditUser,
    Users,
    RoleChart,
    PropertyTypeSelect,
  },
  created() {
    this.loadRoleData();
  },
  methods: {
    updateRoleFilter(role) {
      if (role) {
        this.filter.role = this.$getRoleTypeValues(role).value;
      }
    },
    updateTotalUsers(val) {
      this.totalUsers = val;
    },
    resetFilter() {
      this.filter.username = null;
      this.filter.role = null;
      this.$refs.roleSelect.resetValue();
    },
    loadRoleData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}stats/role/`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.roleData.totalUser = 0;
          this.roleData.totalEnlace = 0;
          this.roleData.totalResponsable = 0;
          this.roleData.totalAdmin = 0;
          this.roleData.totalSuperAdmin = 0;

          response.data.forEach((element) => {
            const clave = element[0];
            const valor = element[1];
            if (clave === 'ROLE_USER') {
              this.roleData.totalUser = valor;
            } else if (clave === 'ROLE_ENLACE') {
              this.roleData.totalEnlace = valor;
            } else if (clave === 'ROLE_RESPONSABLE') {
              this.roleData.totalResponsable = valor;
            } else if (clave === 'ROLE_ADMIN') {
              this.roleData.totalAdmin = valor;
            } else if (clave === 'ROLE_SUPER_ADMIN') {
              this.roleData.totalSuperAdmin = valor;
            }
          });

          this.$refs.roleChart.updateRoleData(this.roleData);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.users_panel-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;
  .left-side {
    grid-column: 1 / span 8;
  }
  .right-side {
    grid-column: 9 / span 4;
  }
}
.users_panel-tab {
  margin-bottom: 1em;
  pointer-events: none;
}

.usuarios-totales {
  height: calc(3em + 3px);
  color: var(--bodyColor);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  // margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
  .usuarios-totales-text {
    font-size: 20px;
  }
}
</style>
<style lang="scss">
.users_panel-filters{
  height: calc(var(--base)*6.5);
}

.users_panel-filters .v-input__slot {
  margin-bottom: 0 !important;
}
.users_panel-filters .v-input {
  margin-right: 1em;
}
</style>
