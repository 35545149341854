<template>
    <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
      <section-header first="Ahora necesitamos tus" second="Datos personales" />
      <v-form action="">
        <div class="input-field">
          <label class="input-field--label"> Nombre completo: </label>
          <v-text-field
            placeholder="Introduce tu nombre"
            outlined
            dense
            color="black"
            v-model="user.name"
            :rules="[$v.user.name.required]"
            :disabled="disabled"
            hide-details
          ></v-text-field>
        </div>
        <div class="input-field">
          <label class="input-field--label"> Correo electrónico: </label>
          <v-text-field
            placeholder="Introduce tu correo electrónico"
            outlined
            dense
            color="black"
            v-model="user.email"
            :rules="[$v.user.email.required, $v.user.email.email]"
            :disabled="disabled"
            hide-details
          ></v-text-field>
        </div>
        <div class="input-field">
          <label class="input-field--label"> Teléfono: </label>
          <v-text-field
            placeholder="Introduce tu número de teléfono"
            outlined
            dense
            color="black"
            v-model="user.phone"
            :rules="[$v.user.phone.required]"
            :disabled="disabled"
            type="number"
            hide-details
            hide-spin-buttons
          ></v-text-field>
        </div>
        <div class="input-field">
          <label class="input-field--label"> DNI/CIF: </label>
          <v-text-field
            placeholder="Introduce tu DNI/CIF"
            outlined
            dense
            color="black"
            v-model="user.idDocument"
            :rules="[$v.user.idDocument.required]"
            :disabled="disabled"
            hide-details
          ></v-text-field>
        </div>
        <div class="input-field">
          <label class="input-field--label"> Domicilio: </label>
          <v-text-field
            placeholder="Introduce tu domicilio"
            outlined
            dense
            color="black"
            v-model="user.streetName"
            :rules="[$v.user.streetName.required]"
            :disabled="disabled"
            hide-details
          ></v-text-field>
        </div>
        <div class="field-group">
          <v-checkbox class="checkbox-field" color="accent" v-model="user.termsAccepted">
            <template v-slot:label>
              <div>
                Acepto los
                <a class="link" @click.stop target="_blank" href="https://funpicfotomaton.com/privacidad/">
                  términos y condiciones
                </a>
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-form>
      <div class="navigation-buttons">
        <primary-button
          appearance="text"
          text="Atrás"
          size="small"
          v-on:click.native="backStep"
        />
        <primary-button
          appearance="solid"
          text="Siguiente"
          size="small"
          v-on:click.native="nextStep"
          :enabled="!this.$v.$invalid"
        />
      </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img
          src="@/assets/images/basic-desktop-2.jpeg"
          alt=""
        />
      </div>
    </section>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
// import dniValidation from '../../../utils/validations/dniValidation';

export default {
  name: 'personal-data-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionHeader,
    PrimaryButton,
  },
  data: () => ({
    modal: false,
    user: {
      name: null,
      email: null,
      phone: null,
      idDocument: null,
      streetName: null,
      termsAccepted: false,
    },
  }),
  validations() {
    const valObj = {
      user: {
        name: { required },
        email: { required, email },
        phone: { required },
        idDocument: { required },
        streetName: { required },
        // termsAccepted must be true
        termsAccepted: {
          sameAs: sameAs(() => true),
        },

      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
  },
  watch: {
    user: {
      handler() {
        this.updatePersonalData();
      },
      deep: true,
    },
    userValues: {
      handler() {
        this.user = this.userValues;
      },
      deep: true,
    },
  },

  methods: {
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updatePersonalData() {
      this.$emit('updateData', this.user);
      this.updatePersonalValid();
    },
    updatePersonalValid() {
      this.$emit('updateValid', this.$v);
    },

  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom:auto;
}
.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 8);
}
.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGrey);
  }
}
.aside-container{
  padding: 0 !important;
}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreyRGB),0) 0%, rgba(var(--colorGreyRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
