import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Home from '../views/Home/HomeSimple.vue';
import HomeIndex from '../views/Home/HomeIndex.vue';

// import Dashboard from '../views/Dashboard.vue';
// import SelectProject from '../views/Project/SelectProject.vue';

// import Population from '../views/Population/Population.vue';
// import PopulationNewRegister from '../views/Population/NewRegister.vue';

// import Calendar from '../views/Calendar/Calendar.vue';
// import NewEvent from '../views/Calendar/NewEvent.vue';
// import EventPanel from '../views/Calendar/EventPanel.vue';
// import EventPending from '../views/Calendar/EventPending.vue';
// import EventsTable from '../views/Calendar/EventsTable.vue';

// import ProjectIndex from '../views/Project/ProjectIndex.vue';
// import ProjectInfo from '../views/Project/Information.vue';
// import ProjectParticipants from '../views/Project/ProjectParticipants.vue';
// import ProjectStats from '../views/Project/ProjectStats.vue';
// import ProjectRegister from '../views/Project/ProjectRegister.vue';

// import MailingIndex from '../views/Mailing/MailingIndex.vue';
// import MailingList from '../views/Mailing/MailingList.vue';

import UsersManager from '../views/AdminPanel/UsersManager.vue';
import Activity from '../views/AdminPanel/Activity.vue';
// import Settings from '../views/AdminPanel/Settings.vue';
// import PublicData from '../views/AdminPanel/PublicData.vue';
import AppointmentStats from '../views/Stats/AppointmentStats.vue';
import BudgetStats from '../views/Stats/BudgetStats.vue';

import ActivityPanel from '../views/Panels/ActivityPanel.vue';
// import PopulationPanel from '../views/Panels/PopulationPanel.vue';
// import UserPanel from '../views/Panels/UserPanel.vue';
// import UserChat from '../views/Panels/UserChat.vue';
// import UserNotRegistered from '../views/Panels/UserNotRegistered.vue';
import NotAllowed from '../views/Panels/NotAllowed.vue';

import Validation from '../views/Validation/Validation.vue';
import Reset from '../views/Validation/Reset.vue';
import RegisterCompleted from '../views/Validation/RegisterCompleted.vue';
import RegisterCanceled from '../views/Validation/RegisterCanceled.vue';

// Basic login
// import Login from '../views/Login/StandardLogin.vue';
import Login from '../views/Login/BasicLogin.vue';
import RecoverPasswordGreetings from '../views/Login/RecoverPasswordGreetings.vue';

// new Fun
// import ConfigureAppointment from '../views/Appointment/ConfigureAppointment.vue';
import ConfigureManualAppointment from '../views/Appointment/ConfigureManualAppointment.vue';
import ConfigureFastAppointment from '../views/Appointment/ConfigureFastAppointment.vue';
import ConfigureAppointmentPostPayment from '../views/Appointment/ConfigureAppointmentPostPayment.vue';
import EditAppointmentPostPayment from '../views/Appointment/EditAppointmentPostPayment.vue';
import AppointmentCalendar from '../views/Appointment/AppointmentCalendar.vue';
import AppointmentProps from '../views/Appointment/AppointmentProps.vue';
import AppointmentPropRegister from '../views/Appointment/AppointmentPropRegister.vue';
import Appointments from '../views/Appointment/Appointments.vue';
import AppointmentPanel from '../views/Appointment/AppointmentPanel.vue';
import AppointmentWarehouse from '../views/Appointment/Warehouse.vue';
import ExportAlbum from '../views/Appointment/ExportAlbum.vue';
import ExportExtras from '../views/Appointment/ExportExtras.vue';
import CreateCoupon from '../views/Appointment/CouponRegister.vue';
import Coupons from '../views/Appointment/Coupons.vue';

/**
 * EL FRONT DE HARRY DE LOS CÓDIGOS POSTALES
 */
import PostalCodes from '../views/AdminPanel/PostalCodes.vue';
import PostalCodeRegister from '../views/AdminPanel/PostalCodeRegister.vue';

import UserPanel from '../views/Appointment/UserPanel.vue';
import UserPanelFastRegister from '../views/Appointment/UserPanelFastRegister.vue';

import NotFoundView from '../views/ErrorViews/NotFoundView.vue';
import ErrorGeneric from '../views/ErrorViews/ErrorGeneric.vue';
import ErrorView from '../views/ErrorViews/ErrorView.vue';

import Languages from '../views/AdminPanel/Languages.vue';
import Accesibility from '../views/Login/Accesibility.vue';
import KitDigital from '../views/Login/kitdigital.vue';
import Sitemap from '../views/Login/Sitemap.vue';

// FUNPIC fotomatón
/*
import funpic1 from '../views/_FUNPIC/pages/funpic1.vue';
import funpic2 from '../views/_FUNPIC/pages/funpic2.vue';
import funpic3 from '../views/_FUNPIC/pages/funpic3.vue';
import funpic4 from '../views/_FUNPIC/pages/funpic4.vue';
import funpic5 from '../views/_FUNPIC/pages/funpic5.vue';
import funpic6 from '../views/_FUNPIC/pages/funpic6.vue';
import funpic7 from '../views/_FUNPIC/pages/funpic7.vue';
import funpic8 from '../views/_FUNPIC/pages/funpic8.vue';
import funpic9 from '../views/_FUNPIC/pages/funpic9.vue';
*/

Vue.use(VueRouter);

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },
  {
    path: '/recover-password-confirmation',
    name: 'RecoverPasswordGreetings',
    component: RecoverPasswordGreetings,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },

  {
    path: '/accesibilidad',
    name: 'Accesibility',
    component: Accesibility,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },

  {
    path: '/kit-digital',
    name: 'KitDigital',
    component: KitDigital,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: Sitemap,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },

  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex,
    nav_available: false,
    admin_role: false,
    icon: 'home',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: '',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE', 'ROLE_USER'],
    },
  },
  {
    path: '/inicio',
    name: 'Home',
    component: Home,
    nav_available: true,
    admin_role: false,
    icon: 'home',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Inicio',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE'],
    },
  },
  {
    path: '/nueva-reserva',
    name: 'Appointment Register',
    component: ConfigureFastAppointment,
    nav_available: false,
    admin_role: false,
    icon: 'person_add',
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Nueva reserva',
    },
  },
  {
    path: '/reserva-manual',
    name: 'ManualRegister',
    component: ConfigureManualAppointment,
    nav_available: true,
    admin_role: true,
    icon: 'add_circle',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Añadir reserva',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE'],
    },
  },
  {
    path: '/mi-reserva',
    name: 'UserPanel',
    component: UserPanel,
    nav_available: false,
    admin_role: false,
    icon: 'person',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Mi reserva',
      roles: ['ROLE_USER'],
    },
  },
  {
    path: '/mi-reserva/pendiente-de-personalizacion',
    name: 'UserPanelFastRegister',
    component: UserPanelFastRegister,
    nav_available: false,
    admin_role: false,
    icon: 'person',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Mi reserva rápida',
      roles: ['ROLE_USER'],
    },
  },
  {
    path: '/mi-reserva/personalizar',
    name: 'AppointmentConfiguration',
    component: ConfigureAppointmentPostPayment,
    nav_available: false,
    admin_role: false,
    icon: 'event',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Personalizar reserva',
    },
  },
  {
    path: '/reservas/editar/:id',
    name: 'AppointmentEdit',
    component: EditAppointmentPostPayment,
    nav_available: false,
    admin_role: false,
    icon: 'event',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Personalizar reserva',
    },
  },

  {
    path: '/calendario',
    name: 'Calendar',
    component: AppointmentCalendar,
    nav_available: true,
    admin_role: false,
    icon: 'calendar_month',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Calendario',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE'],
    },
  },

  {
    path: '/eventos',
    name: 'Appointments Table',
    component: Appointments,
    nav_available: true,
    admin_role: false,
    icon: 'local_activity',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Eventos',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE'],
    },
  },
  {
    path: '/eventos/:id',
    name: 'Appointments Panel',
    component: AppointmentPanel,
    nav_available: false,
    admin_role: true,
    icon: 'event',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Panel',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE'],
    },
  },
  {
    path: '/estadisticas/reservas',
    name: 'AppointmentStats',
    component: AppointmentStats,
    nav_available: true,
    admin_role: false,
    icon: 'insights',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Estadísticas de eventos',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/estadisticas/presupuesto',
    name: 'BudgetStats',
    component: BudgetStats,
    nav_available: true,
    admin_role: false,
    icon: 'pie_chart',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Estadísticas de presupuesto',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  //
  // APPOINTMENTS
  //
  {
    path: '/almacen',
    name: 'Warehouse',
    component: AppointmentWarehouse,
    nav_available: true,
    admin_role: true,
    icon: 'archive',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Almacén',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_RESPONSABLE'],
    },
  },

  {
    path: '/cupones',
    name: 'Coupons',
    component: Coupons,
    nav_available: true,
    admin_role: true,
    icon: 'discount',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Cupones de descuento',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE'],
    },
  },
  {
    path: '/cupones/nuevo-cupon',
    name: 'Create Coupon',
    component: CreateCoupon,
    nav_available: false,
    admin_role: true,
    icon: 'local_offer',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Nuevo cupón',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE'],
    },
  },

  {
    path: '/almacen/exportar-albums',
    name: 'Export Albums',
    component: ExportAlbum,
    nav_available: false,
    admin_role: true,
    icon: 'archive',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Exportar álbumes',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_RESPONSABLE'],
    },
  },
  {
    path: '/almacen/exportar-extras',
    name: 'Export Extras',
    component: ExportExtras,
    nav_available: false,
    admin_role: true,
    icon: 'archive',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Exportar extras',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_RESPONSABLE'],
    },
  },

  {
    path: '/ajustes-configurador',
    name: 'Appointment Props',
    component: AppointmentProps,
    nav_available: true,
    admin_role: true,
    icon: 'settings',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Ajustes del configurador',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/ajustes-configurador/nuevo',
    name: 'Appointment Prop Register',
    component: AppointmentPropRegister,
    nav_available: false,
    admin_role: true,
    icon: 'add',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Nuevo elemento del configurador',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  {
    path: '/administracion/codigos-postales',
    name: 'PostalCodes',
    component: PostalCodes,
    nav_available: true,
    admin_role: true,
    icon: 'markunread_mailbox',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Códigos postales',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  {
    path: '/administracion/idiomas',
    name: 'Idiomas',
    component: Languages,
    nav_available: false,
    admin_role: true,
    icon: 'flag',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Idiomas',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  {
    path: '/administracion/codigos-postales/nuevo',
    name: 'PostalCodeRegister',
    component: PostalCodeRegister,
    nav_available: false,
    admin_role: true,
    icon: 'map',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Añadir Codigos postales',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  {
    path: '/administracion/usuarios',
    name: 'Users',
    component: UsersManager,
    nav_available: true,
    admin_role: true,
    icon: 'manage_accounts',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Usuarios',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/administracion/usuarios/:id',
    name: 'User Panel',
    component: ActivityPanel,
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Panel de usuario',
      roles: ['ROLE_SUPER_ADMIN'],
    },
  },
  {
    path: '/administracion/actividad',
    name: 'Activity',
    component: Activity,
    nav_available: true,
    admin_role: true,
    icon: 'history',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Actividad',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
    },
  },

  {
    path: '/validacion',
    name: 'Validation',
    component: Validation,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Validación',
    },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Validación',
    },
  },
  {
    path: '/pago-finalizado',
    name: 'RegisterCompleted',
    component: RegisterCompleted,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Pago finalizado',
    },
  },
  {
    path: '/pago-cancelado',
    name: 'RegisterCanceled',
    component: RegisterCanceled,
    meta: {
      auth: false,
      children: [],
      parent: null,
      label: 'Pago cancelado',
    },
  },

  {
    path: '/error',
    name: 'Error',
    component: ErrorGeneric,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },
  {
    path: '/inscripcion/error',
    name: 'Error',
    component: ErrorView,
    meta: {
      auth: false,
      children: [],
      parent: null,
    },
  },
  {
    path: '/error-no-encontrado',
    name: 'No encontrado',
    component: NotFoundView,
    meta: {
      auth: true,
      children: [],
      parent: null,
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE', 'ROLE_USER'],
    },
  },
  {
    path: '/sin-permisos',
    name: 'Not Allowed',
    component: NotAllowed,
    nav_available: false,
    admin_role: false,
    icon: 'warning',
    meta: {
      auth: true,
      children: [],
      parent: null,
      label: 'Sin permisos',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE', 'ROLE_RESPONSABLE', 'ROLE_USER'],
    },
  },
  /*
  {
    path: '/user-not-registered',
    name: 'User Not Registered',
    component: UserNotRegistered,
    nav_available: false,
    admin_role: false,
    icon: 'warning',
    meta: {
      auth: true,
      children: [],
      parent: null,
    },
  },
  */

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.name !== 'Login' && to.name !== 'Validation' && to.name !== 'Appointment Register' && to.name !== 'FastRegister' && to.name !== 'RecoverPasswordGreetings' && to.name !== 'Reset'
  && to.name !== 'RegisterCompleted' && to.name !== 'RegisterCanceled' && to.name !== 'Accesibility' && to.name !== 'KitDigital' && to.name !== 'Sitemap')
    && !store.state.auth.status.loggedIn) next({ name: 'Login' });
  else next();
});

export default router;
