<template>
  <div class="page-layout-with-nav">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <!-- -->
        <section-header first="Aquí tienes la información de" second="Tu reserva" />
        <div class="field-group" v-if="user.appointment">
          <label for="" class="field-group--label">
            Datos del evento:
          </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Fecha:</span>
              <span class="data--value">{{ dateOfAppointment }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Horario:</span>
              <span class="data--value">{{ this.getTypeLabel(user.appointment.type) }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Lugar del evento:</span>
              <span class="data--value">{{ user.appointment.name }}</span>
            </div>

            <!-- <div class="data--item" v-if="user.appointment.postalCode">
              <span class="data--label">Código Postal:</span>
              <span class="data--value">{{ user.appointment.postalCode }}</span>
            </div> -->
          </div>
        </div>
        <v-divider></v-divider>
        <!-- Datos personales -->
        <div class="field-group">
          <label for="" class="field-group--label"> Datos Personales: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre:</span>
              <span class="data--value">{{ user.name }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Correo electrónico:</span>
              <span class="data--value">{{ user.email }}</span>
            </div>
            <div class="data--item" v-if="user.appointment">
              <span class="data--label">Domicilio:</span>
              <span class="data--value">{{ user.appointment.direction }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Teléfono:</span>
              <span class="data--value">{{ user.phone }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">DNI:</span>
              <span class="data--value">{{ user.idDocument }}</span>
            </div>
          </div>
        </div>

        <!-- Start update time -->
        <div class="field-group" v-if="user.appointment">
          <v-divider></v-divider>
          <label for="" class="field-group--label">
            Todavía no has personalizado tu fotomatón
          </label>
          <div class="discount-code--container">
            <selection-button title="¡Hazlo aquí!" v-on:click.native="goToConfigureAppointment" />
          </div>
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>

import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';

const axios = require('axios');

export default {
  name: 'Panel-Usuario-Reserva-Rapida',
  components: {
    SectionHeader,
    SelectionButton,
  },
  data() {
    return {
      adminAvailable: false,
      superAdminAvailable: false,
      totalRecords: null,
      totalComments: null,
      totalAttendances: null,
      userId: null,
      user: [],
      userInfo: {
        namePartners: null,
        lastName1: null,
        namePlace: null,
        streetPlace: null,
        phone2: null,
        guestsNumber: null,
        time: null,
      },
      records: [],
      comments: [],
      attendances: [],
      userActivityId: null,

      // new data
      tab: 0,
      tab2: 0,
      loading: false,
      disabled: true,
      imageFileAlbum: null,
      imageFilePlantilla: null,

      previewImg: null,
      swiperLoaded: false,
      postalCodeItem: null,
      originCoordinates: {
        latitude: -3.8752786669764077,
        longitude: 40.489787823760636,
      },
      optionsExtras: null,
      newExtras: [],
      isPaid: true,
      newTime: null,
      newNeonPersonalizado: null,
    };
  },
  computed: {

    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },

    checkTime() {
      if (!this.user.appointment || !this.user.appointment.time) {
        return null;
      }
      return this.user.appointment.time;
    },
    dateOfInscription() {
      if (this.user && this.user.creationTime) {
        return new Date(this.user.creationTime).toLocaleDateString();
      }
      return 'No disponible';
    },
    dateOfLastUpdate() {
      if (this.user && this.user.lastUpdateTime) {
        return new Date(this.user.lastUpdateTime).toLocaleDateString();
      }
      return 'No disponible';
    },
    dateOfAppointment() {
      if (this.user && this.user.appointment) {
        return new Date(this.user.appointment.eventDate).toLocaleDateString();
      }
      return 'No disponible';
    },
    userChar() {
      if (this.user.name) {
        return this.user.name.substring(1, 0).toUpperCase()
          + this.user.lastName1.substring(1, 0).toUpperCase();
      }
      return 'X';
    },
  },
  mounted() {
    if (authService.getDecodedJwtToken(this.$store.state.auth.user).affiliate) {
      this.loading = true;
      this.getAffiliate();
    } else {
      this.$router.push({ name: 'Not Allowed' });
    }
  },
  methods: {
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Inicio antes de las 19:15)';
        case 'evening':
          return 'Boda de noche (Inicio después de 23:29)';
        default:
          return 'No disponible';
      }
    },

    getAffiliate() {
      this.$store.dispatch('affiliates/getLoggedAffiliate').then(() => {
        this.user = this.$store.state.affiliates.loggedAffiliate;
        this.userInfo = {
          namePartners: this.user.appointment.namePartners,
          lastName1: this.user.lastName1,
          namePlace: this.user.appointment.namePlace,
          streetPlace: this.user.appointment.streetPlace,
          phone2: this.user.appointment.phone,
          guestsNumber: this.user.appointment.guestsNumber,
          time: this.user.appointment.time,
        };
        this.getPostalCode(this.user.appointment.postalCode);
      })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    getPostalCode(id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}postal-code/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.postalCodeItem = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },

    updateUserInfo() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/add-user-info`,
          {
            time: this.newTime,
            neonPersonalizado: this.user.appointment.neonPersonalizado,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.getAffiliate();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goToConfigureAppointment() {
      this.$router.push({ name: 'AppointmentConfiguration' });
    },
  },
};
</script>
<style scoped>
/* .main-container--inner{
  padding: calc(var(--base)*3) 0;
} */
.preview-canvas {
    max-height: 40%;
    margin-bottom: calc(var(--base));
}
.page-layout-with-nav{
  height: 100%;
  padding: 0 ;
}
.main-container{
 height: 100% !important;
}
</style>
<style lang="scss" scoped>
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 8) !important;
  }
}
</style>
