<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear :color="$getColor('vuetifyColor1')" class="linear-progress" indeterminate>
      </v-progress-linear>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
        calculate-widths
        :color="$getColor('vuetifyColor1')"
        outlined
        flat
        elevation="0"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 100, -1],
        }"
      >
        <template v-slot:[`item.userPanel`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <!-- <v-btn fab v-if="item.appointment" elevation="0" x-small @click="goToView(item.id)"
                :color="$getAppointmentTypeValues(item.appointment.type).color" v-on="on">
                <v-icon>{{ $getAppointmentTypeValues(item.appointment.type).icon }}</v-icon>
              </v-btn> -->
              <!-- Lo cambié para no ser redundante con lo de día, y al final es un enlace para abrir el evento-->
              <v-btn
                fab
                elevation="0"
                x-small
                @click="goToView(item.id)"
                :color="$getColor('vuetifyColor1')"
                v-on="on"
              >
                <v-icon color="white">open_in_new</v-icon>
              </v-btn>
            </template>
            <span>Ver evento</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.appointment.type`]="{ item }">
          <!-- Fallaba si llamabamos a item.inscriptionType, he usado item.appointment.type y ahora si que filtra bien-->
          <v-chip
            v-if="item.appointment"
            :color="$getAppointmentTypeValues(item.appointment.type).color"
            :dark="$getAppointmentTypeValues(item.appointment.type).dark"
          >
            {{ $getAppointmentTypeValues(item.appointment.type).label }}
          </v-chip>
        </template>
        <!--
          <template v-slot:[`item.idDocument`]="{ item }">
          <span class="caption font-italic">{{ item.idDocument }}</span>
        </template>
         -->
        <template v-slot:[`item.email`]="{ item }">
          <span class="font-weight-medium">{{ item.email }}</span>
        </template>
        <template v-slot:[`item.appointment.eventDate`]="{ item }">
          <span v-if="item.appointment" class="font-weight-medium">
            {{
              new Date(item.appointment.eventDate).toLocaleDateString("es-ES", {
                timeZone: "Europe/Madrid",
              })
            }}
          </span>
        </template>
        <template v-slot:[`item.appointment.paidStatus`]="{ item }">
          <v-chip v-if="item.appointment"  :color="$getAppointmentPaidStatusValues(item.appointment.paidStatus).color"
            :dark="$getAppointmentPaidStatusValues(item.appointment.paidStatus).dark"
          >
            {{ $getAppointmentPaidStatusValues(item.appointment.paidStatus).label }} </v-chip>
        </template>
        <template v-slot:[`item.appointment.albumInStock`]="{ item }">
          <v-icon v-if="item.appointment && item.appointment.albumInStock" color="teal accent-3"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon v-if="item.appointment && !item.appointment.albumInStock" color="grey"
            >mdi-close-circle-outline</v-icon
          >
        </template>
        <template v-slot:[`item.appointment.neonInStock`]="{ item }">
          <v-icon v-if="item.appointment && item.appointment.neonInStock" color="teal accent-3"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon v-if="item.appointment && !item.appointment.neonInStock" color="grey"
            >mdi-close-circle-outline</v-icon
          >
        </template>
        <template v-slot:[`item.fastRegister`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-if="item.fastRegister" color="#E99797" v-on="on">mdi-close-circle</v-icon>
            </template>
            <span>Sin configurar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.manualRegister">
            <template v-slot:activator="{ on }">
              <v-icon :color="$getColor('vuetifyColor1')" v-on="on">handyman</v-icon>
            </template>
            <span>Reserva manual</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.fastRegister && !item.manualRegister">
            <template v-slot:activator="{ on }">
              <v-icon :color="$getColor('vuetifyColor1')" v-on="on">mdi-check-circle</v-icon>
            </template>
            <span>Configuración completa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';
import * as XLSX from 'xlsx';
import authService from '../../services/auth.service';

export default {
  name: 'standard-table',
  props: {},
  data() {
    return {
      authService,
      isAdmin: false,
      filter: {
        idDocument: null,
        name: null,
        namePartners: null,
        email: null,
        type: null,
        phone: null,
        date: null,
      },

      tableData: [],
      count: 0,

      loading: true,
      cancelTokenSource: null,

      options: {},
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'userPanel',
        },

        // { text: 'DNI', value: 'idDocument', width: '100px' },
        { text: 'Nombre', value: 'name', width: '100px' },
        { text: 'Email', value: 'email' },
        { text: 'Reserva', value: 'fastRegister', width: '100px' },
        { text: 'Tipo', value: 'appointment.type' },
        { text: 'Teléfono', value: 'phone' },
        { text: 'Teléfono (evento)', value: 'appointment.phone', width: '150px' },
        { text: 'Fecha evento', value: 'appointment.eventDate', width: '130px' },
        { text: '€', value: 'appointment.paidStatus', width: '130px' },
        { text: 'Álbum', value: 'appointment.albumInStock', width: '90px' },
        { text: 'Neón', value: 'appointment.neonInStock', width: '90px' },
        { text: 'Finca', value: 'appointment.name', width: '90px' },

        // { text: 'Nombre', value: 'name' },
        // { text: 'Email', value: 'email' },
        // { text: 'Tipo', value: 'inscriptionType' },
        // { text: 'Teléfono', value: 'phone' },
        // { text: 'Teléfono (evento)', value: 'appointment.phone' },
        // { text: 'Fecha evento', value: 'eventDate' },
        // { text: 'Álbum', value: 'appointment.albumInStock' },
        // { text: 'Neón', value: 'appointment.neonInStock' },
        // { text: 'Finca', value: 'appointment.name' },
        // { text: 'Nombre pareja', value: 'appointment.namePartners' },
        // { text: 'Apellido novia', value: 'lastName1' },
        // { text: 'Reserva', value: 'fastRegister' },
      ],
    };
  },
  created() {
    if (
      this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol === 'ROLE_SUPER_ADMIN'
    ) {
      this.isAdmin = true;
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  components: {},
  methods: {
    getRequestParams() {
      const params = {};
      params.idDocument = this.filter.idDocument;
      params.name = this.filter.name;
      params.namePartners = this.filter.namePartners;
      params.email = this.filter.email;
      params.type = this.filter.type;
      params.phone = this.filter.phone;
      params.onlyNext = this.filter.onlyNext;
      params.fastRegister = this.filter.fastRegister;
      if (this.filter.date) {
        const fechaOriginal = new Date(this.filter.date);

        // Obtener día, mes y año
        const dia = fechaOriginal.getDate();
        const mes = fechaOriginal.getMonth() + 1; // Nota: en JavaScript, los meses van de 0 a 11
        const anio = fechaOriginal.getFullYear();

        // Formatear la fecha como dd/MM/YYYY
        const fechaFormateada = `${dia}/${mes}/${anio}`;

        // Asignar la fecha formateada a params.date
        params.date = fechaFormateada;
        // params.date = new Date(this.filter.date).toLocaleString('es-ES', { timeZone: 'Europe/Madrid' });
      }
      // eslint-disable-next-line prefer-destructuring
      params.sort = this.options.sortBy[0];
      if (this.options.sortDesc[0]) {
        params.direction = 'desc';
      } else {
        params.direction = 'asc';
      }

      params.page = this.options.page - 1;
      params.size = this.options.itemsPerPage;
      return params;
    },

    goToView(id) {
      return this.$router.push({ name: 'Appointments Panel', params: { id } });
    },

    updateFilter(val) {
      this.filter = val;
      this.options.page = 1;
      this.getItems();
    },

    downloadData() {
      this.download();
    },

    download() {
      this.dataExport = this.tableData.map((item) => {
        const outItem = JSON.parse(JSON.stringify(item));

        // Renombrar campos
        outItem.Nombre = outItem.name;
        delete outItem.name; // Eliminar el campo 'name'
        outItem.ApellidoNovia = outItem.lastName1;
        delete outItem.lastName1;
        outItem.Telefono = outItem.phone;
        delete outItem.phone;
        outItem.CodigoPostal = outItem.postalCode;
        delete outItem.postalCode;
        // Modificar campos
        outItem.Tipo = item.appointment.type === 'evening' ? 'Boda de noche' : 'Boda de día';
        outItem.TipoReserva = item.manualRegister ? 'Reserva Manual' : 'Reserva Online';
        outItem.Configuracion = item.fastRegister ? 'Sin configurar' : 'Configuración completa';

        // Agregar propiedades de appointment
        outItem.FechaEvento = item.appointment ? new Date(item.appointment.eventDate).toLocaleDateString() : null;
        outItem.Domicilio = item.appointment ? item.appointment.direction : null;
        outItem.NombrePareja = item.appointment ? item.appointment.namePartners : null;
        outItem.LugarDelEvento = item.appointment ? item.appointment.name : null;
        outItem.DireccionFinca = item.appointment ? item.appointment.streetPlace : null;
        outItem.TelefonoEvento = item.appointment ? item.appointment.phone : null;
        outItem.Status = item.appointment ? item.appointment.status : null;
        outItem.Photocall = item.appointment && item.appointment.photocall ? item.appointment.photocall.name : null;
        outItem.Neon = item.appointment && item.appointment.neon ? item.appointment.neon.name : null;
        outItem.NeonPersonalizado = item.appointment ? item.appointment.neonPersonalizado : null;
        outItem.Album = item.appointment && item.appointment.album ? item.appointment.album.name : null;
        outItem.AlbumPersonalizado = item.appointment.hasCustomImageAlbum ? 'Album personalizado' : '';
        outItem.Plantilla = item.appointment && item.appointment.plantilla ? item.appointment.plantilla.name : null;
        outItem.PlantillaSize = item.appointment && item.appointment.plantilla ? item.appointment.plantilla.size : null;
        outItem.PlantillaPersonalizada = item.appointment.hasCustomImagePlantilla ? 'Plantilla personalizada' : '';
        outItem.CuponDescuento = item.appointment && item.appointment.discountCoupon ? item.appointment.discountCoupon.code : null;
        outItem.PaidStatus = item.appointment && item.appointment.paidStatus ? item.appointment.paidStatus : null;
        outItem.Precio = item.appointment ? item.appointment.priceForStats : null;
        outItem.NumeroInvitados = item.appointment ? item.appointment.guestsNumber : null;
        outItem.HoraAprox = item.appointment ? item.appointment.time : null;

        // Concatenar los nombres de los extras
        if (item.appointment && item.appointment.extras && item.appointment.extras.length > 0) {
          outItem.Extras = item.appointment.extras.map((extra) => extra.name).join(' / ');
        } else {
          outItem.Extras = null;
        }

        // Renombrar campos
        outItem.FechaCreacion = outItem.creationTime;
        delete outItem.creationTime; // Eliminar el campo 'name'
        outItem.FechaUltimaActualizacion = outItem.lastUpdateTime;
        delete outItem.lastUpdateTime;

        // Eliminar campos
        delete outItem.manualRegister;
        delete outItem.appointment;
        delete outItem.inscriptionType;
        delete outItem.fastRegister;
        delete outItem.streetName;

        return outItem;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.dataExport);
      XLSX.utils.book_append_sheet(wb, ws, 'data');

      const date = new Date();
      const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const fileName = `data_export_${dateString}.xlsx`;
      XLSX.writeFile(wb, fileName);
      this.loading = false;
    },

    getItems() {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}affiliate/filtered`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.tableData = response.data.content;
          this.count = response.data.totalElements;
          this.updateTotal();
          this.loading = false;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            this.errored = true;
            this.loading = false;
          }
        });
    },

    updateTotal() {
      this.$emit('updateTotal', this.count);
    },
  },
};
</script>
<style>
.centered-content-column div {
  display: flex;
  justify-content: center;
}
</style>
<style scoped>
.table_box {
  min-height: 150px;
  position: relative;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.pagination-container {
  display: flex;
  flex-direction: column;
}

.pagination-options {
  margin-top: 1em;
  margin-bottom: 1em;
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    width: max-content;
  }
}

.md-drawer.md-persistent:not(.md-active).md-left + .md-app-container .md-content.dashboard_box {
  border-left: 1px solid #e5e5e5;
}

.loader-overlay {
  position: relative;
}
</style>
