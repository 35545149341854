<template>
  <div>
    <div class="card-filter-container">
      <v-text-field dense v-model="filter.phone" outlined label="Buscar por Teléfono" :color="$getColor('vuetifyColor1')"
        hide-details prepend-inner-icon="phone"></v-text-field>
      <div>
        <div>Próximos eventos (mostrar):
          <!-- <div style="color:#7FC88E">Tienes 5 nuevos</div> -->
        </div>
        <div>
          <v-switch color="#A5DDB1" hide-details v-model="filter.onlyNext"></v-switch>
        </div>
      </div>
      <div>
        <div>Eventos sin configurar (mostrar):<div style="color:#E99797">Tienes {{ alerts }} sin configurar</div>
        </div>
        <div>
          <v-switch color="#E99797" hide-details v-model="filter.fastRegister"></v-switch>
        </div>
      </div>
      <div class="download">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" large v-bind="attrs" v-on="on"
              @click="clickExpansionFilter()">
              <v-icon>filter_alt</v-icon>
            <span v-if="expansionPanel.length > 0">Ocultar filtros</span>
            <span v-else>Mostrar filtros</span>

            </v-btn>
          </template>
          <span>Mostrar/Ocultar filtros</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :elevation="0" :dark="false" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" color="#00E676" large v-bind="attrs" v-on="on"
              @click="downloadClick()">
              <v-icon>download</v-icon>
            <span>Descargar</span>
            </v-btn>
          </template>
          <span>Descargar datos</span>
        </v-tooltip>
      </div>
    </div>
    <v-expansion-panels accordion outlined v-model="expansionPanel" multiple color="transparent">
      <v-expansion-panel color="transparent">
        <v-expansion-panel-content color="transparent">
          <div class="card-filter-container">
            <v-text-field dense v-model="filter.name" outlined label="Nombre" :color="$getColor('vuetifyColor1')"
              hide-details prepend-inner-icon="person"></v-text-field>
            <!-- <v-text-field dense v-model="filter.namePartners" outlined label="Nombre de la pareja"
              :color="$getColor('vuetifyColor1')" hide-details prepend-inner-icon="person_outline"></v-text-field> -->
            <v-text-field dense v-model="filter.idDocument" outlined label="DNI" :color="$getColor('vuetifyColor1')"
              prepend-inner-icon="badge" hide-details>
            </v-text-field>

            <v-text-field v-model="filter.email" outlined label="Buscar por email..." :color="$getColor('vuetifyColor1')"
              hide-details prepend-inner-icon="email" dense></v-text-field>
            <property-type-select dense propertyValue="appointmentType" label="Tipo" icon="work"
              @updateParent="updateType" ref="appointmentTypeSelect">
            </property-type-select>
            <!-- Add v-date picker with v-menu -->
            <v-dialog ref="dialog" v-model="menu" :return-value.sync="filter.date" persistent width="450px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="filter.date" label="Fecha del evento" prepend-inner-icon="mdi-calendar"
                  readonly v-bind="attrs" v-on="on" :color="$getColor('vuetifyColor1')" outlined
                  hide-details></v-text-field>
              </template>
              <v-date-picker v-model="filter.date" scrollable :color="$getColor('vuetifyColor2')" locale="es"
                first-day-of-week="1">
                <v-spacer></v-spacer>
                <v-btn text :color="$getColor('vuetifyColor1')" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text :color="$getColor('vuetifyColor1')" @click="$refs.dialog.save(filter.date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider></v-divider>
    <div v-if="filterAvailable" class="filter-chips-container">
      <v-chip class="ma-2 borrar-filtros" color="rgba(0,0,0,0.56)" close outlined @click="resetFilter()"
        @click:close="resetFilter()">
        Resetear Filtros
      </v-chip>
      <v-chip v-if="filter.onlyNext" class="ma-2" :color="$getColor('vuetifyColor2')" close
        @click:close="filter.onlyNext = false">
        Próximos eventos
      </v-chip>
      <v-chip v-if="filter.fastRegister" class="ma-2" :color="$getColor('vuetifyColor5')" close
        @click:close="filter.fastRegister = false">
        Sin configurar
      </v-chip>
      <v-chip v-if="filter.idDocument != null && filter.idDocument != ''" class="ma-2" :color="$getColor('vuetifyColor1')"
        close outlined dark @click:close="filter.idDocument = null">
        DNI: {{ this.filter.idDocument }}
      </v-chip>
      <v-chip v-if="filter.phone != null && filter.phone != ''" class="ma-2" :color="$getColor('vuetifyColor1')" close
        dark @click:close="filter.phone = null">
        Teléfono: {{ this.filter.phone }}
      </v-chip>

      <v-chip v-if="filter.email != null && filter.email != ''" class="ma-2" :color="$getColor('vuetifyColor3')" close
        @click:close="filter.email = null">
        Email: {{ this.filter.email }}
      </v-chip>
      <v-chip v-if="filter.namePartners != null && filter.namePartners != ''" class="ma-2"
        :color="$getColor('vuetifyColor4')" close @click:close="filter.namePartners = null">
        Nombre de la pareja: {{ this.filter.namePartners }}
      </v-chip>

      <v-chip v-if="filter.date != null && filter.date != ''" class="ma-2" :color="$getColor('vuetifyColor5')" close
        @click:close="filter.date = null">
        Fecha: {{ this.filter.date }}
      </v-chip>
      <v-chip v-if="filter.type" class="ma-2" color="red lighten-2" close dark @click:close="resetType">
        Tipo: {{ $getAppointmentTypeValues(this.filter.type).label }}
      </v-chip>
      <v-chip v-if="filter.name != null && filter.name != ''" class="ma-2" color="deep-purple lighten-2" close outlined
        @click:close="filter.name = null">
        Nombre: {{ this.filter.name }}
      </v-chip>
    </div>
    <v-divider v-if="filterAvailable"></v-divider>
  </div>
</template>

<script>
import PropertyTypeSelect from '@/components/Forms/PropertiesSelect.vue';
import authService from '../../services/auth.service';

export default {
  name: 'header-filter',
  props: {
    icon: {
      type: String,
      default: 'people',
    },
  },
  data: () => ({
    expansionPanel: [],

    dates: [],
    authService,
    unCollapsed: false,
    total: null,
    filter: {
      name: null,
      idDocument: null,
      namePartners: null,
      email: null,
      type: null,
      date: null,
      phone: null,
      onlyNext: true,
      fastRegister: false,
    },
    alerts: 0,
    menu: false,
  }),
  components: {
    PropertyTypeSelect,
  },
  computed: {
    filterAvailable() {
      return (
        this.filter.idDocument != null
        || this.filter.name != null
        || this.filter.namePartners != null
        || this.filter.type != null
        || this.filter.phone != null
        || this.filter.date != null
        || this.filter.email != null
        || this.filter.onlyNext
        || this.filter.fastRegister !== false);
    },

  },
  methods: {
    clickExpansionFilter() {
      if (this.expansionPanel.length > 0) {
        this.expansionPanel = [];
      } else {
        this.expansionPanel = [0];
      }
    },
    updateAlerts(val) {
      this.alerts = val;
    },
    updateType(val) {
      this.filter.type = val;
    },
    clickDateStart() {
      this.filterStartDate = !this.filterStartDate;
    },
    clickDateEnd() {
      this.filterEndDate = !this.filterEndDate;
    },
    updateTotalBox(val) {
      this.total = val;
    },
    updateParent() {
      this.$emit('updateParent', this.filter);
    },
    downloadClick() {
      this.$emit('downloadClick', this.filter);
    },
    applyFilter() {
      this.$emit('applyFilter');
    },
    resetType() {
      this.filter.type = null;
      if (this.$refs.appointmentTypeSelect) {
        this.$refs.appointmentTypeSelect.resetValue();
      }
    },
    resetFilter() {
      this.filter.name = null;
      this.filter.namePartners = null;
      this.filter.idDocument = null;
      this.filter.email = null;
      this.filter.date = null;
      this.filter.phone = null;
      this.filter.type = null;
      this.filter.onlyNext = false;
      this.filter.fastRegister = false;
      this.resetType();
    },
  },
  created() {
    this.updateParent();
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.unCollapsed = !isJustShown;
    });
  },
  watch: {
    'filter.date': {
      handler(newDate, oldDate) {
        if (newDate !== oldDate) {
          this.filter.onlyNext = false;
        }
      },
    },
  },
};
</script>

<style>
.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}

.card-filter-button:hover {
  background-color: #fff;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}

.filter-chips-container {
  margin-bottom: 1em;
  widows: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkboxes-wrapper {
  display: flex;
}
</style>

<style scoped>
.card-filter-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  column-gap: 1em;
  row-gap: 1em;
  margin: 0;
  margin-bottom: 1em;
  margin-top: 1rem;
}
</style>

<style lang="scss">
.range {
  padding-top: 1em;
  padding-right: 1em;
}

.v-picker--date {
  position: absolute;
  z-index: 9999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.filter-prediccion {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    // line-height: 34px;
    margin: 0;
    margin-right: 1em;
  }
}

.fecha-wrapper {
  .v-input {
    margin-bottom: 1em;
  }
}

.checkbox-container {
  margin-right: 2em;

  .v-label {
    margin-right: 0em;
    margin-bottom: 0.5em;
  }

  .v-input__control {
    margin-right: 0.2em;
  }
}

.download {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: calc(var(--base)*2);
  & > * {
    margin: 0 !important;
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  width: 200px;
}

.v-chip.borrar-filtros {
  border: none !important;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 8px;
}
</style>
