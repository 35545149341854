<template>
  <div class="recover-password-greetings">
    <div class="recover-password--headings">
      <h2 class="recover-password--title">
        Cotraseña cambiada
      </h2>
    </div>
    <div class="aside">
      <router-link to="/login" class="back-to-home--anchor">Login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPasswordGreetings',

};
</script>

<style lang="scss" scoped>
.recover-password-greetings {
  position: relative;
  width: 100%;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  padding: calc(var(--base) * 2);
  overflow: hidden;

}

.recover-password--headings {
  // margin-top: calc(var(--app-height) * 0.1709145427);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(var(--base) * 6);

  @media (min-width: 1024px) {
    // margin-top: calc(var(--app-height) * 0.2657841141);
  }

  .recover-password--title {
    font-size: calc(var(--base) * 3);
    font-weight: 700;
    text-align: center;
    padding: 0 calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 0.5);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 4);
    }

  }

  .recover-password--subtitle {

    width: 100%;
    font-size: calc(var(--base) * 1.375);
    font-weight: 400;
    text-align: center;
    padding: 0 calc(var(--base) * 2);

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 1.875);
    }
  }

}

.back-to-home--anchor {
  margin: 0 auto;
  text-align: center;
  font-size: calc(var(--base) * 2.5);

  &:hover {
    text-decoration: underline;
    font-weight: 700;
  }
}</style>
