<template>
  <div>
    <!-- Add progress bar -->
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear :color="$getColor('vuetifyColor1')" class="linear-progress" indeterminate>
      </v-progress-linear>
    </div>
    <v-simple-table v-if="!loading" class="elevation-0" color="transparent">
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <th class="text-left"></th> -->
            <th class="text-left">Nombre</th>
            <th class="text-left">Activo</th>
            <th class="text-left">Tipo</th>
            <th class="text-left" v-if="typeSelected === 'Extras'">Descripción</th>
            <th class="text-left">Precio</th>
            <th class="text-left" v-if="typeSelected === 'Extras' || typeSelected === 'Neon'">Descuento</th>
            <th class="text-left" v-if="typeSelected === 'Plantilla'">Tamaño</th>
            <th class="text-left">Imagen</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.text">
            <!--
              <td>
               <v-btn @click="goToPanel(item.id)"
               outlined fab small
               :rounded="$getPrimaryButton('rounded')"
                 :color="$getPrimaryButton('color')"  >
                <v-icon>edit</v-icon>
              </v-btn>
            </td>
             -->
            <td class="table-description">{{ item.name }}</td>
            <td class="table-description">

              <v-btn v-if="item.active" icon @click.native="toggleActiveProp(item)">
                <v-icon color="teal accent-3">mdi-check-circle-outline</v-icon>
              </v-btn>
              <v-btn v-else icon @click.native="toggleActiveProp(item)">
                <v-icon color="grey">mdi-close-circle-outline</v-icon>
              </v-btn>

            </td>
            <td class="table-description">
              <v-chip class="button-add_comment button-in-cell" small outlined
                :color="$getAppointmentPropsTypeValues(typeSelected).color">
                {{ $getAppointmentPropsTypeValues(typeSelected).label }}
              </v-chip>
            </td>
            <td class="table-description" v-if="typeSelected === 'Extras'">{{ item.description }} </td>
            <td class="table-description">{{ item.price }} </td>
            <td class="table-description" v-if="typeSelected === 'Extras' || typeSelected === 'Neon'">{{ item.discount }}
            </td>
            <td class="table-description" v-if="typeSelected === 'Plantilla'">{{ item.size }} </td>
            <td class="table-description">
              <!-- Add image preview -->
              <img :src="item.imageUrl" alt="Image" width="100" height="100">
            </td>

          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

const axios = require('axios');

export default {
  name: 'appointments-props-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    adminTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authService,
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 50,
      pageSizes: [5, 10, 20],

      items: [],
      currentSort: 'name',
      currentSortOrder: 'asc',

      typeSelected: null,
      loading: false,
    };
  },

  watch: {
    typeSelected() {
      this.getItems();
    },
    statusSelectedArray() {
      this.getItems();
    },
  },
  methods: {
    emitCount() {
      this.$emit('emitCount', this.count, this.typeSelected);
    },
    updateTypeSelected(val) {
      this.typeSelected = val;
    },

    goToPanel(id) {
      return this.$router.push(`/calendar/${id}`);
    },

    getRequestParams(page, pageSize) {
      const params = {};
      // eslint-disable-next-line prefer-destructuring
      params.type = this.typeSelected;
      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;
      if (page) {
        params.page = page - 1;
      }
      if (pageSize) {
        params.size = pageSize;
      }
      return params;
    },

    getItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type-admin`, {
          headers: authHeader(),
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.items = response.data.content;
          this.count = response.data.totalElements;
          this.emitCount();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    customSort() {
      return [];
    },
    toggleActiveProp(val) {
      this.loading = true;
      axios.post(
        `${process.env.VUE_APP_API_URL}appointment-prop/activate`,
        {
          active: !val.active,
          id: val.id,
        },
        { headers: authHeader() },
      )
        .then(() => {
          this.getItems();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
