<template>
  <div class="dashboard-main">
    <div class="project-grid">
      <div
        class="project-card dashboard_box"
      >
        <div class="project-thumbnail">
          <v-icon :color="$getColor('vuetifyColor1')" large>menu_book</v-icon>
        </div>
        <div class="project-label">
          <div class="project-info">
            <div class="project-title">Álbums</div>
            <div class="project-description">Exportar álbumes en las fechas seleccionadas</div>
          </div>
          <div class="project-button">
            <v-btn
              outlined
              :color="$getColor('vuetifyColor1')"
              small
              @click="goToExportAlbum()"
              >Seleccionar</v-btn
            >
          </div>
        </div>
      </div>
      <div
        class="project-card dashboard_box"
      >
        <div class="project-thumbnail-pink">
          <v-icon :color="$getColor('vuetifyColor1')" large>bolt</v-icon>
        </div>
        <div class="project-label">
          <div class="project-info">
            <div class="project-title">Extras ( Neón personalizado )</div>
            <div class="project-description">Exportar neones personalizados en las fechas seleccionadas</div>
          </div>
          <div class="project-button">
            <v-btn
              outlined
              :color="$getColor('vuetifyColor1')"
              small
              @click="goToExportNeon()"
              >Seleccionar</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'select-warehouse',
  data: () => ({
    project: null,
    assemblyItem: null,
    options: [],
    sourceOptions: [],
    info: null,
    loading: true,
    errored: false,
    userId: null,
    userRole: null,
    userName: null,
    user: [],
    // filter data
    assemblyType: null,
    assembly: null,
  }),
  mounted() {

  },
  methods: {
    goToExportAlbum() {
      this.$router.push({ name: 'Export Albums' });
    },
    goToExportNeon() {
      this.$router.push({ name: 'Export Extras' });
    },

  },
};
</script>
