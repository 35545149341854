// add constant with color properties
const Options = {

  colors: {
    color1: '#FB8C00',
    color2: '#ffc04a',
    color3: '#FF6E40',
    color4: '#FFFF8D',

    // vuetifyColor1: 'orange darken-1',
    vuetifyColor1: '#090909',
    // vuetifyColor2: 'orange lighten-1',
    vuetifyColor2: '#D9F1DE',
    vuetifyColor3: '#ffdef2',
    vuetifyColor4: '#ddd3eb',
    vuetifyColor5: '#ffd0d0',

    vuetifyAppBar: 'white',
    vuetifyNavSecondary: 'grey lighten-4',
    vuetifyColorAvatar: 'orange darken-1',

  },
  primaryButton: {
    color: '#090909',
    textColor: 'white',
    outlined: false,
    dark: true,
    rounded: true,
  },
  cards: {
    color1: '#FB8C00',
    color2: '#ffc04a',
    elevation: 0,
  },
  values: {
    inscriptionType: {
      1: {
        label: 'User',
        color: '#ff6347',
      },
      2: {
        label: 'Customer',
        color: '#ffc04a',
      },
      3: {
        label: 'Supplier',
        color: '#ff1347',
      },
    },
    gender: {
      H: {
        label: 'Male',
        color: '#ff6347',
      },
      M: {
        label: 'Female',
        color: '#ffc04a',
      },
      NoB: {
        label: 'Non-binary',
        color: '#ff1347',
      },
    },
    projectType: {
      1: {
        label: 'Main projects',
        color: '#ff6347',
        icon: 'engineering',
      },
      2: {
        label: 'Other external projects',
        color: '#ffc04a',
        icon: 'work',
      },
    },
    eventType: {
      Asamblea: {
        label: 'Project meeting',
        color: '#ff6347',
        icon: 'work',
      },
      General: {
        label: 'General',
        color: '#ffc04a',
        icon: 'event',
      },
      Otros: {
        label: 'Other',
        color: '#ff1347',
        icon: 'other_houses',
      },
    },
    appointmentType: {
      morning: {
        label: 'Día',
        color: '#ffd0d0',
        icon: 'mdi-weather-sunny',
        dark: false,
      },
      evening: {
        label: 'Noche',
        color: '#ddd3eb',
        icon: 'mdi-weather-night',
        dark: false,
      },
    },
    appointmentPaidStatus: {
      CASH: {
        label: 'Efectivo',
        color: '#060606',
        icon: 'mdi-payments',
        dark: true,
      },
      BANK: {
        label: 'Banco',
        color: '#060606',
        icon: 'mdi-account-balance',
        dark: true,
      },
      PAID: {
        label: 'Pagado',
        color: '#00E676',
        icon: 'mdi-check-circle',
        dark: false,
      },
      PROBLEM: {
        label: 'Problema',
        color: '#FF5252',
        icon: 'mdi-warning',
        dark: true,
      },
      EMPTY: {
        label: 'Sin Datos',
        color: '#f2f2f2',
        icon: 'mdi-cancel',
        dark: false,
      },
    },
    budgetType: {
      high: {
        label: 'Temporada Alta',
        color: '#ffd0d0',
        icon: 'mdi-weather-sunny',
        dark: false,
      },
      low: {
        label: 'Resto de días',
        color: '#ddd3eb',
        icon: 'mdi-weather-night',
        dark: false,
      },
    },
    feesOptions: {
      1: {
        label: 'Monthly',
        color: '#ff6347',
        options: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 20, text: '20' },
          { value: 30, text: '30' },
          { value: 'Otro', text: 'Otro' },
        ],
      },
      2: {
        label: 'Quarterly',
        color: '#ffc04a',
        options: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 20, text: '20' },
          { value: 30, text: '30' },
          { value: 40, text: '40' },
          { value: 50, text: '50' },
          { value: 60, text: '60' },
          { value: 70, text: '70' },
          { value: 80, text: '80' },
          { value: 90, text: '90' },
          { value: 100, text: '100' },
          { value: 'Otro', text: 'Otro' },
        ],
      },
      3: {
        label: 'Yearly',
        color: '#ff1347',
        options: [
          { value: 20, text: '20' },
          { value: 40, text: '40' },
          { value: 60, text: '60' },
          { value: 80, text: '80' },
          { value: 100, text: '100' },
          { value: 150, text: '150' },
          { value: 200, text: '200' },
          { value: 250, text: '250' },
          { value: 300, text: '300' },
          { value: 350, text: '350' },
          { value: 400, text: '400' },
          { value: 'Otro', text: 'Otro' },
        ],
      },
    },
    roles: {
      ROLE_USER: {
        label: 'Usuario',
        value: 0,
      },
      ROLE_ENLACE: {
        label: 'Comercial',
        value: 1,
      },
      ROLE_RESPONSABLE: {
        label: 'Almacén',
        value: 2,
      },
      ROLE_ADMIN: {
        label: 'Administrador',
        value: 3,
      },
      ROLE_SUPER_ADMIN: {
        label: 'Super Administrador',
        value: 4,
      },
    },
    status: {
      Pendiente: {
        label: 'Pending',
        color: '#FB8C00',
        icon: 'alarm',
      },
      Aprobada: {
        label: 'Approved',
        color: '#00E676',
        icon: 'check',
      },
      Confirmado: {
        label: 'Confirmed',
        color: '#00E676',
        icon: 'check',
      },
      Cancelada: {
        label: 'Canceled',
        color: '#ff1347',
        icon: 'close',
      },

    },
    appointmentPropsType: {
      Photocall: {
        label: 'Photocall',
        color: '#ff6347',
        icon: 'work',
      },
      Neon: {
        label: 'Neon',
        color: '#ffc04a',
        icon: 'event',
      },
      Album: {
        label: 'Album',
        color: '#ffc04a',
        icon: 'event',
      },
      Plantilla: {
        label: 'Plantilla',
        color: '#ff4717',
        icon: 'photo',
      },
      Extras: {
        label: 'Extras',
        color: '#ff1347',
        icon: 'other_houses',
      },
    },

  },

};
// eslint-disable-next-line func-names
Options.install = function (Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getColor = (key) => Options.colors[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getPrimaryButton = (key) => Options.primaryButton[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getValues = (key) => Options.values[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getInscriptionTypeValues = (key) => Options.values.inscriptionType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getRoleTypeValues = (key) => Options.values.roles[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getGenderValues = (key) => Options.values.gender[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getEventTypeValues = (key) => Options.values.eventType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getAppointmentTypeValues = (key) => Options.values.appointmentType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getAppointmentPaidStatusValues = (key) => Options.values.appointmentPaidStatus[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getBudgetTypeValues = (key) => Options.values.budgetType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getProjectTypeValues = (key) => Options.values.projectType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getStatusValues = (key) => Options.values.status[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getFeesOptions = (key) => Options.values.feesOptions[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getCardOptions = (key) => Options.cards[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getAppointmentPropsTypeValues = (key) => Options.values.appointmentPropsType[key];
};

export default Options;
