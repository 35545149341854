<template>
  <div class="page-layout">
    <modal-login title="Iniciar sesión" icon="login" ref="modalLogin">
    </modal-login>
    <section class="main-container">
      <div class="bg-image">
        <img v-if="isMobile()" src="@/assets/images/fondo-movil.svg" alt="">
        <img v-else src="@/assets/images/fondo-desktop.svg" alt="">
      </div>
      <div class="main-container--inner">
        <div class="login-logo">
          <h1 class="first-line">Funpic<sup>®</sup></h1>
          <h2 class="second-line">Fotomatón</h2>
        </div>
        <div class="login-buttons">
          <primary-button appearance="outlined" text="Iniciar sesión" size="large" v-on:click.native="openModal" />
          <primary-button appearance="solid" text="Nueva reserva" size="large" v-on:click.native="goToNewRegister" />
          <div class="mx-auto text-center">
            Si necesitas más información sobre nuestros servicios,
            <a class="link-reservation" @click="goToInfo()"  @keydown="goToInfo()" color="accent">
              pincha aquí
            </a>
          </div>
        </div>
        <!-- footer container for footer links -->
        <div class="footer-container">
          <div class="footer-links">
            <a class="link-reservation"  @click="goToAccesibility()" @keydown="goToAccesibility()" color="accent">
              Accesibilidad
            </a>
            <a class="link-reservation"   @click="goToKitDigital()" @keydown="goToKitDigital()" color="accent">
              Kit digital
            </a>
            <a class="link-reservation" to="/sitemap" @click="goToSitemap()" @keydown="goToSitemap()" color="accent">
              Sitemap
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import ModalLogin from './ModalLogin.vue';

export default {
  name: 'login-view',
  mixins: [validationMixin],
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      message: '',
      sending: false,
      showPasswordRecovery: false,
      passwordRecoveryEmail: '',
    };
  },
  validations: {
    input: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  components: {
    ModalLogin,
    PrimaryButton,
  },

  methods: {
    goToKitDigital() {
      this.$router.push({ name: 'KitDigital' });
    },
    goToAccesibility() {
      this.$router.push({ name: 'Accesibility' });
    },
    goToSitemap() {
      this.$router.push({ name: 'Sitemap' });
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
    goToNewRegister() {
      this.$router.push({ name: 'Appointment Register' });
    },
    goToFastRegister() {
      this.$router.push({ name: 'FastRegister' });
    },
    goToInfo() {
      // redirect to url: https://funpicfotomaton.com/bodas-fotomaton-alquiler/
      window.open('https://funpicfotomaton.com/bodas-fotomaton-alquiler/', '_blank');
    },
    openModal() {
      this.$refs.modalLogin.openModal();
    },
    getValidationClass(fieldName) {
      const field = this.$v.input[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return '';
    },

    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.handleLogin();
      }
    },
    handleLogin() {
      this.sending = true;
      const user = {};
      user.username = this.input.username;
      user.password = this.input.password;

      this.loading = true;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          if (error.response.status === 403) {
            this.message = 'Not valid username or password';
          } else {
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
          }
          this.sending = false;
        },
      );
    },
    handleEmailRecovery() {
      this.sending = true;
      this.loading = true;

      this.$store
        .dispatch('auth/recoverPassword', this.passwordRecoveryEmail)
        .then(
          () => {
            this.loading = false;
            this.message = 'Email with recovery link was sent';
            this.sending = false;
          },
          (error) => {
            this.loading = false;
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
            this.sending = false;
          },
        );
    },
  },
};
</script>
<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.link-reservation {
  margin: 0 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.login-logo {
  margin-top: calc(var(--base) * 16);
  margin-bottom: calc(var(--base) * 4);

  @media (min-width: 1024px) {
    margin-top: calc(var(--base) * 24);
    margin-top: 30%;
    // margin-bottom: calc(var(--base) * 24);
  }

  .first-line {
    font-size: calc(var(--base) * 10);
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.03em;
    margin: 0;
    padding: 0;
    margin-left: -0.08em;

    sup {
      font-weight: 500;
    }
  }

  .second-line {
    margin: 0;
    padding: 0;
    font-size: calc(var(--base) * 2);
    font-weight: 300;
    letter-spacing: -0.03em;
  }
}

.login-buttons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: calc(var(--base) * 12);
  row-gap: calc(var(--base) * 2);

  @media (min-width: 1024px) {
    margin-bottom:auto;
  }
}

.main-container {
  max-height: var(--app-height);

  .main-container--inner {
    max-height: var(--app-height);
  }
}
.aside-container{
  padding:0 !important;
}

.link-reservation {
  color: rgba(var(--bodyColor), 1) !important;
  text-decoration: underline !important;

  &:hover {
    color: var(--colorAccent) !important;
  }
}
</style>
