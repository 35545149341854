<template>
    <div class="field-group">
    <div :class="priceClass()">
          <div class="price-summary--item" v-if="this.prices.basePrice">
              <span class="price-summary--concept">Base:</span>
              <span class="price-summary--description">{{ this.prices.basePrice.description }}</span>
              <span class="price-summary--price">{{ this.prices.basePrice.price }} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.extraPrice">
              <span class="price-summary--concept">Transporte:</span>
              <!-- <span class="price-summary--description">{{ this.prices.extraPrice.description}}</span> -->
              <span class="price-summary--price">+ {{ this.prices.extraPrice.price }} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.photocall">
              <span class="price-summary--concept">Photocall:</span>
              <span class="price-summary--description"
                >{{this.prices.photocall.name}}</span>
              <span class="price-summary--price">+ {{this.prices.photocall.price}} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.neon">
              <span class="price-summary--concept">Neón:</span>
              <span class="price-summary--description">
                {{ this.prices.neon.name }}
                <span v-if="this.prices.neonPersonalizado">({{ this.prices.neonPersonalizado}})</span>
                <span v-if="this.prices.neon.name === 'Personalizado' && !this.prices.neonPersonalizado">(Sin configurar)</span>
              </span>

              <span class="price-summary--price">+ {{this.prices.neon.price}} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.album">
              <span class="price-summary--concept">Álbum:</span>
              <span class="price-summary--description">{{this.prices.album.name}}</span>
              <span class="price-summary--price">+ {{this.prices.album.price}} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.plantilla">
              <span class="price-summary--concept">Fotos:</span>
              <span class="price-summary--description"> {{this.prices.plantilla.name}} ({{this.prices.plantilla.size}}) </span>
              <span class="price-summary--price">+ {{this.prices.plantilla.price}} €</span>
            </div>
            <div v-for="extra in this.prices.extras" :key="extra.id" >
              <div class="price-summary--item">
                <span class="price-summary--concept">Extra:</span>
                <span  class="price-summary--description">
                  {{extra.name}}
                </span>
                <span class="price-summary--price">+ {{extra.price}} €</span>
              </div>
            </div>
            <div v-for="(extra, index) in this.prices.extrasWithDiscount" :key="index">
            <div class="price-summary--item">
              <span class="price-summary--concept">Extra:</span>
              <span class="price-summary--description">
                {{ extra.name }}
              </span>
              <span class="price-summary--price">+ {{ extra.price - extra.discount }} € <del style="color:red">{{ extra.price }} € </del></span>
            </div>
          </div>
            <div class="price-summary--item" v-if="this.prices.coupon && this.prices.coupon.code">
              <span class="price-summary--concept">Cupón de descuento:</span>
              <span class="price-summary--description"> {{this.prices.coupon.code}} </span>
              <span class="price-summary--price">- {{this.prices.coupon.discount}} €</span>
            </div>

            <div class="price-summary--total" v-if="this.prices.totalPrice">
              <span class="price-summary--concept">Total:</span>
              <span class="price-summary--description" v-if="isPaid">(-100€ de reserva)</span>
              <span class="price-summary--price">{{this.prices.totalPrice}} €</span>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  name: 'Prices-Component',
  props: {
    prices: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updatePrices(data) {
      this.prices = data;
    },
    priceClass() {
      if (this.isMobile) {
        return 'price-summary--container sumatorio-group';
      }
      return 'price-summary--container';
    },
  },
};
</script>
<style lang="scss" scoped>
.field-group{
  margin-bottom: calc(var(--base)*2);
  @media (min-width: 1024px) {
    margin-bottom: 1rem;
  }
}
.option-selector--swiper {
  &::before {
    background: var(--colorGrey) !important;
  }

  @media (min-width: 1024px) {
    &::after {
      background: linear-gradient(270deg,
          rgba(var(--colorGreyRGB), 1) 0%,
          rgba(var(--colorGreyRGB), 0) 80%);
    }

  }
}</style>
