<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
      <section-header
        first="Ha llegado el momento de"
        second="Configurar tu fotomatón"
      />
      <div class="configuration-menu">
        <configuration-card
          title="Photocall"
          description="Elige entre los distintos fondos para el Photcall y añade el neón."
          :stepNumber="1"
          color="customGreen"
        />
        <configuration-card
          title="Album"
          description="Elige entre distintos albumes y selecciona el diseño que más te guste."
          :stepNumber="2"
          color="customPurple"
        />
        <configuration-card
          title="Plantillas"
          description="Selecciona tu plantilla entre las muchas disponibles."
          :stepNumber="3"
          color="customPink"
        />
        <configuration-card
          title="Extras"
          description="Añade los extras que elijas a tu fotomatón y personalízalo."
          :stepNumber="4"
          color="customRed"
        />
      </div>
      <div class="navigation-buttons">
        <primary-button
          appearance="text"
          text="Atrás"
          size="small"
          v-on:click.native="backStep"
        />
        <primary-button
          appearance="solid"
          text="Siguiente"
          size="small"
          v-on:click.native="nextStep"
        />
      </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img
          src="@/assets/images/videomaton_big.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>
<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import ConfigurationCard from '@/components/Cards/ConfigurationCard.vue';

export default {
  name: 'funpic4-view',
  components: {
    SectionHeader,
    PrimaryButton,
    ConfigurationCard,
  },
  methods: {
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
  },
};

</script>
<style lang="scss" scoped>
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}
// .check-buttons {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   column-gap: calc(var(--base) * 2);
//   margin-bottom:auto;
// }

.aside-container{
  padding:0 !important;
  height:100%;
}
// .aside-image{
//   position:absolute;
//   bottom:0;
//   left:0;
//   top: 0;
//   right: 0;
//   display:flex;
//   justify-content:center;
//   align-items:center;
//   img{
//     width:100%;
//     height:100%;
//     object-fit:cover;
//   }
// }

// .navigation-buttons{
//   margin-top: 10% !important;
//   margin-bottom: auto !important;
// }

.main-container {
  overflow-y: hidden;

  &::after {
    position: absolute;
    content: 'Scroll para ver más';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreyRGB), 0) 0%, rgba(var(--colorGreyRgb), 1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}

</style>
