<template>
  <div class="full-height">
    <!-- Step 1 -->
    <v-stepper v-model="e6" flat outlined>
      <!-- Step 3 -->
      <v-stepper-content step="3" class="p-0 m-0">
            <middle-step @nextStep="nextStep" @backStep="goToHome">
            </middle-step>
      </v-stepper-content>
      <!-- Step 4 -->
      <v-stepper-content step="4" class="p-0 m-0">
            <photocall-register @updateData="updatePhotocallData" ref="photocall" :isPaid="true"
              @updateValid="updatePhotocallValid" @nextStep="nextStep" @backStep="backStep">
            </photocall-register>
      </v-stepper-content>
      <!-- Step 5 -->
      <v-stepper-content step="5" class="p-0 m-0">
            <album-register @updateData="updateAlbumData" ref="album" :isPaid="true"
              @updateValid="updateAlbumValid" @nextStep="nextStep" @backStep="backStep">
            </album-register>
      </v-stepper-content>
      <!-- Step 6 -->
      <v-stepper-content step="6" class="p-0 m-0">
            <photos-register @updateData="updatePhotosData" ref="photos" :isPaid="true"
              @updateValid="updatePhotosValid" @nextStep="nextStep" @backStep="backStep">
            </photos-register>
      </v-stepper-content>
      <!-- Step 7 -->
      <v-stepper-content step="7" class="p-0 m-0">
            <extras-register @updateData="updateExtrasData" ref="extras" :isPaid="true"
              @updateValid="updateExtrasValid" @nextStep="nextStep" @backStep="backStep">
            </extras-register>
      </v-stepper-content>
      <!-- Step 8 -->
      <v-stepper-content step="8" class="p-0 m-0">
            <summary-configuration @updateCoupon="updateCoupon"  ref="summaryConfiguration"
              @nextStep="nextStep" @backStep="backStep" :isPaid="true">
            </summary-configuration>
      </v-stepper-content>
      <!-- Step 9 -->
      <v-stepper-content step="9" class="p-0 m-0">
        <info-register @updateData="updateInfoData"
            @updateValid="updateInfoValid"  @nextStep="nextStep" @backStep="backStep">
        </info-register>
      </v-stepper-content>
      <!-- Step 10 -->
      <v-stepper-content step="10" class="p-0 m-0">
        <finish-configuration  ref="finishConfiguration" :isPaid="true"
              @nextStep="newRegister" @backStep="backStep">
          </finish-configuration>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>

import PhotocallRegister from '@/components/Register/Appointment/PhotocallRegister.vue';
import AlbumRegister from '@/components/Register/Appointment/AlbumRegister.vue';
import PhotosRegister from '@/components/Register/Appointment/PhotosRegister.vue';
import ExtrasRegister from '@/components/Register/Appointment/ExtrasRegister.vue';
import InfoRegister from '@/components/Register/Appointment/InfoRegister.vue';
import MiddleStep from '@/components/Register/Appointment/MiddleStep.vue';
import SummaryConfiguration from '@/components/Register/Appointment/SummaryConfiguration.vue';
import FinishConfiguration from '@/components/Register/Appointment/FinishConfiguration.vue';
import authHeader from '../../services/auth-header';
// eslint-disable-next-line import/no-cycle
import axios from '../../services/http-common';

export default {
  name: 'configure-appointment',

  data() {
    return {
      e6: 3,
      captcha: '',
      user: null,
      checkAvailabilityData: {
        type: null,
        eventTime: null,
        totalOnSelectedDate: null,
      },
      checkRegisterData: {
        name: null,
        postalCode: null,
        direction: null,
        postalCodeItem: null,
        distance: null,
      },
      photocallData: {
        photocall: null,
        neon: null,
        neonCustom: null,
      },
      albumData: {
        album: null,
      },
      photosData: {
        photos: null,
        size: null,
      },
      extrasData: {
        extras: null,
      },
      personalData: {
        name: null,
        email: null,
        phone: null,
        idDocument: null,
        streetName: null,
      },
      infoData: {
        namePartners: null,
        lastname1: null,
        namePlace: null,
        streetPlace: null,
        phone2: null,
        guestsNumber: null,
        time: null,
      },
      coupon: {
        coupon: null,
      },
      valid: {
        checkAvailability: false,
        checkRegister: false,
        photocall: false,
        album: false,
        photos: false,
        extras: false,
        personalData: false,
        info: false,
      },
      originCoordinates: {
        latitude: -3.8752786669764077,
        longitude: 40.489787823760636,
      },
      isPaid: true,
      priorityOptions: null,
    };
  },
  computed: {
    allValid() {
      return !!(!this.valid.checkAvailability.$invalid
        && !this.valid.checkRegister.$invalid && !this.valid.photocall.$invalid
        && !this.valid.album.$invalid && !this.valid.photos.$invalid
        && !this.valid.extras.$invalid && !this.valid.personalData.$invalid
        && !this.valid.info.$invalid);
    },
    basePrice() {
      const basePrice = {
        description: null,
        price: null,
      };
      if (this.checkAvailabilityData.eventTime) {
        if (this.isWeekend(this.checkAvailabilityData.eventTime)) {
          basePrice.description = 'Temporada Alta';
          basePrice.price = 485;
        } else {
          basePrice.description = 'Temporada Baja';
          basePrice.price = 465;
        }
      }
      return basePrice;
    },

    extraPrice() {
      const base = 0.27;
      const extraPrice = {};
      if (this.checkRegisterData.distance) {
        if (this.checkRegisterData.distance < 50) {
          extraPrice.description = 'Distancia menor a 50km';
          extraPrice.price = 0;
        } else {
          extraPrice.price = (this.checkRegisterData.distance * base).toFixed(2);
          extraPrice.description = `${this.checkRegisterData.distance}km * 0.27€/km`;
        }
      }
      return extraPrice;
    },

    totalPrice() {
      let total = 0;
      if (this.basePrice.price) {
        total += parseFloat(this.basePrice.price);
      }
      if (this.extraPrice.price) {
        total += parseFloat(this.extraPrice.price);
      }
      if (this.photocallData.photocall) {
        total += parseFloat(this.photocallData.photocall.price);
      }
      if (this.photocallData.neon) {
        total += parseFloat(this.photocallData.neon.price);
      }
      if (this.albumData.album) {
        total += parseFloat(this.albumData.album.price);
      }
      if (this.photosData.photos) {
        total += parseFloat(this.photosData.photos.price);
      }
      if (this.extrasData.extras && this.extrasData.extras.length > 0) {
        this.extrasData.extras.forEach((extra) => {
          total += parseFloat(extra.price);
        });
      }
      if (this.coupon.code) {
        total -= parseFloat(this.coupon.discount);
      }
      if (this.isPaid) {
        // total = total - 100
        total -= 100;
      }
      return total.toFixed(2);
    },

    prices() {
      const prices = {};
      if (this.basePrice.price) {
        prices.basePrice = this.basePrice;
      }
      if (this.extraPrice.price) {
        prices.extraPrice = this.extraPrice;
      }
      if (this.photocallData.photocall) {
        prices.photocall = this.photocallData.photocall;
      }
      if (this.photocallData.neon) {
        prices.neon = this.photocallData.neon;
      }
      if (this.albumData.album) {
        prices.album = this.albumData.album;
      }
      if (this.photosData.photos) {
        prices.plantilla = this.photosData.photos;
      }
      if (this.extrasData.extras && this.extrasData.extras.length > 0) {
        prices.extras = this.extrasData.extras;
      }
      if (this.coupon) {
        prices.coupon = this.coupon;
      }
      if (this.totalPrice) {
        prices.totalPrice = this.totalPrice;
      }

      return prices;
    },

    distanceText() {
      if (this.checkRegisterData.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }
      return null;
    },
  },
  components: {
    PhotocallRegister,
    AlbumRegister,
    PhotosRegister,
    ExtrasRegister,
    InfoRegister,
    MiddleStep,
    SummaryConfiguration,
    FinishConfiguration,
  },
  mounted() {
    this.loadData();
    this.getPriorityItems();
  },

  watch: {
    prices() {
      this.updatePrices();
    },
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'HomeIndex' });
    },
    getDistance() {
      if (this.checkRegisterData.postalCodeItem) {
        if (this.checkRegisterData.postalCodeItem.distance) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.checkRegisterData.distance = this.checkRegisterData.postalCodeItem.distance.toFixed(0);
          return this.checkRegisterData.distance.toFixed(0);
        }
        const distance = this
          .getDistanceFromLatLonInKm(
            this.checkRegisterData.postalCodeItem.latitude,
            this.checkRegisterData.postalCodeItem.longitude,
            this.originCoordinates.latitude,
            this.originCoordinates.longitude,
          );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.checkRegisterData.distance = distance.toFixed(0);
        return distance.toFixed(0);
      }
      return null;
    },
    nextStep(val) {
      if (val) {
        this.e6 += 1;
        if (this.$refs.summaryConfiguration) {
          this.$refs.summaryConfiguration.updateData(
            this.checkAvailabilityData,
            this.checkRegisterData,
            this.photocallData,
            this.albumData,
            this.photosData,
            this.extrasData,
          );
        }
        if (this.$refs.finishConfiguration) {
          this.$refs.finishConfiguration.updateData(
            this.checkAvailabilityData,
            this.checkRegisterData,
            this.photocallData,
            this.albumData,
            this.photosData,
            this.extrasData,
            this.personalData,
            this.infoData,
            this.coupon,
          );
        }

        this.updatePrices();
      }
    },
    backStep() {
      this.e6 -= 1;
    },
    updatePrices() {
      if (this.$refs.photocall) {
        this.$refs.photocall.updatePrices(this.prices);
      }
      if (this.$refs.album) {
        this.$refs.album.updatePrices(this.prices);
      }
      if (this.$refs.photos) {
        this.$refs.photos.updatePrices(this.prices);
      }
      if (this.$refs.extras) {
        this.$refs.extras.updatePrices(this.prices);
      }
      if (this.$refs.summaryConfiguration) {
        this.$refs.summaryConfiguration.updatePrices(this.prices);
      }
      if (this.$refs.finishConfiguration) {
        this.$refs.finishConfiguration.updatePrices(this.prices);
      }
    },
    // method to check if a date is Saturday and month is between June and September
    isWeekend(date) {
      const calcDate = new Date(date);
      const day = calcDate.getDay();
      const month = calcDate.getMonth();
      return !!(day === 6 && month >= 4 && month <= 9);
    },

    onVerify(response) {
      this.captcha = response;
    },

    updateCheckAvailabilityData(data) {
      this.checkAvailabilityData = data;
    },
    updateCheckAvailabilityValid(valid) {
      this.valid.checkAvailability = valid;
    },
    updateCheckRegisterData(data) {
      this.checkRegisterData = data;
    },
    updateCheckRegisterValid(valid) {
      this.valid.checkRegister = valid;
    },
    updatePhotocallData(data) {
      this.photocallData = data;
    },
    updatePhotocallValid(valid) {
      this.valid.photocall = valid;
    },
    updateAlbumData(data) {
      this.albumData = data;
    },
    updateAlbumValid(valid) {
      this.valid.album = valid;
    },
    updatePhotosData(data) {
      this.photosData = data;
    },
    updatePhotosValid(valid) {
      this.valid.photos = valid;
    },
    updateExtrasData(data) {
      this.extrasData = data;
    },
    updateExtrasValid(valid) {
      this.valid.extras = valid;
    },
    updatePersonalDataData(data) {
      this.personalData = data;
    },
    updatePersonalDataValid(valid) {
      this.valid.personalData = valid;
    },
    updateInfoData(data) {
      this.infoData = data;
    },
    updateInfoValid(valid) {
      this.valid.info = valid;
    },
    updateCoupon(data) {
      this.coupon = data;
    },
    setUniqueEventSelected(val) {
      this.$store.commit('setUniqueEventId', val);
    },

    loadData() {
      this.$store.dispatch('affiliates/getLoggedAffiliate').then(() => {
        this.user = this.$store.state.affiliates.loggedAffiliate;
        this.checkAvailabilityData = {
          type: this.user.appointment.type,
          eventTime: this.user.appointment.eventDate,
        };
        this.checkRegisterData = {
          name: this.user.appointment.name,
          postalCode: this.user.appointment.postalCode,
          direction: this.user.appointment.direction,
          postalCodeItem: null,
          distance: null,
        };
        this.personalData = {
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone,
          idDocument: this.user.idDocument,
          streetName: this.user.appointment.direction,
        };
        this.getPostalCode(this.user.appointment.postalCode);
      })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },

    getPostalCode(id) {
      const postalCode0 = this.priorityOptions.find((item) => item.id === Number(id));
      // if postalCode is in priorityOptions
      if (postalCode0) {
        this.checkRegisterData.postalCodeItem = postalCode0;
      } else {
        axios
          .get(`${process.env.VUE_APP_API_URL}postal-code/${id}`, {
            headers: authHeader(),
          })
          .then((response) => {
            this.checkRegisterData.postalCodeItem = response.data;
            this.getDistance();
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          });
      }
    },
    getPriorityItems() {
      axios.get(
        `${process.env.VUE_APP_API_URL}postal-code-distance/all`,
      )
        .then((response) => {
          this.priorityOptions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    newRegister() {
      if (!this.allValid) {
        return;
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}affiliate/update`,
          {
            // name: this.personalData.name,
            // email: this.personalData.email,
            // phone: this.personalData.phone,
            // idDocument: this.personalData.idDocument,
            // streetName: this.personalData.streetName,
            // documentType: 'DNI',
            // inscriptionType: 1,
            // postalCode: this.checkRegisterData.postalCode,
            lastName1: this.infoData.lastName1,

            appointmentDto: {
              // type: this.checkAvailabilityData.type,
              namePartners: this.infoData.namePartners,
              phone: this.infoData.phone2,
              guestsNumber: this.infoData.guestsNumber,
              namePlace: this.infoData.namePlace,
              streetPlace: this.infoData.streetPlace,
              time: this.infoData.time,
              // eventDate: this.checkAvailabilityData.eventTime,
              // name: this.checkRegisterData.name,
              // postalCode: this.checkRegisterData.postalCode,
              // direction: this.checkRegisterData.direction,
              photocallId: this.photocallData.photocall.id,
              neonId: this.photocallData.neon ? this.photocallData.neon.id : null,
              albumId: this.albumData.album.id,
              plantillaId: this.photosData.photos.id,
              // extrasId as new array of ids
              extrasId: this.extrasData.extras.map((extra) => extra.id),
              couponCode: this.coupon.code,
              neonPersonalizado: this.photocallData.neonCustom,
              priceForStats: this.prices.totalPrice,
            },
          },
          { headers: authHeader() },
        )
        .then(() => {
          // console.log(response);
          // router push to Home view
          this.$router.push({ name: 'HomeIndex' });
        })
        .catch((error) => {
          console.log(error);
          this.$router.push('/inscripcion/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .grecaptcha-badge {
    visibility: hidden;
    opacity: 0.3;
}

</style>
<style scoped>
.container{
  padding: 0!important;
}
::v-deep .container--fluid {
    padding: 0!important;
}
.preview-canvas {
    max-height: 40%;
    margin-bottom: calc(var(--base));
}
</style>
