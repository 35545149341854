<template>
  <div class="full-height">
    <div class="download mb-1" v-if="user.appointment">
      <!-- <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            :color="$getAppointmentPaidStatusValues(user.appointment.paidStatus).color"
            :dark="$getAppointmentPaidStatusValues(user.appointment.paidStatus).dark"
            v-bind="attrs"
            v-on="on"
            @click="toggleEditPaidStatus()"
            large
            v-if="!isEditingPaidStatus"
          >
            {{ $getAppointmentPaidStatusValues(user.appointment.paidStatus).label}}
          </v-btn> -->
      <div class="select-box" v-if="user.appointment.paidStatus">
        <v-select
          :items="paidStatusArray"
          item-text="label"
          item-value="value"
          label="Estado del pago"
          v-model="user.appointment.paidStatus"
          @change="updatePaidStatus(user.appointment.id)"
          outlined
          :readonly="!adminAvailable && !superAdminAvailable"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" :color="item.color" :dark="item.dark">
              <span>{{ item.label }}</span>
            </v-chip>
          </template></v-select
        >
      </div>
      <v-tooltip bottom v-if="user.appointment.neon && !user.appointment.neonInStock">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            color="#ffd0d0"
            v-bind="attrs"
            v-on="on"
            @click="addNeonInStock(user.appointment.id)"
            large
          >
            <v-icon left>check</v-icon>
            Neón
          </v-btn>
        </template>
        <span>Marcar como neón en almacén</span>
      </v-tooltip>
      <v-tooltip bottom v-if="user.appointment.neon && user.appointment.neonInStock">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            color="#ffd0d0"
            v-bind="attrs"
            v-on="on"
            @click="removeNeonInStock(user.appointment.id)"
            large
          >
            <v-icon left>close</v-icon>
            Eliminar neón
          </v-btn>
        </template>
        <span>Borrar como neón en almacén</span>
      </v-tooltip>
      <!-- -->
      <v-tooltip bottom v-if="!user.appointment.albumInStock">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de"
            v-bind="attrs"
            v-on="on"
            @click="addAlbumInStock(user.appointment.id)"
            large
          >
            <v-icon left>check</v-icon>
            Álbum
          </v-btn>
        </template>
        <span>Marcar como álbum en almacén</span>
      </v-tooltip>
      <v-tooltip bottom v-if="user.appointment.albumInStock">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de"
            v-bind="attrs"
            v-on="on"
            @click="removeAlbumInStock(user.appointment.id)"
            large
          >
            <v-icon left>close</v-icon>
            Eliminar album
          </v-btn>
        </template>
        <span>Borrar como album en almacén</span>
      </v-tooltip>
      <v-dialog v-model="pdfDialog" class="dialog-wrapper" width="75%" max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :elevation="0"
            :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')"
            color="#ffdef2"
            v-bind="attrs"
            v-on="on"
            large
          >
            <v-icon left>download</v-icon>
            Exportar a PDF
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="h5">Exportar a PDF</span>
          </v-card-title>
          <v-card-text class="dialog-content pb-0">
            <div class="input-field">
              <!-- <label class="input-field--label">Azafatos:</label> -->
              <v-text-field
                hide-details
                outlined
                flat
                color="black"
                label="Azafatos"
                v-model="pdfAzafatos"
              ></v-text-field>
            </div>
            <div class="input-field">
              <!-- <label class="input-field--label">Burgo Centro:</label> -->
              <v-text-field
                hide-details
                outlined
                flat
                color="black"
                label="Almacén"
                v-model="pdfBurgoCentro"
              ></v-text-field>
            </div>
            <div class="input-field">
              <!-- <label class="input-field--label">Nº Máquina:</label> -->
              <v-text-field
                hide-details
                outlined
                flat
                color="black"
                label="Nº Máquina"
                v-model="pdfNumMaquina"
              ></v-text-field>
            </div>
            <!-- <div class="input-field">

              <v-text-field hide-details outlined flat color="black" label="Hora de comienzo"
                v-model="pdfHoraComienzo"></v-text-field>
            </div> -->
            <div class="input-field">
              <!-- <label class="input-field--label">Pagado:</label> -->
              <v-text-field
                hide-details
                outlined
                flat
                color="black"
                label="Pagado"
                v-model="pdfPagado"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="navigation-buttons">
              <primary-button
                appearance="text"
                text="Cancelar"
                size="small"
                v-on:click.native="pdfDialog = false"
              />
              <primary-button
                appearance="solid"
                text="Exportar PDF"
                size="small"
                @click.native="exportToPDF()"
              />
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="page-layout">
      <section class="main-container">
        <div class="main-container--inner p-0 no-scrollbar">
          <!-- -->
          <section-header first="Panel del" second="Evento" />
          <preview-canvas :previewImg="previewImg" v-show="isMobile" />
          <div class="field-group chosen-config-group">
            <label for="" class="field-group--label"> Configuración elegida: </label>
            <div
              ref="swiperFinal"
              class="option-selector--swiper swiper"
              :class="{ loading: !this.swiperLoaded }"
            >
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper" v-if="user.appointment">
                <!-- Slides -->
                <selection-card
                  v-if="user.appointment.photocall"
                  :name="user.appointment.photocall.name"
                  class="swiper-slide customGreen"
                  size="small"
                  :price="user.appointment.photocall.price"
                  :img="user.appointment.photocall.imageUrl"
                  v-on:click.native="setValue(user.appointment.photocall.imageUrl)"
                  selected
                />
                <selection-card
                  v-if="user.appointment.neon"
                  :name="user.appointment.neon.name"
                  class="swiper-slide customGreen"
                  size="small"
                  :price="user.appointment.neon.price"
                  :img="user.appointment.neon.imageUrl"
                  v-on:click.native="setValue(user.appointment.neon.imageUrl)"
                  selected
                />
                <selection-card
                  v-if="user.appointment.album"
                  :name="user.appointment.album.name"
                  class="swiper-slide customPurple"
                  size="small"
                  :price="user.appointment.album.price"
                  :img="user.appointment.album.imageUrl"
                  v-on:click.native="setValue(user.appointment.album.imageUrl)"
                  selected
                />
                <selection-card
                  v-if="user.appointment.plantilla && user.appointment.plantilla.name"
                  :name="
                    user.appointment.plantilla.name + ' (' + user.appointment.plantilla.size + ')'
                  "
                  class="swiper-slide customPink"
                  size="small"
                  :price="user.appointment.plantilla.price"
                  :img="user.appointment.plantilla.imageUrl"
                  v-on:click.native="setValue(user.appointment.plantilla.imageUrl)"
                  selected
                />
                <selection-card
                  v-for="item in user.appointment.extras"
                  :key="item.id"
                  :name="item.name"
                  class="swiper-slide customRed"
                  size="small"
                  :price="item.price"
                  :img="item.imageUrl"
                  v-on:click.native="setValue(item.imageUrl)"
                  selected
                />
                <selection-card
                  v-for="(item, index) in user.appointment.extrasWithDiscount"
                  :key="index"
                  :name="item.name"
                  class="swiper-slide customRed"
                  size="small"
                  :price="item.price"
                  :priceWithDiscount="item.price - item.discount"
                  :img="item.imageUrl"
                  v-on:click.native="setValue(item.imageUrl)"
                  selected
                />
              </div>
            </div>
          </div>
          <div class="selection-button--container field-group">
            <selection-button
              v-if="superAdminAvailable"
              title="Editar configuración"
              @click.native="editAffiliateInfo"
              enabled
            />
          </div>
          <div class="field-group" v-if="user.appointment">
            <label for="" class="field-group--label"> Resumen: </label>
            <div class="data--container">
              <div class="data--item">
                <span class="data--label">Fecha:</span>
                <span class="data--value">{{ dateOfAppointment(user.appointment.eventDate) }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Horario:</span>
                <span class="data--value">{{ this.getTypeLabel(user.appointment.type) }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Lugar del evento:</span>
                <span class="data--value">{{ user.appointment.name }}</span>
              </div>
              <!--
                <div class="data--item">
                <span class="data--label">Dirección de la finca:</span>
                <span class="data--value">{{ user.appointment.streetPlace }}</span>
              </div>
              -->

              <!-- <div class="data--item" v-if="user.appointment.postalCode">
                <span class="data--label">Código Postal:</span>
                <span class="data--value">{{ user.appointment.postalCode }}</span>
              </div> -->
            </div>
          </div>
          <prices-component
            v-if="isMobile && this.prices"
            :prices="prices"
            :isPaid="isPaid"
          ></prices-component>
          <v-divider v-if="user.appointment && superAdminAvailable"></v-divider>
          <div class="field-group" v-if="user.appointment && superAdminAvailable">
            <label for="" class="field-group--label mb-6"> Código de descuento: </label>
            <div class="discount-code--container">
              <v-text-field
                v-if="user.appointment.discountCoupon"
                :placeholder="
                  user.appointment.discountCoupon.code +
                  ' (' +
                  user.appointment.discountCoupon.discount +
                  '€ de descuento)'
                "
                outlined
                dense
                color="black"
                class="discount-code--input"
                :disabled="true"
                hide-details
              ></v-text-field>
              <v-text-field
                v-if="!user.appointment.discountCoupon"
                v-model="couponCode"
                placeholder="Cupón de descuento"
                outlined
                dense
                color="black"
                class="discount-code--input"
                hide-details
              ></v-text-field>
              <primary-button
                v-if="!user.appointment.discountCoupon"
                appearance="outlined"
                text="Aplicar"
                size="small"
                class="discount-code--button"
                v-on:click.native="getCoupon(couponCode)"
                :enabled="true"
              />
            </div>
          </div>
          <!-- Text neon personalizado -->
          <div
            class="selection-button--container field-group"
            v-if="user.appointment && checkCustomNeon"
          >
            <label class="selection-button--label field-group--label" for=""
              >Neón personalizado:</label
            >
            <div class="discount-code--container">
              <v-text-field
                :placeholder="user.appointment.neonPersonalizado || 'Sin configurar'"
                outlined
                dense
                color="black"
                class="discount-code--input"
                :disabled="
                  user.appointment.neonPersonalizado !== null &&
                  user.appointment.neonPersonalizado !== ''
                "
                hide-details
                v-model="neonCustomTemp"
              ></v-text-field>
              <primary-button
                v-if="
                  user.appointment.neonPersonalizado == null &&
                  user.appointment.neonPersonalizado == ''
                "
                appearance="outlined"
                text="Aplicar"
                size="small"
                class="discount-code--button"
                v-on:click.native="updateNeonText()"
                :enabled="false"
              />
            </div>
          </div>
          <!-- Image album personalizado -->
          <div
            class="selection-button--container field-group"
            v-if="checkImageAlbumUploaded === 'not loaded' && superAdminAvailable"
          >
            <!-- <pre>{{ imageFileAlbum || 'sin album' }}</pre> -->
            <label class="selection-button--label field-group--label" for=""
              >Álbum personalizado:</label
            >
            <v-file-input
              show-size
              counter
              outlined
              :color="$getColor('vuetifyColor1')"
              label="Adjuntar imagen"
              v-model="imageFileAlbum"
              hint="Tamaño mínimo 1100*1100px"
              persistent-hint
              :dense="true"
            >
            </v-file-input>
            <primary-button
              v-if="imageFileAlbum"
              appearance="outlined"
              text="Subir Imagen"
              size="small"
              v-on:click.native="uploadImageAlbum"
              enabled
            />
          </div>
          <div
            class="selection-button--container field-group"
            v-if="checkImageAlbumUploaded === 'loaded'"
          >
            <label class="selection-button--label field-group--label" for=""
              >Álbum personalizado:</label
            >
            <selection-button
              title="Descargar imagen"
              v-on:click.native="downloadAlbumImage"
              enabled
            />
          </div>
          <!-- Image plantilla personalizada -->
          <div
            class="selection-button--container field-group"
            v-if="checkImagePlantillaUploaded === 'not loaded' && superAdminAvailable"
          >
            <label class="selection-button--label field-group--label" for=""
              >Plantilla personalizada:</label
            >
            <v-file-input
              show-size
              counter
              outlined
              :color="$getColor('vuetifyColor1')"
              label="Adjuntar imagen"
              v-model="imageFilePlantilla"
              hint="Tamaño mínimo 500*500px"
              persistent-hint
              :dense="true"
            >
            </v-file-input>
            <primary-button
              v-if="imageFilePlantilla"
              appearance="outlined"
              text="Subir Imagen"
              v-on:click.native="uploadImagePlantilla"
              enabled
            />
          </div>
          <div
            class="selection-button--container field-group"
            v-if="checkImagePlantillaUploaded === 'loaded'"
          >
            <label class="selection-button--label field-group--label" for=""
              >Plantilla personalizada:</label
            >
            <selection-button
              title="Descargar imagen"
              v-on:click.native="downloadPlantillaImage"
              enabled
            />
          </div>
          <v-divider></v-divider>
          <!-- Datos personales -->
          <div class="field-group" v-if="user.appointment">
            <label for="" class="field-group--label"> Datos Personales: </label>
            <div class="data--container">
              <div class="data--item">
                <span class="data--label">Nombre:</span>
                <span class="data--value">{{ user.name }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Correo electrónico:</span>
                <span class="data--value">{{ user.email }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Teléfono:</span>
                <span class="data--value">{{ user.phone }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Domicilio:</span>
                <span class="data--value">{{ user.appointment.direction }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">DNI:</span>
                <span class="data--value">{{ user.idDocument }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Apellido de la novia:</span>
                <span class="data--value">{{ user.lastName1 }}</span>
              </div>
            </div>
          </div>
          <div class="selection-button--container field-group" v-if="superAdminAvailable">
            <selection-button
              title="Editar datos personales"
              v-on:click.native="editPersonalInfo"
              enabled
            />
          </div>
          <v-divider v-if="user.appointment"></v-divider>
          <div class="field-group" v-if="user.appointment">
            <label for="" class="field-group--label"> Datos del evento: </label>
            <div class="data--container">
              <div class="data--item">
                <span class="data--label">Fecha del evento:</span>
                <span class="data--value">{{ dateOfAppointment(user.appointment.eventDate) }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Nombre de la pareja:</span>
                <span class="data--value">{{ user.appointment.namePartners }}</span>
              </div>
              <!--
                <div class="data--item">
                <span class="data--label">Apellido de la novia:</span>
                <span class="data--value">{{ user.lastName1 }}</span>
              </div>
               -->

              <div class="data--item">
                <span class="data--label">Lugar del evento:</span>
                <span class="data--value">{{ user.appointment.name }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Dirección de la finca:</span>
                <span class="data--value">{{ user.appointment.streetPlace }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Teléfono de contacto el día de la boda:</span>
                <span class="data--value">{{ user.appointment.phone }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Número aproximado de invitados:</span>
                <span class="data--value">{{ user.appointment.guestsNumber }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Hora aproximada:</span>
                <span class="data--value">{{ user.appointment.time }}</span>
              </div>
            </div>
          </div>
          <div class="selection-button--container field-group" v-if="superAdminAvailable">
            <selection-button
              title="Editar datos del evento"
              v-on:click.native="editEventInfo"
              enabled
            />
          </div>
          <v-divider></v-divider>
          <!-- Image plantilla personalizada -->
          <div class="selection-button--container field-group">
            <label class="selection-button--label field-group--label" for="">Comentarios:</label>
            <div class="pt-2 pb-2" v-if="comments">
              <v-list-item
                v-for="message in comments"
                :key="message.id"
                style="padding: 0 !important"
              >
                <v-list-item-content class="left-message" v-if="user.email != message.from">
                  <v-list-item-subtitle> FUNPIC </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ message.creationTime }}
                  </v-list-item-subtitle>
                  <p class="pt-2">
                    {{ message.description }}
                  </p>
                </v-list-item-content>
                <v-list-item-content class="right-message" v-else>
                  <!-- <v-list-item-subtitle>
                    {{ message.from }}
                  </v-list-item-subtitle> -->
                  <v-list-item-subtitle class="caption">
                    {{ message.creationTime }}
                  </v-list-item-subtitle>
                  <p class="pt-2">
                    {{ message.description }}
                  </p>
                </v-list-item-content>
              </v-list-item>
            </div>
            <add-chat-comment
              :affiliateId="user.id"
              :affiliateEmail="user.email"
              title="Añadir comentario"
              icon="chat"
              :fabButton="false"
            >
            </add-chat-comment>
          </div>
        </div>
      </section>
      <section class="aside-container">
        <div class="logo-container" style="z-index: 1">
          <h1 class="first-line">Funpic<sup>®</sup></h1>
        </div>
        <preview-canvas :previewImg="previewImg"></preview-canvas>
        <prices-component
          v-if="this.prices"
          :prices="prices"
          :isPaid="isPaid"
          class="mb-0"
        ></prices-component>
      </section>
    </div>
    <div class="panel-grid mt-5">
      <configuration-data
        :appointmentValues="appointmentInfo"
        @updateData="updateAppointment"
        @nextStep="patchUser"
        :disabled="disabled"
        ref="configurationData"
      ></configuration-data>
      <info-data
        :userValues="userInfo"
        @updateData="updateUser"
        @nextStep="patchUser"
        :disabled="disabled"
        ref="infoData"
      >
      </info-data>
      <personal-data
        :userValues="personalInfo"
        :appointmentValues="userInfo"
        @updateData="updateUserPersonal"
        @nextStep="patchUser"
        :disabled="disabled"
        ref="personalData"
        @updateAppointmentData="updateUserPersonalAppointment"
      >
      </personal-data>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import html2pdf from 'html2pdf.js';

import PersonalData from '@/components/Register/Appointment/UpdatePersonalDataRegister.vue';
import InfoData from '@/components/Register/Appointment/UpdateInfoRegister.vue';
import ConfigurationData from '@/components/Register/Appointment/UpdateConfigurationRegister.vue';
import AddChatComment from '@/components/Forms/AddChatComment.vue';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';
import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';
import PricesComponent from '@/components/Cards/Prices.vue';

import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const axios = require('axios');

export default {
  name: 'Panel-Inscrito',
  props: {
    cardUserImage: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/logo.png'),
    },
  },
  components: {
    PersonalData,
    InfoData,
    ConfigurationData,
    AddChatComment,
    SectionHeader,
    PrimaryButton,
    PreviewCanvas,
    // SidesGradient,
    SelectionButton,
    SelectionCard,
    PricesComponent,
  },
  data() {
    return {
      adminAvailable: false,
      superAdminAvailable: false,
      paidStatusArray: [
        {
          value: 'EMPTY',
          label: 'Sin Datos',
          color: '#f2f2f2',
          dark: false,
        },
        {
          value: 'CASH',
          label: 'Efectivo',
          color: '#060606',
          dark: true,
        },
        {
          value: 'BANK',
          label: 'Banco',
          color: '#060606',
          dark: true,
        },
        {
          value: 'PAID',
          label: 'Pagado',
          color: '#00E676',
          dark: false,
        },

        {
          value: 'PROBLEM',
          label: 'Problema',
          color: '#FF5252',
          dark: true,
        },
      ],
      totalRecords: null,
      totalComments: null,
      totalAttendances: null,
      userId: null,
      user: [],
      userInfo: {
        namePartners: null,
        lastName1: null,
        namePlace: null,
        streetPlace: null,
        phone2: null,
        guestsNumber: null,
        time: null,
        eventDate: null,
      },
      personalInfo: {
        name: null,
        email: null,
        phone: null,
        idDocument: null,
        streetName: null,
      },
      appointmentInfo: {
        photocall: null,
        neon: null,
        neonCustom: null,
        album: null,
        plantilla: {
          size: null,
          name: null,
        },
        extras: null,
        extrasWithDiscount: null,
        // Agrega aquí cualquier otro campo que necesites
      },
      paidStatus: 'EMPTY',
      records: [],
      comments: [],
      attendances: [],
      userActivityId: null,

      // new data
      tab: 0,
      tab2: 0,
      loading: false,
      disabled: true,
      imageFileAlbum: null,
      imageFilePlantilla: null,

      previewImg: null,
      swiperLoaded: false,
      postalCodeItem: null,
      originCoordinates: {
        latitude: -3.8752786669764077,
        longitude: 40.489787823760636,
      },

      couponCode: null,
      newCoupon: null,
      isPaid: true,
      pdfDialog: false,
      pdfAzafatos: null,
      pdfBurgoCentro: null,
      pdfNumMaquina: null,
      pdfHoraComienzo: null,
      pdfPagado: null,
      neonCustomTemp: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },

    checkImageAlbumUploaded() {
      if (!this.user.appointment) {
        return null;
      }
      if (this.user.appointment.album && this.user.appointment.album.name === 'Personalizado') {
        if (this.user.appointment.customImageAlbumName) {
          return 'loaded';
        }
        return 'not loaded';
      }
      return null;
    },
    checkImagePlantillaUploaded() {
      if (!this.user.appointment) {
        return null;
      }
      if (
        this.user.appointment.plantilla
        && this.user.appointment.plantilla.name === 'Personalizado'
      ) {
        if (this.user.appointment.customImagePlantillaName) {
          return 'loaded';
        }
        return 'not loaded';
      }
      return null;
    },
    checkCustomNeon() {
      if (!this.user.appointment) {
        return null;
      }
      return !!(this.user.appointment.neon && this.user.appointment.neon.name === 'Personalizado');
    },
    basePrice() {
      const basePrice = {
        description: null,
        price: null,
      };
      if (this.user.appointment) {
        if (this.isWeekend(this.user.appointment.eventDate)) {
          basePrice.description = 'Temporada Alta';
          basePrice.price = 485;
        } else {
          basePrice.description = 'Temporada Baja';
          basePrice.price = 465;
        }
      }
      return basePrice;
    },

    extraPrice() {
      const base = 0.27;
      const extraPrice = {};
      if (this.distance) {
        if (this.distance < 50) {
          extraPrice.description = 'Distancia menor a 50km';
          extraPrice.price = 0;
        } else {
          extraPrice.price = (this.distance * base).toFixed(2);
          extraPrice.description = `${this.distance}km * 0.27€/km`;
        }
      }
      return extraPrice;
    },

    totalPrice() {
      let total = 0;
      if (this.basePrice.price) {
        total += parseFloat(this.basePrice.price);
      }
      if (this.extraPrice.price) {
        total += parseFloat(this.extraPrice.price);
      }
      if (this.user.appointment.photocall) {
        total += parseFloat(this.user.appointment.photocall.price);
      }
      if (this.user.appointment.neon) {
        total += parseFloat(this.user.appointment.neon.price);
      }
      if (this.user.appointment.album) {
        total += parseFloat(this.user.appointment.album.price);
      }
      if (this.user.appointment.plantilla) {
        total += parseFloat(this.user.appointment.plantilla.price);
      }
      if (this.user.appointment.extras && this.user.appointment.extras.length > 0) {
        this.user.appointment.extras.forEach((extra) => {
          total += parseFloat(extra.price);
        });
      }
      if (
        this.user.appointment.extrasWithDiscount
        && this.user.appointment.extrasWithDiscount.length > 0
      ) {
        this.user.appointment.extrasWithDiscount.forEach((extra) => {
          total += parseFloat(extra.price) - extra.discount;
        });
      }
      if (this.user.appointment.discountCoupon) {
        total -= parseFloat(this.user.appointment.discountCoupon.discount);
      }
      if (this.isPaid) {
        // total = total - 100
        total -= 100;
      }
      return total.toFixed(2);
    },

    prices() {
      const prices = {};
      if (this.user.appointment) {
        if (this.basePrice.price) {
          prices.basePrice = this.basePrice;
        }
        if (this.extraPrice.price) {
          prices.extraPrice = this.extraPrice;
        }
        if (this.user.appointment.photocall) {
          prices.photocall = this.user.appointment.photocall;
        }
        if (this.user.appointment.neon) {
          prices.neon = this.user.appointment.neon;
        }
        if (this.user.appointment.neonPersonalizado) {
          // Añadimos el texto personalizado al nombre del neón
          prices.neonPersonalizado = this.user.appointment.neonPersonalizado;
        }
        if (this.user.appointment.album) {
          prices.album = this.user.appointment.album;
        }
        if (this.user.appointment.plantilla) {
          prices.plantilla = this.user.appointment.plantilla;
        }
        if (this.user.appointment.extras && this.user.appointment.extras.length > 0) {
          prices.extras = this.user.appointment.extras;
        }
        if (
          this.user.appointment.extrasWithDiscount
          && this.user.appointment.extrasWithDiscount.length > 0
        ) {
          prices.extrasWithDiscount = this.user.appointment.extrasWithDiscount;
        }
        if (this.user.appointment.discountCoupon) {
          prices.coupon = this.user.appointment.discountCoupon;
        }
        if (this.totalPrice) {
          prices.totalPrice = this.totalPrice;
        }
        this.updatePriceForStats(this.isPaid ? parseFloat(this.totalPrice) + 100 : this.totalPrice);
      }

      return prices;
    },
    dateOfInscription() {
      if (this.user && this.user.creationTime) {
        return new Date(this.user.creationTime).toLocaleDateString();
      }
      return 'No disponible';
    },
    dateOfLastUpdate() {
      if (this.user && this.user.lastUpdateTime) {
        return new Date(this.user.lastUpdateTime).toLocaleDateString();
      }
      return 'No disponible';
    },

    userChar() {
      if (this.user.name) {
        return (
          this.user.name.substring(1, 0).toUpperCase()
          + this.user.lastName1.substring(1, 0).toUpperCase()
        );
      }
      return 'X';
    },
    distance() {
      if (this.postalCodeItem) {
        if (this.postalCodeItem.distance) {
          return this.postalCodeItem.distance;
        }
        const distance = this.getDistanceFromLatLonInKm(
          this.postalCodeItem.latitude,
          this.postalCodeItem.longitude,
          this.originCoordinates.latitude,
          this.originCoordinates.longitude,
        );
        return distance.toFixed(0);
      }
      return null;
    },
    distanceText() {
      if (this.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }
      return null;
    },
  },
  watch: {
    // watch immagePreview
    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiperFinal();
      }
    },
  },
  mounted() {
    this.loading = true;
    this.adminAvailable = this.$store.getters['auth/hasRole']([
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_ENLACE',
      'ROLE_RESPONSABLE',
    ]);
    this.superAdminAvailable = this.$store.getters['auth/hasRole']([
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_ENLACE',
    ]);
    if (!this.adminAvailable) {
      this.$router.push('/acceso-denegado');
    } else {
      this.getPopulation();
    }
  },
  methods: {
    updateNeonText() {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/neon-custom-text/${this.user.appointment.id}`,
          { neonPersonalizado: this.neonCustomTemp },
          { headers: authHeader() },
        )
        .then(() => {
          // Manejar la respuesta si es necesario
          // console.log(response);
          this.getPopulation();
        })
        .catch((error) => {
          console.error('Error al actualizar el texto del neón:', error);
        });
    },
    dateOfAppointment(date) {
      if (this.user && this.user.appointment) {
        return new Date(date).toLocaleDateString();
      }
      return 'No disponible';
    },
    updatePaidStatus(appointmentId) {
      // Asegúrate de que el valor de paidStatus se obtiene correctamente del estado actual del componente
      const { paidStatus } = this.user.appointment;

      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/setPaidStatus/${appointmentId}`,
          paidStatus, // Envía el estado de pago actual como una cadena de texto plano
          {
            headers: {
              ...authHeader(),
              'Content-Type': 'text/plain', // Asegúrate de incluir el tipo de contenido correcto
            },
          },
        )
        .then(() => {
          // Manejar la respuesta si es necesario
          // console.log(response);
          // Aquí puedes actualizar el estado del componente según sea necesario
        })
        .catch((error) => {
          console.error('Error al actualizar el estado de pago:', error);
        });
    },

    editConfiguration() {
      const appointmentId = this.$route.params.id;
      this.$router.push(`/reservas/editar/${appointmentId}`);
    },
    setValue(value) {
      this.previewImg = value;
    },
    isWeekend(date) {
      const calcDate = new Date(date);
      const day = calcDate.getDay();
      const month = calcDate.getMonth();
      return !!(day === 6 && month >= 4 && month <= 9);
    },
    initSwiperFinal() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiperFinal, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        pagination: {
          el: '.swiper-pagination',
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Inicio antes de las 19:15)';
        case 'evening':
          return 'Boda de noche (Inicio después de 23:29)';
        default:
          return 'No disponible';
      }
    },
    exportToPDF() {
      let extras = 'EXTRAS:';
      // for each extra add to extras
      this.user.appointment.extras.forEach((element) => {
        // eslint-disable-next-line no-const-assign
        extras += `<p class="m-4">${element.name}</p>`;
      });
      this.user.appointment.extrasWithDiscount.forEach((element) => {
        // eslint-disable-next-line no-const-assign
        extras += `<p class="m-4">${element.name}</p>`;
      });

      let comentarios = '';
      this.comments.forEach((element) => {
        // eslint-disable-next-line no-const-assign
        comentarios += `<p class="m-4">${element.description}</p>`;
      });
      // get src of images from assets/logo.png
      // eslint-disable-next-line global-require
      const logo = require('@/assets/logo.png');
      const data = '<div class="mt-5 m-2">'
        // add image
        + `<div class="text-right mr-4 m-2"><img src="${logo}" alt="Logo" style="height:45px;"></div>`
        + `<div class="ml-4"><h6 class="font-weight-bold">TELÉFONO DE LOS NOVIOS : ${this.user.phone}</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">NOMBRE DE LOS NOVIOS : ${this.user.appointment.namePartners}</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">FECHA DE LA BODA : ${this.dateOfAppointment(this.user.appointment.eventDate)}</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">LUGAR DEL EVENTO : ${
          this.user.appointment.namePlace || this.user.appointment.name
        }</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">DIRECCIÓN DE LA FINCA : ${this.user.appointment.streetPlace}</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">TELÉFONO DE CONTACTO : ${this.user.appointment.phone}</h6></div>`
        + `<div class="m-4"><h6 class="font-weight-bold">HORA DE INICIO DE FOTOMATÓN : ${this.user.appointment.time}</h6></div>`
        + '<div class="m-4"><h6 class="font-weight-bold">CONFIGURACIÓN DE FOTOMATÓN :</h6></div>'
        + `<div class="m-4 ml-5"><h6 class="">PHOTOCALL : ${
          this.user.appointment.photocall.name
        }</h6></div>${
          this.user.appointment.neon
            ? `<div class="m-4 ml-5"><h6 class="">NEÓN : ${this.user.appointment.neon.name}${
              this.user.appointment.neon.name === 'Personalizado'
                ? ` (Texto: ${this.user.appointment.neonPersonalizado})`
                : ''
            }</h6></div>`
            : ''
        }<div class="m-4 ml-5"><h6 class="">ALBUM : ${
          this.user.appointment.album.name
        }</h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">PLANTILLAS : ${this.user.appointment.plantilla.name} (${this.user.appointment.plantilla.size})</h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">AZAFATOS : ${this.pdfAzafatos} </h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">ALMACÉN : ${this.pdfBurgoCentro} </h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">NÚMERO MÁQUINA : ${this.pdfNumMaquina} </h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">PAGADO : ${this.pdfPagado} </h6></div>`
        + `<div class="m-4 ml-5"><h6 class="">${extras}</h6></div>`
        + '<div class="m-4"><h6 class="font-weight-bold">COMENTARIOS :</h6></div>'
        + `<div class="m-4 ml-5"><h6 class="">${comentarios}</h6></div>`
        + '</div>';

      html2pdf(data, {
        margin: 1,
        filename: `${this.user.appointment.namePartners}.pdf`,
      });
    },
    downloadAlbumImage() {
      const url = `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/get-custom-image-album`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: authHeader(),
      }).then((response) => {
        const newurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = newurl;
        link.setAttribute(
          'download',
          `${this.user.appointment.namePartners}_${this.user.appointment.customImageAlbumName}`,
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    downloadPlantillaImage() {
      const url = `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/get-custom-image-plantilla`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: authHeader(),
      }).then((response) => {
        const newurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = newurl;
        link.setAttribute(
          'download',
          `${this.user.appointment.namePartners}_${this.user.appointment.customImagePlantillaName}`,
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    uploadImageAlbum() {
      const formData = new FormData();
      formData.append('file', this.imageFileAlbum);
      const header = authHeader();
      header['Content-Type'] = 'multipart/form-data';

      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/upload-custom-image-album`,
          formData,
          { headers: header },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadImagePlantilla() {
      const formData = new FormData();
      formData.append('file', this.imageFilePlantilla);
      const header = authHeader();
      header['Content-Type'] = 'multipart/form-data';

      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/upload-custom-image-plantilla`,
          formData,
          { headers: header },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    patchUser(val) {
      if (val) {
        this.updateAffiliate();
      } else {
        this.getPopulation();
      }
    },
    updateUser(val) {
      if (this.user && this.user.appointment) {
        this.user.lastName1 = val.lastName1;
        this.user.appointment.namePartners = val.namePartners;
        this.user.appointment.namePlace = val.namePlace;
        this.user.appointment.name = val.namePlace;
        this.user.appointment.streetPlace = val.streetPlace;
        this.user.appointment.phone = val.phone2;
        this.user.appointment.guestsNumber = val.guestsNumber;
        this.user.appointment.time = val.time;
        this.user.appointment.eventDate = val.eventDate;
      }
    },
    updateUserPersonal(val) {
      if (this.user) {
        this.user.name = val.name;
        this.user.email = val.email;
        this.user.phone = val.phone;
        this.user.idDocument = val.idDocument;
        this.user.streetName = val.streetName;
        this.user.id = val.id;
      }
    },
    updateUserPersonalAppointment(val) {
      if (this.user && this.user.appointment) {
        this.user.appointment.direction = val.direction;
      }
    },
    updateAppointment(val) {
      if (this.user && this.user.appointment) {
        this.user.appointment.photocall = val.photocall;
        this.user.appointment.neon = val.neon;
        this.user.appointment.neonPersonalizado = val.neonCustom;
        this.user.appointment.album = val.album;
        this.user.appointment.plantilla = val.plantilla;
        this.user.appointment.extras = val.extras;
        // Actualiza aquí cualquier otro campo que necesites
      }
    },
    updatePriceForStats(price) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/change-price-for-stats/${this.user.appointment.id}`,
          {
            priceForStats: parseFloat(price),
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {});
    },
    getCoupon(code) {
      this.loading = true;
      console.log(code, 'code');
      axios
        .get(`${process.env.VUE_APP_API_URL}discount-coupon/${code}`, { headers: authHeader() })
        .then((response) => {
          this.coupon = response.data;
          this.addCoupon(response.data);
          // console.log(response.data, 'cupon');
        })
        .catch((error) => {
          // check error 404
          if (error.response.status === 404) {
            this.couponCode = null;
            // console.log('Cupón no encontrado');
            // window.alert('Cupón no encontrado');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addCoupon(coupon) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/add-coupon`,
          // transform newExtras to array of ids
          coupon,
          { headers: authHeader() },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAlbumInStock(id) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/setAlbumInStock/${id}`,
          {},
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAlbumInStock(id) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/setAlbumInStockFalse/${id}`,
          {},
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNeonInStock(id) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/setNeonInStock/${id}`,
          {},
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeNeonInStock(id) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/setNeonInStockFalse/${id}`,
          {},
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.getPopulation();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPopulation() {
      axios
        .get(`${process.env.VUE_APP_API_URL}affiliate/${this.$route.params.id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (!response.data) {
            this.$router.push('/error-no-encontrado');
          }
          this.user = response.data;
          this.userInfo = {
            namePartners: this.user.appointment.namePartners,
            lastName1: this.user.lastName1,
            namePlace: this.user.appointment.name,
            streetPlace: this.user.appointment.streetPlace,
            phone2: this.user.appointment.phone,
            guestsNumber: this.user.appointment.guestsNumber,
            time: this.user.appointment.time,
            appointmentId: this.user.appointment.id,
            direction: this.user.appointment.direction,
            eventDate: this.user.appointment.eventDate,
          };
          this.personalInfo = {
            name: this.user.name,
            lastName1: this.user.lastName1,
            email: this.user.email,
            phone: this.user.phone,
            idDocument: this.user.idDocument,
            streetName: this.user.streetName,
            appointmentId: this.user.appointment.id,
            id: this.user.id,
          };
          this.appointmentInfo = {
            photocall: this.user.appointment.photocall,
            neon: this.user.appointment.neon,
            neonCustom: this.user.appointment.neonPersonalizado,
            album: this.user.appointment.album,
            plantilla: this.user.appointment.plantilla
              ? { ...this.user.appointment.plantilla }
              : {
                size: null,
                name: null,
              },
            extras: this.user.appointment.extras,
            extrasWithDiscount: this.user.appointment.extrasWithDiscount,
            appointmentId: this.user.appointment.id,

            // Inicializa aquí cualquier otro campo que necesites
          };
          if (this.user.manualRegister) {
            this.isPaid = false;
          }

          this.getComments(this.user.email);
          this.getPostalCode(this.user.appointment.postalCode);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAffiliate() {
      const sendObject = {
        name: this.user.name,
        lastName1: this.user.lastName1,
        lastName2: this.user.lastName2,
        sex: this.user.sex,
        idDocument: this.user.idDocument,
        // Añade aquí cualquier otro campo que necesites
      };
      axios
        .put(`${process.env.VUE_APP_API_URL}affiliate/${this.$route.params.id}`, sendObject, {
          headers: authHeader(),
        })
        .then((response) => {
          // Actualiza la información del usuario después de la actualización
          this.user = response.data;
          this.initSwiperFinal();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getComments(email) {
      axios
        .get(`${process.env.VUE_APP_API_URL}chatComment/${email}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.comments = response.data.content.map((ele) => ({
            id: ele.id,
            admin: ele.admin,
            to: ele.emailTo,
            from: ele.emailFrom,
            // format date with time
            creationTime: new Date(ele.creationTime).toLocaleString(),
            description: ele.description,
            deleteHide: true,
          }));
          this.totalComments = this.comments.length;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPostalCode(id) {
      const idInt = parseInt(id, 10);
      axios
        .get(`${process.env.VUE_APP_API_URL}postal-code-distance/${idInt}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.postalCodeItem = response.data;
          this.postalCodeItem = {
            postalCodeId: id,
            distance: response.data,
          };
        })
        .catch((error) => {
          console.log(error);
          this.getPostalCodeSource(id);
        });
    },
    getPostalCodeSource(id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}postal-code/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.postalCodeItem = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1))
          * Math.cos(this.deg2rad(lat2))
          * Math.sin(dLon / 2)
          * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    editAffiliateInfo() {
      this.disabled = false;
      this.$refs.configurationData.openModal();
    },
    editEventInfo() {
      this.disabled = false;
      this.$refs.infoData.openModal();
    },
    editPersonalInfo() {
      this.disabled = false;
      this.$refs.personalData.openModal();
    },
  },
};
</script>
<style scoped>
.page-layout {
  height: calc(100% - var(--base) * 5);
  /* height: 100%; */
  padding-left: 0;
}

.preview-canvas {
  max-height: 40%;
  /* margin-bottom: calc(var(--base)); */
}

::v-deep .v-treeview-node__root {
  cursor: pointer;
}

.left-message {
  text-align: left;
  background-color: var(--colorPurple);
  border-radius: 10px;
  border-left: 3px solid #9e9e9e;
  /* border left without radius */
  border-top-left-radius: 0;
  padding: 10px;
  margin: 10px;
  width: 60%;
  margin-right: calc(var(--base) * 6);
  margin-left: 0;
}

.right-message {
  text-align: right;
  background-color: var(--colorGreen);
  border-radius: 10px;
  border-right: 3px solid #4caf50;
  /* border right without radius */
  border-top-right-radius: 0;
  padding: 10px;
  margin: 10px;
  width: 60%;
  margin-right: 0;
  margin-left: calc(var(--base) * 6);
}

.user-message {
  text-align: left;
  margin-left: 0;
}
</style>
<style lang="scss" scoped>
.aside-container {
  gap: calc(var(--base) * 2);
}

.main-container {
  overflow-y: hidden;

  &::after {
    position: absolute;
    content: "Scroll para ver más";
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 8) !important;
  }
}

.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 1);
  margin-top: calc(var(--base) * 2);
}
.select-box {
  width: 100%;
  max-width: 200px;
}
</style>
