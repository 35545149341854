<template>

    <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Tercer paso" second="Fotos" />
        <step-nav :activeStep="3"/>
        <preview-canvas
          :previewImg="previewImg"
          v-if="isMobile"
        >
      </preview-canvas>
        <div class="field-group">
          <label class="field-group--label" for="">Elige un formato:</label>
          <v-select
            v-model="data.size"
            :items="optionsSize"
            dense
            color="black"
            background-color="white"
            outlined
            placeholder="Ningún formato seleccionado"
            hide-details
            @change="updateData"
          ></v-select>
        </div>
        <div class="option-selector--container field-group">
          <label v-show="data.size" class="option-selector--label field-group--label" for="">Elige un diseño:</label>

          <div class="field-cards-grid">
            <selection-card
                v-for="item in optionsPhotosFiltered"
                :key="item.id"
                :name="item.name"
                size="small"
                :price="item.price"
                :img="item.img"
                v-on:click.native="setValue(item)"
                :selected="checkValue(item.id)"
              />
          </div>
        </div>

        <prices-component
      v-if="isMobile && this.prices"
        :prices="this.prices"
        :isMobile="true"
        :isPaid="isPaid"
      ></prices-component>
        <div class="navigation-buttons">
          <primary-button
          appearance="text"
          text="Atrás"
          size="small"
          v-on:click.native="backStep"
        />
        <primary-button
          appearance="solid"
          text="Siguiente"
          size="small"
          v-on:click.native="nextStep"
          :enabled="!this.$v.$invalid"
        />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <preview-canvas
      :previewImg="previewImg"
      ></preview-canvas>
      <prices-component
        v-if="this.prices"
        :prices="this.prices"
        :isPaid="isPaid"
      ></prices-component>
    </section>
  </div>
  <!--
    //////////////////////////////
    <v-select
      v-model="data.size"
      :items="optionsSize"
      label="Select size"
      :color="$getColor('vuetifyColor1')"
      outlined
      @change="updateData"
    ></v-select>
      <div v-if="!$v.data.size.required">Required field *</div>
    <v-select
      v-if="data.size"
      v-model="data.photos"
      :items="optionsPhotosFiltered"
      label="Select type"
      :color="$getColor('vuetifyColor1')"
      outlined
      @change="updateData"
    ></v-select>
      <div v-if="!$v.data.photos.required && data.size">Required field *</div>
      <v-row>
      <v-img
      v-for="item in optionsPhotosFiltered"
      :key="item.id"
      :src="item.img"
      :alt="item.text"
      max-width="200"
      max-height="200"
      :class="checkValue(item.id)"
      @click="setValue(item)"
    >
    <span>{{item.text}}</span>
  </v-img>
    </v-row>
    -->

</template>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import authHeader from '@/services/auth-header';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import StepNav from '@/components/Nav/StepNav.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';

import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import PricesComponent from '@/components/Cards/Prices.vue';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'photos-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },

  },
  components: {
    SectionHeader,
    PrimaryButton,
    StepNav,
    SelectionCard,
    PreviewCanvas,
    PricesComponent,
  },
  data: () => ({
    modal: false,
    data: {
      photos: null,
      size: null,
    },
    optionsPhotos: null,
    optionsPhotosFiltered: null,
    optionsSize: [
      { text: 'Fotos tiras 5x15', value: '5x15' },
      { text: 'Fotos 10x15', value: '10x15' },
    ],
    prices: null,
    swiperLoaded: false,
  }),
  validations() {
    const valObj = {
      data: {
        photos: { required },
        size: { required },
      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
    this.getItems('Plantilla');
    this.updateData();
  },
  watch: {
    data: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    // watch data.size and update optionsPhotosFiltered
    'data.size': {
      handler() {
        this.updateOptions();
        // this.initSwiper1();
      },
      deep: true,
    },

    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiper1();
      }
    },
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    previewImg() {
      return this.data.photos
        ? this.data.photos.img
        : null;
    },
  },
  methods: {
    updatePrices(val) {
      this.prices = val;
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    setValue(item) {
      if (this.data.photos === item) {
        this.data.photos = null;
      } else {
        this.data.photos = item;
      }
    },
    checkValue(id) {
      return !!(this.data.photos && this.data.photos.id === id);
    },
    updateOptions() {
      if (this.data.size) {
        this.optionsPhotosFiltered = this.optionsPhotos
          .filter((item) => item.size === this.data.size);
      } else {
        this.optionsPhotosFiltered = this.optionsPhotos;
      }
      this.initSwiper1();
    },
    updateData() {
      this.$emit('updateData', this.data);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },

    getItems(typeV) {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: typeV,
            size: 50,
          },
        }).then((response) => this.initTemplates(response));
    },
    initTemplates(response) {
      this.setOptions(response.data.content);
      this.data.size = '5x15';
    },
    setOptions(response) {
      // map response to options
      this.optionsPhotos = response.map((item) => ({
        name: item.name,
        value: item.id,
        size: item.size,
        img: item.imageUrl,
        id: item.id,
        price: item.price,
      }));
    },
    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper1, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

      });
    },
  },
};
</script>
<style scoped>
/*add selected with vuetifyColor*/
.selected {
  border: 2px solid var(--color1);
}
</style>
<style lang="scss" scoped>

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 8);
}
.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorPink);
  }
}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorPinkRGB),0) 0%, rgba(var(--colorPinkRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
