<template>
    <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Segundo paso" second="Álbum" />
        <step-nav :activeStep="2"/>
        <preview-canvas
          :previewImg="previewImg"
          v-if="isMobile"
        >
      </preview-canvas>
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for="">Elige un diseño:</label>

          <div class="field-cards-grid">
            <selection-card
            v-for="item in optionsAlbum"
                :key="item.id"
                :name="item.name"

                size="small"
                :price="item.price"
                :img="item.img"
                v-on:click.native="setValue(item)"
                :selected="checkValue(item.id)"
              />
          </div>
        </div>
        <div class="option-selector--container field-group"  v-if="!data.album || data.album.name != 'Personalizado'">
          <label class="option-selector--label field-group--label" for="">O personalízalo a tu gusto:</label>
          <selection-button

          title="Album personalizado"
          subtitle="Añade la imagen de tu album personalizado"
          enabled
          v-on:click.native="selectAlbumPersonalizado()"
          />
        </div>
        <prices-component
        v-if="isMobile && this.prices"
        :prices="this.prices"
        :isMobile="true"
        :isPaid="isPaid"
      ></prices-component>
        <div class="navigation-buttons">
          <primary-button
          appearance="text"
          text="Atrás"
          size="small"
          v-on:click.native="backStep"
        />
        <primary-button
          appearance="solid"
          text="Siguiente"
          size="small"
          v-on:click.native="nextStep"
          :enabled="!this.$v.$invalid"
        />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <preview-canvas
      :previewImg="previewImg"
      ></preview-canvas>
      <prices-component
        v-if="this.prices"
        :prices="this.prices"
        :isPaid="isPaid"
      ></prices-component>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import authHeader from '@/services/auth-header';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import StepNav from '@/components/Nav/StepNav.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';

import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import PricesComponent from '@/components/Cards/Prices.vue';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'album-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionHeader,
    PrimaryButton,
    StepNav,
    SelectionCard,
    SelectionButton,
    PreviewCanvas,
    PricesComponent,
  },
  data: () => ({
    modal: false,
    data: {
      album: null,
    },
    optionsAlbum: null,
    prices: null,

    swiperLoaded: false,
  }),
  validations() {
    const valObj = {
      data: {
        album: { required },
      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
    this.getItems('Album');
    this.updateData();
  },
  watch: {
    data: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    // watch immagePreview
    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiper1();
      }
    },
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    previewImg() {
      return this.data.album
        ? this.data.album.img
        : null;
    },
  },

  methods: {
    updatePrices(val) {
      this.prices = val;
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    setValue(item) {
      if (this.data.album === item) {
        this.data.album = null;
      } else {
        this.data.album = item;
      }
    },
    checkValue(id) {
      return !!(this.data.album && this.data.album.id === id);
    },
    updateData() {
      this.$emit('updateData', this.data);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    getItems(typeV) {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: typeV,
            size: 50,
          },
        }).then((response) => this.setOptions(response.data.content));
    },
    setOptions(response) {
      // map response to options
      this.optionsAlbum = response.map((item) => ({
        name: item.name,
        value: item.id,
        img: item.imageUrl,
        id: item.id,
        price: item.price,
      }));
    },
    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper1, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    selectAlbumPersonalizado() {
      // get in optionsAlbum the album with name = personalizado
      const albumPersonalizado = this.optionsAlbum.find((album) => album.name === 'Personalizado');
      this.data.album = albumPersonalizado;
      this.updateData();
    },
  },

};
</script>

<style lang="scss" scoped>
// *{
//   border: solid 1px red;
// }

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: auto;
}
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom:auto;
}
.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.page-layout{
  @media (min-width: 1024px) {
    background-color: var(--colorPurple);
  }
}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorPurpleRGB),0) 0%, rgba(var(--colorPurpleRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
