<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="El resumen de" second="Tu pedido" />
        <div class="field-group">
          <v-icon class="field-group--icon pb-2 pr-2" color="black" size="25">mdi-account</v-icon>
          <label for="" class="field-group--label"> Datos Personales: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre:</span>
              <span class="data--value">{{ checkRegisterData.userName }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">DNI/CIF:</span>
              <span class="data--value">{{ checkRegisterData.idDocument }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Dirección<noscript></noscript>:</span>
              <span class="data--value">{{ this.checkRegisterData.direction }}</span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="field-group">
          <v-icon class="field-group--icon pb-2 pr-2" color="black" size="25">mdi-phone</v-icon>
          <label for="" class="field-group--label"> Datos de contacto: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Teléfono:</span>
              <span class="data--value">{{ checkRegisterData.phone }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Correo electrónico:</span>
              <span class="data--value">{{ checkRegisterData.email }}</span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="field-group">
          <v-icon class="field-group--icon pb-2 pr-2" color="black" size="25">mdi-calendar</v-icon>
          <label for="" class="field-group--label">
            Datos del evento:
          </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Fecha:</span>
              <!-- this.checkAvailabilityData.eventTime but in format dd/MM/YYYY
              <span class="data--value">{{ this.checkAvailabilityData.eventTime }}</span>
            -->
              <span class="data--value" v-if="this.checkAvailabilityData.eventTime">
                {{ this.checkAvailabilityData.eventTime.split('-')[2] }}/{{ this.checkAvailabilityData.eventTime.split('-')[1] }}/{{ this.checkAvailabilityData.eventTime.split('-')[0] }}
              </span>
            </div>
            <div class="data--item">
              <span class="data--label">Horario:</span>
              <span class="data--value">{{ this.getTypeLabel(this.checkAvailabilityData.type) }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Lugar del evento:</span>
              <span class="data--value">{{ this.checkRegisterData.name }}</span>
            </div>
            <!-- <div class="data--item" v-if="this.checkRegisterData.postalCodeItem">
              <span class="data--label">Código Postal:</span>
              <span class="data--value">
                {{ this.checkRegisterData.postalCodeItem.codigopostalid ? this.checkRegisterData.postalCodeItem.codigopostalid :  this.checkRegisterData.postalCodeItem.id }}
              </span>
            </div> -->
          </div>
        </div>
        <v-divider></v-divider>
        <div class="field-group m-0 p-0 pb-3 pt-1">
          <label for="" class="field-group--label m-0 p-0">
            Presupuesto:
          </label>
        </div>
        <prices-component
        :isPaid="false"
        :eventDate="checkAvailabilityData.eventTime"
        :postalCodeItem="checkRegisterData.postalCodeItem"
        ></prices-component>

        <div class="navigation-buttons">
          <primary-button
          appearance="text"
          text="Atrás"
          size="small"
          v-on:click.native="backStep"
        />
        <primary-button
          appearance="solid"
          text="Reservar fecha 100€"
          size="small"
          v-on:click.native="nextStep"
          :enabled="!buttonClicked"
        />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpeg" alt="" />
      </div>
    </section>

  </div>
</template>
<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeaderSmall.vue';
import PricesComponent from '@/components/Cards/PricesBasic.vue';

export default {
  name: 'summary-configuration-view',
  components: {
    SectionHeader,
    PrimaryButton,
    PricesComponent,
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
  },
  methods: {
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Hasta las 20:30)';
        case 'evening':
          return 'Evento de noche (Desde las 23:30)';
        default:
          return 'No disponible';
      }
    },
    nextStep() {
      if (!this.buttonClicked) {
        this.buttonClicked = true;
        this.$emit('nextStep', true);
        // Aquí puedes agregar cualquier lógica adicional para el manejo del clic
      }
    },
    backStep() {
      this.$emit('backStep', true);
    },

    updateData(checkAvailabilityData, checkRegisterData) {
      this.checkAvailabilityData = checkAvailabilityData;
      this.checkRegisterData = checkRegisterData;
    },
    updatePrices(val) {
      this.prices = val;
    },
  },
  data() {
    return {
      checkAvailabilityData: {
        type: null,
        eventTime: null,
        totalOnSelectedDate: null,
      },
      checkRegisterData: {
        name: null,
        postalCode: null,
        direction: null,
        postalCodeItem: null,
        distance: null,
        userName: null,
        email: null,
        idDocument: null,
        phone: null,
      },
      prices: null,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
  },
};

</script>
<style lang="scss" scoped>

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom:auto;
}
.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGrey);
  }
}
.aside-container{
  padding:0 !important;
}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreyRGB),0) 0%, rgba(var(--colorGreyRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
