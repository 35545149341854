<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Lo primero" second="Comprueba la disponibilidad" />
        <div class="date-picker-wrapper">
          <v-date-picker
            v-model="itemData.eventTime"
            locale="es"
            first-day-of-week="1"
            :min="minDate"
            :color="$getColor('vuetifyColor2')"
          >
          </v-date-picker>
        </div>
        <div class="check-buttons">
          <v-progress-circular
            v-if="loading"
            indeterminate
            :color="$getColor('vuetifyColor2')"
          ></v-progress-circular>
          <check-selector
            v-show="!loading"
            title="Boda de comida"
            subtitle="Inicio antes de las 19:15"
            ref="morningSelector"
            v-on:click.native="selectMorning"
          />
          <check-selector
            v-show="!loading"
            title="Boda de cena"
            subtitle="Inicio después de 23:29"
            ref="eveningSelector"
            v-on:click.native="selectEvening"
          />
        </div>
        <prices-component
          v-if="itemData.eventTime"
          :isPaid="false"
          :eventDate="itemData.eventTime"
          :postalCodeItem="null"
          class="pt-5 mb-0"
        ></prices-component>
        <div class="navigation-buttons">
          <primary-button
            appearance="text"
            text="Atrás"
            size="small"
            v-on:click.native="goToLogin"
            v-if="this.$route.path != '/reserva-manual'"
          />
          <primary-button
            appearance="solid"
            text="Siguiente"
            size="small"
            v-on:click.native="nextStep"
            :enabled="!this.$v.$invalid"
          />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import CheckSelector from '@/components/Buttons/CheckSelector.vue';
import PricesComponent from '@/components/Cards/PricesBasic.vue';

import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import authHeader from '@/services/auth-header';

export default {
  name: 'check-availability',
  mixins: [validationMixin],
  data: () => ({
    itemData: {
      type: null,
      eventTime: null,
      totalOnSelectedDate: 16,
    },
    minDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
    totalOnSelectedDateMorning: 0,
    totalOnSelectedDateEvening: 0,
    registerAvailableMorning: false,
    registerAvailableEvening: false,
    loadingMorning: false,
    loadingEvening: false,
    modal: false,
    registerAvailable: false,
  }),
  components: {
    SectionHeader,
    PrimaryButton,
    CheckSelector,
    PricesComponent,
  },
  watch: {
    // watch changes on itemData.eventTime
    'itemData.eventTime': {
      handler() {
        this.resetData();
        this.checkAvailability();
      },
      deep: true,
    },
    // watch changes on itemData.type
    'itemData.type': {
      handler() {
        this.updateData();
      },
      deep: true,
    },
  },
  validations() {
    const valObj = {
      itemData: {
        type: {
          required,
        },
        eventTime: { required },
        totalOnSelectedDate: {
          // check if totalOnSelectedDate is less than 10
          lessThan: (val) => val < 16,
        },
      },
    };
    return valObj;
  },
  computed: {
    // add compted loading with setter and getter
    loading: {
      get() {
        return this.loadingMorning || this.loadingEvening;
      },
      set() {},
    },

  },
  methods: {
    allowedDates: (val) => new Date(val) >= new Date(),
    resetData() {
      this.itemData.type = null;
      this.itemData.totalOnSelectedDate = 16;
      this.totalOnSelectedDateMorning = 0;
      this.totalOnSelectedDateEvening = 0;
      this.registerAvailableMorning = false;
      this.registerAvailableEvening = false;
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateEnabled(false);
      this.$refs.eveningSelector.updateEnabled(false);
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    updateData() {
      this.$emit('updateData', this.itemData);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    selectMorning() {
      this.itemData.type = 'morning';
      this.itemData.totalOnSelectedDate = this.totalOnSelectedDateMorning;
      this.$refs.morningSelector.updateSelected(true);
      this.$refs.eveningSelector.updateSelected(false);
      this.updateData();
    },
    selectEvening() {
      this.itemData.type = 'evening';
      this.itemData.totalOnSelectedDate = this.totalOnSelectedDateEvening;
      this.$refs.eveningSelector.updateSelected(true);
      this.$refs.morningSelector.updateSelected(false);
      this.updateData();
    },
    // check availability
    checkAvailability() {
      this.loading = true;
      if (this.itemData.eventTime) {
        this.checkAvailabilityMorning();
        this.checkAvailabilityEvening();
      }
      this.loading = false;
    },
    checkAvailabilityMorning() {
      this.loadingMorning = true;
      const data = {
        // send eventTime with EuropeTimezone
        date: new Date(this.itemData.eventTime).toLocaleString('en-GB', {
          timeZone: 'Europe/Madrid',
        }),
        type: 'morning',
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/checkAvailability`, {
          headers: authHeader(),
          params: data,
        })
        .then((response) => {
          // this.totalOnSelectedDate = response.data;
          this.totalOnSelectedDateMorning = response.data;
          this.registerAvailableMorning = true;
          this.$refs.morningSelector.updateEnabled(true);
          if (this.totalOnSelectedDateMorning >= 8) {
            this.$refs.morningSelector.updateEnabled(false);
          }
          // this.updateData();
        })
        .catch((error) => {
          // check error 409
          if (error.response.status === 409) {
            this.totalOnSelectedDateMorning = 8;
            this.registerAvailableMorning = false;
          }
        })
        .finally(() => {
          this.loadingMorning = false;
        });
    },
    checkAvailabilityEvening() {
      this.loadingEvening = true;
      const data = {
        // send eventTime with EuropeTimezone
        date: new Date(this.itemData.eventTime).toLocaleString('en-GB', {
          timeZone: 'Europe/Madrid',
        }),
        type: 'evening',
      };
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/checkAvailability`, {
          headers: authHeader(),
          params: data,
        })
        .then((response) => {
          // this.totalOnSelectedDate = response.data;
          this.totalOnSelectedDateEvening = response.data;
          this.registerAvailableEvening = true;
          this.$refs.eveningSelector.updateEnabled(true);
          if (this.totalOnSelectedDateEvening >= 8) {
            this.$refs.morningSelector.updateEnabled(false);
          }
          // this.updateData();
        })
        .catch((error) => {
          // check error 409
          if (error.response.status === 409) {
            this.totalOnSelectedDateEvening = 8;
            this.registerAvailableEvening = false;
          }
        })
        .finally(() => {
          this.loadingEvening = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;

  @media (min-width: 1024px) {
    margin-bottom: auto;
  }
}
.date-picker-wrapper {
  padding: 0 calc(var(--base) * 4);
  padding-bottom: calc(var(--base) * 2);
  display: flex;
  justify-content: center;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 4);
}

::v-deep .theme--light.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled .v-btn__loading,
.theme--light.v-btn.v-btn--disabled .v-icon {
  background-color: #f1f1f1;
}

.aside-container {
  padding: 0 !important;
}

.main-container {
  overflow-y: hidden;

  &::after {
    position: absolute;
    content: "Scroll para ver más";
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(
        180deg,
        rgba(var(--colorGreyRGB), 0) 0%,
        rgba(var(--colorGreyRgb), 1) 70%
      );
    }
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
