/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class AppointmentService {
  getAffiliateWithAppointmentId(id) {
    return axios
      .get(`${API_URL}affiliate/appointment/${id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  goToAppointmentPanel(id) {
    this.$router.push({ name: 'Appointments Panel', params: { id } });
  }
}

export default new AppointmentService();
