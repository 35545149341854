<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="El resumen de" second="Tu pedido" />
        <div class="content">
          <div class="field-group">
            <label for="" class="field-group--label">
              Resumen:
            </label>
            <div class="data--container">
              <div class="data--item">
                <span class="data--label">Fecha:</span>
                <span class="data--value"> {{ new Date(this.checkAvailabilityData.eventTime).toLocaleDateString()
                }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Horario:</span>
                <span class="data--value">{{ this.getTypeLabel(this.checkAvailabilityData.type) }}</span>
              </div>
              <div class="data--item">
                <span class="data--label">Lugar del evento:</span>
                <span class="data--value">{{ this.checkRegisterData.name }}</span>
              </div>
              <!--
                <div class="data--item">
                <span class="data--label">Dirección de la finca:</span>
                <span class="data--value">{{ this.checkRegisterData.direction }}</span>
              </div>
              -->

              <!-- <div class="data--item" v-if="this.checkRegisterData.postalCodeItem">
                <span class="data--label">Código Postal:</span>
                <span class="data--value">{{ this.checkRegisterData.postalCodeItem.codigopostalid ? this.checkRegisterData.postalCodeItem.codigopostalid :  this.checkRegisterData.postalCodeItem.id }}</span>
              </div> -->
            </div>
          </div>
          <v-divider></v-divider>
          <div class="field-group">
            <label for="" class="field-group--label">
              Configuración elegida:
            </label>
            <prices-component v-if="this.prices" :prices="prices" :isPaid="isPaid"></prices-component>
          </div>
          <v-divider></v-divider>

          <div class="field-group">
            <label for="" class="field-group--label">
              Código de descuento:
            </label>
            <div class="discount-code--container">
              <v-text-field v-if="coupon" :placeholder="coupon.code + ' (' + coupon.discount + '€ de descuento)'" outlined
                dense color="black" class="discount-code--input" :disabled="true"></v-text-field>
              <v-text-field v-if="!coupon" v-model="couponCode" placeholder="Cupón de descuento" outlined dense color="black"
                class="discount-code--input"></v-text-field>
              <span v-if="couponCodeAlert" class="error-message">Código de descuento incorrecto</span>
              <primary-button v-if="!coupon" appearance="outlined" text="Aplicar" size="small"
                class="discount-code--button" v-on:click.native="getCoupon(couponCode)" :enabled="true" />
            </div>
          </div>
        </div>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button appearance="solid" text="Siguiente" size="small" v-on:click.native="nextStep" />
        </div>

      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>
<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeaderSmall.vue';

import PricesComponent from '@/components/Cards/Prices.vue';

import axios from 'axios';
import { validationMixin } from 'vuelidate';
import authHeader from '@/services/auth-header';

export default {
  name: 'summary-configuration-view',
  mixins: [validationMixin],
  components: {
    SectionHeader,
    PrimaryButton,
    PricesComponent,
  },
  props: {
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prices: null,
      checkAvailabilityData: {
        type: null,
        eventTime: null,
        totalOnSelectedDate: null,
      },
      checkRegisterData: {
        name: null,
        postalCode: null,
        direction: null,
        postalCodeItem: null,
        distance: null,
      },
      photocallData: {
        photocall: null,
        neon: null,
      },
      albumData: {
        album: null,
      },
      photosData: {
        photos: null,
        size: null,
      },
      extrasData: {
        extras: null,
      },
      loading: false,
      couponCode: null,
      couponCodeAlert: false,
      coupon: null,
    };
  },
  mounted() {
  },
  watch: {
    coupon() {
      if (this.coupon) {
        this.updateCoupon();
      }
    },
  },
  methods: {
    updatePrices(val) {
      this.prices = val;
    },
    updateCoupon() {
      this.$emit('updateCoupon', this.coupon);
    },
    getCoupon(code) {
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_API_URL}discount-coupon/${code}`,
        { headers: authHeader() },
      )
        .then((response) => {
          this.coupon = response.data;
        })
        .catch((error) => {
          // check error 404
          if (error.response.status === 404) {
            this.couponCode = null;
            this.couponCodeAlert = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Inicio antes de las 19:15)';
        case 'evening':
          return 'Boda de noche (Inicio después de 23:29)';
        default:
          return 'No disponible';
      }
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updateData(checkAvailabilityData, checkRegisterData, photocallData, albumData, photosData, extrasData) {
      this.checkAvailabilityData = checkAvailabilityData;
      this.checkRegisterData = checkRegisterData;
      this.photocallData = photocallData;
      this.albumData = albumData;
      this.photosData = photosData;
      this.extrasData = extrasData;
    },
  },
};

</script>
<style lang="scss" scoped>
// *{
//   border: solid 1px red;
// }

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  // margin-bottom: calc(var(--base) * 3);
}

.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGrey);
  }
}

.page-layout {
  .content {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
  }

  .aside-container {

    padding: 0 !important;
  }

}
.main-container{
  overflow-y: hidden;
  &::after{
    position:absolute;
    content:'Scroll para ver más';
    bottom:0;
    left:0;
    width:100%;
    height:calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg),0) 0%, rgba(var(--bodyBg),1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreyRGB),0) 0%, rgba(var(--colorGreyRGB),1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
