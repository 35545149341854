<template>
  <div class="configuration-card" :class="color">
    <div class="content">
      <h3 class="first-line">{{ title }}</h3>
      <div class="second-line">{{ description }}</div>
    </div>
    <div class="number">
      {{ stepNumber }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    description: {
      type: String,
      default: 'Subtitle',
    },
    stepNumber: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: 'green',
    },
    onClick: {
      type: Function,
      default: () => { },
    },
  },

};
</script>

<style lang="scss" scoped>
.configuration-card {
  position: relative;
  width: 100%;
  border-radius: calc(var(--base) * 3);
  padding: calc(var(--base) * 2) calc(var(--base) * 3);
  background-color: rgb(var(--colorGreen));
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px rgba(var(--bodyColor), 0.1);
  box-shadow: none;
  transition: all 0.3s ease;
  overflow: hidden;

  .number {
    position: absolute;
    top: 50%;
    left: calc(var(--base) * -0.5);
    transform: translate(0, -50%);
    font-weight: 600;
    font-size: calc(var(--base) * 19.5);
    line-height: 1;
    color: rgba(var(--bodyBg), 0.35);
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    max-width: calc(var(--base) * 40);

    .first-line {
      font-size: calc(var(--base) * 2.25);
      line-height: calc(var(--base) * 3);
      font-weight: 500;
      margin-bottom: calc(var(--base) * 1);
      -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
    }

    .second-line {
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 2);
      font-weight: 300;
    }
  }

  &.customGreen {
    background-color: var(--colorGreen);
  }

  &.customPurple {
    background-color: var(--colorPurple);
  }

  &.customPink {
    background-color: var(--colorPink);
  }

  &.customRed {
    background-color: var(--colorRed);
  }
}</style>
