<template>
  <button :class="cardClass">
    <div class="selection-card--image">
      <img :src="img" alt="" />
    </div>
    <div class="selection-card--info">
      <div class="selection-card--title">{{ name }}</div>
      <div class="selection-card--price" v-if="!priceWithDiscount">+{{ price }}€</div>
      <div class="selection-card--strikethrough" v-if="priceWithDiscount">+{{ price }}€</div>
      <div class="selection-card--price" v-if="priceWithDiscount">+{{ priceWithDiscount }}€</div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Nombre',
    },
    price: {
      type: Number,
      default: 0,
    },
    img: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => { },
    },
    color: {
      type: String,
      default: 'customRed',
    },
    size: {
      type: String,
      default: 'small',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    priceWithDiscount: {
      type: Number,
      default: null,
    },
  },
  computed: {
    cardClass() {
      return this.selected ? `selection-card selected ${this.size}` : `selection-card ${this.size}`;
    },
  },

};
</script>

<style lang="scss" scoped>
.selection-card {
  width: 100%;
  // max-width: calc(var(--base) * 17);
  height: calc(var(--base) * 20);
  max-height: calc(var(--base) * 20);
  min-height: calc(var(--base) * 20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: calc(var(--base) * 1);
  border: solid 2px rgba(var(--bodyColor), 0.1);
  padding: calc(var(--base) * 1.3);
  transition: all ease-out 0.3s;
  background-color: rgb(var(--bodyBg));

  &:hover {
    opacity: 0.8;
  }

  .selection-card--image {
    border-radius: calc(var(--base) * 0.5);
    overflow: hidden;
    margin-bottom: calc(var(--base) * 1);
    width: 100%;
    height: 100%;
    background-color: rgba(var(--bodyBg), 1);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .selection-card--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: -0.03em;

    .selection-card--title {
      font-size: calc(var(--base) * 2);
      font-weight: 300;
      line-height: 1;
      margin-bottom: calc(var(--base) * 0.5);
      overflow: hidden;
      text-overflow: ellipsis;
     display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
       -webkit-box-orient: vertical;
    }

    .selection-card--price {
      font-size: calc(var(--base) * 1.75);
      font-weight: 300;
      line-height: calc(var(--base) * 2);
      opacity: 0.6;
    }

    .selection-card--strikethrough {
      text-decoration: line-through;
      color: red;
      font-weight: 600;
    }
  }

  //Sizes
  &.fullwidth {
    width: 100%;
    max-width: 100%;
    align-items: flex-start;

    .selection-card--info {
      align-items: flex-start;
    }
  }

  &.large {
    width: 80%;
    max-width: calc(var(--base) * 35);
    align-items: flex-start;

    .selection-card--info {
      align-items: flex-start;
    }
  }

  //Colors
  &.selected {
    background-color: rgba(var(--bodyColor), 1);
    border: solid 2px rgba(var(--bodyBg), 0.1);
    color: rgba(var(--bodyBg), 1);

    &.customGreen {
      background-color: var(--colorGreen);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }

    &.customPurple {
      background-color: var(--colorPurple);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }

    &.customPink {
      background-color: var(--colorPink);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }

    &.customRed {
      background-color: var(--colorRed);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }
  }
}
</style>
<style lang="scss">
.swiper-slide.selection-card {

  max-width: calc(var(--base) * 17);

}
</style>
