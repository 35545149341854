<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-alert type="error" color="red lighten-2" dark>
          Ha ocurrido un error. Por favor, intenta nuevamente.
        </v-alert>
        <v-btn color="primary" class="mt-5" @click="$router.push('/')">
          Volver al inicio
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorView',
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
