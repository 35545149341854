<template>
  <div class="page-layout">
    <section class="main-container ">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Primer paso" second="Photocall" />
        <step-nav :activeStep="1" />
        <preview-canvas :previewImg="previewImg" :overlayImg="overlayImg" v-if="isMobile">
        </preview-canvas>
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for="">Elige el fondo del Photocall:</label>

          <div class="field-cards-grid">
            <selection-card v-for="item in optionsPhotocall" :key="item.id" :name="item.name" size="small"
              :price="item.price" :img="item.img" v-on:click.native="setPhotocall(item)"
              :selected="checkPhotocall(item.id)" />
          </div>
        </div>
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for="">Elige un neón (alquiler):</label>

          <div class="field-cards-grid">
            <selection-card v-for="item in optionsNeon" :key="item.id" :name="item.name" size="small" :price="item.price"
              :img="item.img" v-on:click.native="setNeon(item)" :selected="checkNeon(item.id)" />
          </div>
        </div>
        <div class="selection-button--container field-group">
          <label class="selection-button--label field-group--label" for="">O personalízalo a tu gusto:</label>
          <div class="discount-code--container">

            <v-text-field v-if="data.neon && data.neon.name === 'Personalizado'" v-model="data.neonCustom"
              placeholder="Elige el texto" outlined dense color="black" class="discount-code--input"
              hint="Máximo 25 caracteres" :rules="[
                v => (v && v.length <= 25) || 'El texto no puede tener más de 25 caracteres.'
              ]" persistent-hint></v-text-field>
          </div>
          <selection-button v-if="!data.neon || data.neon.name != 'Personalizado'" title="Neón personalizado"
            subtitle="Añade el texto de tu neón personalizado" enabled v-on:click.native="selectNeonPersonalizado()" />
        </div>
        <prices-component v-if="isMobile && this.prices" :prices="this.prices" :isMobile="true"
          :isPaid="isPaid"></prices-component>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button appearance="solid" text="Siguiente" size="small" v-on:click.native="nextStep"
            :enabled="!this.$v.$invalid" />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <preview-canvas :previewImg="previewImg" :overlayImg="overlayImg"></preview-canvas>
      <prices-component v-if="this.prices" :prices="prices" :isPaid="isPaid"></prices-component>
    </section>
  </div>
</template>
<script>

import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import authHeader from '@/services/auth-header';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import StepNav from '@/components/Nav/StepNav.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';

import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import PricesComponent from '@/components/Cards/Prices.vue';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'photocall-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    data: {
      photocall: null,
      neon: null,
      neonCustom: null,
    },
    prices: null,
    optionsPhotocall: null,
    optionsNeon: null,
    swiperLoaded: false,
  }),
  validations() {
    const valObj = {
      data: {
        photocall: { required },
        // neonCustom.length must be less than 20
        neonCustom: {
          lessThanTwenty(value) {
            return !(value && value.length > 20);
          },

        },

      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }

    this.updateData();
    this.getItems('Photocall');
    this.getItems('Neon');
  },
  watch: {
    data: {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    userValues: {
      handler() {
        this.user = this.userValues;
      },
      deep: true,
    },
    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiper1();
        this.initSwiper2();
      }
    },
    overlayImg() {
      if (this.overlayImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiper1();
        this.initSwiper2();
      }
    },
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    previewImg() {
      return this.data.photocall
        ? this.data.photocall.img
        : null;
    },
    overlayImg() {
      return this.data.neon
        ? this.data.neon.img
        : null;
    },
  },

  methods: {
    updatePrices(val) {
      this.prices = val;
    },
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    setPhotocall(item) {
      if (this.data.photocall === item) {
        this.data.photocall = null;
      } else {
        this.data.photocall = item;
      }
    },
    checkPhotocall(id) {
      return !!(this.data.photocall && this.data.photocall.id === id);
    },
    setNeon(item) {
      if (this.data.neon === item) {
        this.data.neon = null;
      } else {
        this.data.neon = item;
      }
    },
    checkNeon(id) {
      return !!(this.data.neon && this.data.neon.id === id);
    },
    updateData() {
      this.$emit('updateData', this.data);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    getItems(typeV) {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: typeV,
          },
        }).then((response) => this.setOptions(response.data.content, typeV));
    },
    setOptions(response, typeV) {
      // map response to options
      if (typeV === 'Photocall') {
        this.optionsPhotocall = response.map((item) => ({
          name: item.name,
          value: item.id,
          img: item.imageUrl,
          id: item.id,
          price: item.price,
        }));
      } else if (typeV === 'Neon') {
        this.optionsNeon = response.map((item) => ({
          name: item.name,
          value: item.id,
          img: item.imageUrl,
          id: item.id,
          price: item.price,
        }));
      }
    },
    selectNeonPersonalizado() {
      const neonPersonalizado = this.optionsNeon.find((neon) => neon.name === 'Personalizado');
      this.data.neon = neonPersonalizado;
      this.updateData();
    },
    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper1, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    initSwiper2() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper2, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },

  },
  components: {
    SectionHeader,
    PrimaryButton,
    StepNav,
    SelectionCard,
    SelectionButton,
    PreviewCanvas,
    PricesComponent,
  },
};
</script>

<style lang="scss" scoped>
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGreen);
  }
}

.main-container {
  overflow-y: hidden;

  &::after {
    position: absolute;
    content: 'Scroll para ver más';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreenRGB), 0) 0%, rgba(var(--colorGreenRGB), 1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}</style>
