<template>
    <div></div>
</template>

<script>
import authService from '@/services/auth.service';

export default {
  name: 'Home-Index',
  data() {
    return {
      userId: null,
      adminAvailable: false,
      userIsAffiliate: false,
    };
  },
  mounted() {
    if (this.$store.getters['auth/hasRole'](['ROLE_USER'])) {
      if (authService.getDecodedJwtToken(this.$store.state.auth.user).affiliate) {
        this.$store.dispatch('affiliates/getLoggedAffiliate').then(() => {
          if (this.$store.state.affiliates.loggedAffiliate.fastRegister) {
            this.$router.push({ name: 'UserPanelFastRegister' });
          } else {
            this.$router.push({ name: 'UserPanel' });
          }
        });
      }
    } else {
      this.$router.push({ name: 'Home' });
    }
  },
  beforeMount() {

  },
};
</script>
