<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Por último" second="Los datos del evento" />
        <v-form action="">
          <div class="input-field">
            <label class="input-field--label"> Nombre de la pareja: </label>
            <v-text-field placeholder="Se usará para ponerlo en plantillas y álbum ¡Presta atención!" outlined dense
              color="black" v-model="user.namePartners" :rules="[$v.user.namePartners.required]" :disabled="disabled"
              hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Apellido de la novia: </label>
            <v-text-field placeholder="Se usará como contraseña para fotos online ¡Presta atención!" outlined dense
              color="black" v-model="user.lastName1" :rules="[$v.user.lastName1.required]" :disabled="disabled"
              hide-details></v-text-field>
          </div>

          <div class="input-field">
            <label class="input-field--label"> Dirección de la finca: </label>
            <v-text-field placeholder="Dirección de la finca" outlined dense color="black" v-model="user.streetPlace"
              :rules="[$v.user.streetPlace.required]" :disabled="disabled" hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Teléfono de contacto del responsable de la finca el día de la boda:
            </label>
            <v-text-field placeholder="Importante para coordinar la llegada" outlined dense color="black"
              v-model="user.phone2" :rules="[$v.user.phone2.required]" :disabled="disabled" type="number" hide-details
              hide-spin-buttons></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Número aproximado de invitados: </label>
            <v-text-field placeholder="Introduce un número" outlined dense color="black" v-model="user.guestsNumber"
              :rules="[$v.user.guestsNumber.required]" :disabled="disabled" type="number" hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label"> Hora aproximada de inicio de servicio: </label>
            <v-text-field placeholder="Si todavía no lo sabes, déjalo en blanco" outlined dense color="black"
              v-model="user.time" :disabled="disabled" hide-details></v-text-field>
          </div>
        </v-form>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button appearance="solid" text="Siguiente" size="small" v-on:click.native="nextStep"
            :enabled="!this.$v.$invalid" />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/videomaton_big.png" alt="" />
      </div>
    </section>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'info-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionHeader,
    PrimaryButton,
  },
  data: () => ({
    modal: false,
    time: false,
    user: {
      namePartners: null,
      lastName1: null,
      namePlace: null,
      streetPlace: null,
      phone2: null,
      guestsNumber: null,
      time: null,
    },
  }),
  validations() {
    const valObj = {
      user: {
        namePartners: { required },
        lastName1: { required },
        streetPlace: { required },
        phone2: { required },
        guestsNumber: { required },
      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
  },
  watch: {
    user: {
      handler() {
        this.updatePersonalData();
      },
      deep: true,
    },
    userValues: {
      handler() {
        this.user = this.userValues;
      },
      deep: true,
    },
  },

  methods: {
    nextStep() {
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updatePersonalData() {
      this.$emit('updateData', this.user);
      this.updatePersonalValid();
    },
    updatePersonalValid() {
      this.$emit('updateValid', this.$v);
    },

  },
};
</script>
<style lang="scss" scoped>
// *{
//   border: solid 1px red;
// }

.navigation-buttons {
  margin-top: 20%;
}

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

// .check-buttons {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   column-gap: calc(var(--base) * 2);
//   margin-bottom:auto;
// }
.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}

.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGrey);
  }

  .aside-container {
    padding: 0;
  }
}

.aside-image {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-container {
  overflow-y: hidden;

  &::after {
    position: absolute;
    content: 'Scroll para ver más';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--colorGreyRGB), 0) 0%, rgba(var(--colorGreyRgb), 1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
