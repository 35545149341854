<template>
  <v-dialog v-model="modal" class="dialog-wrapper" width="75%" scrollable max-width="450px">

    <v-card>
      <v-card-title>
        <span class="text-h5 mb-2">Editar los datos personales</span></v-card-title>
      <!-- <section-header first="Editar" second="Los datos del evento" /> -->
      <v-card-text style="height: 400px;">
        <v-form action="">
          <div class="input-field">
            <label class="input-field--label">Nombre completo: </label>
            <v-text-field placeholder="Nombre completo" outlined dense color="black" v-model="user.name"
              :rules="[$v.user.name.required]" :disabled="disabled" hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label">Correo electrónico: </label>
            <v-text-field placeholder="Correo electrónico" outlined dense color="black" v-model="user.email"
              :rules="[$v.user.email.required, $v.user.email.email]" :disabled="disabled" hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label">Teléfono: </label>
            <v-text-field placeholder="Teléfono" outlined dense color="black" v-model="user.phone"
              :rules="[$v.user.phone.required]" :disabled="disabled" type="number" hide-spin-buttons hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label">DNI/CIF: </label>
            <v-text-field placeholder="DNI/CIF" outlined dense color="black" v-model="user.idDocument"
              :rules="[$v.user.idDocument.required, $v.user.idDocument.dniValidation]" :disabled="disabled"
              hide-details></v-text-field>
          </div>
          <div class="input-field">
            <label class="input-field--label">Domicilio: </label>
            <v-text-field placeholder="Domicilio" outlined dense color="black" v-model="appointment.direction"
               :disabled="disabled" hide-details></v-text-field>
          </div>
          <div class="input-field">
          <label class="input-field--label"> Apellido de la novia: </label>
          <v-text-field placeholder="Apellido de la novia" outlined dense color="black" v-model="user.lastName1"
            :rules="[$v.user.lastName1.required]" :disabled="disabled" hide-details></v-text-field>
        </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Cancelar" size="small" v-on:click.native="openModal" />
          <primary-button appearance="solid" text="Guardar" size="small" v-on:click.native="updateInfo"/>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

import authHeader from '@/services/auth-header';
import axios from 'axios';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
// import SectionHeader from '@/components/Headers/SectionHeader.vue';
import dniValidation from '../../../utils/validations/dniValidation';

export default {
  name: 'update-personal-data-register',
  mixins: [validationMixin],
  props: {
    userValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    appointmentValues: null,
  },
  components: {
    // SectionHeader,
    PrimaryButton,
  },
  data: () => ({
    modal: false,
    user: {
      name: null,
      email: null,
      phone: null,
      idDocument: null,
      streetName: null,
      id: null,
      lastName1: null,
    },
    appointment: {
      direction: null,
    },
  }),
  validations() {
    const valObj = {
      user: {
        name: { required },
        email: { required, email },
        phone: { required },
        idDocument: { required, dniValidation },
        streetName: { required },
        lastName1: { required },
        // termsAccepted must be true
        // termsAccepted: {
        //   sameAs: sameAs(() => true),
        // },

      },
    };
    return valObj;
  },
  mounted() {
    if (this.userValues) {
      this.user = this.userValues;
    }
  },
  watch: {
    user: {
      handler() {
        this.updatePersonalData();
      },
      deep: true,
    },
    userValues: {
      handler() {
        this.user = this.userValues;
      },
      deep: true,
    },
    appointmentValues: {
      handler() {
        this.appointment = this.appointmentValues;
      },
      deep: true,
    },
    appointment: {
      handler() {
        this.updateAppointmentData();
      },
      deep: true,
    },

  },

  methods: {
    openModal() {
      this.modal = !this.modal;
    },
    nextStep() {
      this.$emit('nextStep', true);
      this.modal = false;
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updatePersonalData() {
      this.$emit('updateData', this.user);
      this.updatePersonalValid();
    },
    updateAppointmentData() {
      this.$emit('updateAppointmentData', this.appointment);
    },
    updatePersonalValid() {
      this.$emit('updateValid', this.$v);
    },
    updateInfo() {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}affiliate/info/${this.user.id}`,
          {
            name: this.user.name,
            email: this.user.email,
            phone: this.user.phone,
            idDocument: this.user.idDocument,
            streetName: this.user.streetName,
            lastName1: this.user.lastName1,
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.modal = false;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push('/inscripcion/error');
        })
        .finally(() => {
          this.loading = false;
        });
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/info/${this.user.appointmentId}`,
          {
            namePartners: this.appointment.namePartners,
            phone: this.appointment.phone2,
            guestsNumber: this.appointment.guestsNumber,
            name: this.appointment.namePlace,
            streetPlace: this.appointment.streetPlace,
            time: this.appointment.time,
            direction: this.appointment.direction,
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          this.modal = false;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push('/inscripcion/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}
.navigation-buttons{
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}
</style>
