<template>
  <div class="full-height">
    <!-- Start row with 2 columns -->
    <v-row class="home-row">
      <div class="home-col">
        <!-- Add card -->
        <v-card :elevation="$getCardOptions('elevation')"
          class="card dashboard_box full-card dashboard_column_grid colorGreen">
          <v-card-text class="text-center ">
            <img alt="App" src="../../assets/logo.png" style="height: 100px" />
          </v-card-text>
        </v-card>
      </div>
      <div class="home-col">
        <v-card :elevation="$getCardOptions('elevation')" v-if="user"
          class="card dashboard_box full-card dashboard_column_grid colorPink">
          <v-card-text class="text-center m-2">
            <v-avatar :color="$getColor('vuetifyColor1')" size="92" class="mt-5">
              <span class="white--text text-h5">{{ userChar }}</span>
            </v-avatar>
            <!-- Add title with heading 6-->
            <div class="title-header">
              <h2 class="second-line mb-2">{{ user.sub }}</h2>
              <h2 class="third-line ">{{ $getRoleTypeValues(user.rol).label }}</h2>

            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
    <!-- Start row with 2 columns -->
    <v-row class="home-row">
      <div class="home-col">
        <!-- Add card -->
        <v-card :elevation="$getCardOptions('elevation')"
          class="card dashboard_box full-card dashboard_column_grid colorRed">
          <v-card-text class="text-center">
            <v-icon class="mx-auto" :color="$getColor('vuetifyColor1')" size="90">celebration
            </v-icon>
            <v-btn :elevation="0" @click="goToOption1()" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large class="mx-auto">
              EVENTOS
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
      <div class="home-col">
        <v-card :elevation="$getCardOptions('elevation')"
          class="card dashboard_box full-card dashboard_column_grid colorPurple">
          <v-card-text class="text-center">
            <v-icon class="mx-auto " :color="$getColor('vuetifyColor1')" size="90">event
            </v-icon>
            <v-btn :elevation="0" @click="goToOption2()" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large class="mx-auto">
              CALENDARIO
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </div>
</template>

<script>

import authService from '@/services/auth.service';

export default {
  name: 'HomePanel',
  data() {
    return {
      userId: null,
      user: null,
      adminAvailable: false,
      userIsAffiliate: false,
    };
  },
  mounted() {
    this.user = authService.getDecodedJwtToken(this.$store.state.auth.user);
    this.userId = authService.getDecodedJwtToken(
      this.$store.state.auth.user,
    ).affiliate;
    if (this.userId == null) {
      this.userIsAffiliate = false;
    } else {
      this.userIsAffiliate = true;
    }

    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
  },
  computed: {
    userChar() {
      if (this.user) {
        // return 2 letters of the email
        return this.user.sub.substring(0, 2).toUpperCase();
      }
      return 'X';
    },

  },
  methods: {
    // TODO: Put in correct place
    goToOption1() {
      this.$router.push({ name: 'Appointments Table' });
    },
    goToOption2() {
      this.$router.push({ name: 'Calendar' });
    },

    goToPopulationPanel(id) {
      this.$router.push(`/population/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.home-row {
  max-height: 50%;
  min-height: calc(50% - var(--base));
  display: flex;
  flex-wrap: nowrap;
  gap: calc(var(--base) * 3);
  margin: 0;
  margin-bottom: calc(var(--base) * 2);

  .home-col {
    width: 100%;
    display: flex;
  }
  .card.dashboard_box.full-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .v-card__text.text-center{
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--base) * 3);
    }
  }
}

.colorGreen {
  background-color: var(--colorGreen)
}

.colorRed {
  background-color: var(--colorRed)
}

.colorPurple {
  background-color: var(--colorPurple)
}

.colorPink {
  background-color: var(--colorPink)
}
</style>
<style lang="scss" scoped>
//Functions

.title-header {
  width: 100%;
  letter-spacing: -0.02em;

  .first-line {
    font-size: calc(var(--base) * 2);
    line-height: calc(var(--base) * 3);
    font-weight: 300;
  }

  .second-line {
    margin: 0;
    padding: 0;
    font-size: calc(var(--base) * 3);
    line-height: 1;
    font-weight: 500;
  }

  .third-line {
    margin: 0;
    padding: 0;
    font-size: calc(var(--base) * 2.5);
    line-height: 1;
    font-weight: 600;
  }
}

</style>
