<template>
<div class="full-height">
    <!-- Step 1 -->
    <v-stepper v-model="e6" flat outlined>
      <v-stepper-content step="1" class="p-0 m-0">
            <check-availability @updateData="updateCheckAvailabilityData"
            @updateValid="updateCheckAvailabilityValid" @nextStep="nextStep">
            </check-availability>
      </v-stepper-content>
      <!-- Step 2 -->
      <v-stepper-content step="2" class="p-0 m-0">
            <fast-register @updateData="updateCheckRegisterData"
            @updateValid="updateCheckRegisterValid" @nextStep="nextStep"
            @backStep="backStep">
            </fast-register>
      </v-stepper-content>
      <!-- Step 3 -->
      <v-stepper-content step="3" class="p-0 m-0">
        <finish-configuration  ref="finishConfiguration"
              @nextStep="fastRegister" @backStep="backStep">
          </finish-configuration>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>

import CheckAvailability from '@/components/Register/Appointment/CheckAvailability.vue';
import FastRegister from '@/components/Register/Appointment/FastRegister.vue';
import FinishConfiguration from '@/components/Register/Appointment/FinishFastConfiguration.vue';

import authHeader from '../../services/auth-header';
// eslint-disable-next-line import/no-cycle
import axios from '../../services/http-common';

export default {
  name: 'configure-fast-appointment',

  data() {
    return {
      e6: 1,
      captcha: '',
      checkAvailabilityData: {
        type: null,
        eventTime: null,
        totalOnSelectedDate: null,
      },
      checkRegisterData: {
        name: null,
        postalCode: null,
        direction: null,
        postalCodeItem: null,
        distance: null,
        userName: null,
        email: null,
        idDocument: null,
        phone: null,
      },

      valid: {
        checkAvailability: false,
        checkRegister: false,
      },
    };
  },
  computed: {
    allValid() {
      return !!(!this.valid.checkAvailability.$invalid
        && !this.valid.checkRegister.$invalid);
    },
  },
  components: {
    CheckAvailability,
    FastRegister,
    FinishConfiguration,
  },

  watch: {
    allValid(areValid) {
      if (areValid) {
        // this.$refs.recaptcha.execute();
      }
    },
    prices() {
      this.updatePrices();
    },
  },
  methods: {
    nextStep(val) {
      if (val) {
        this.e6 += 1;
        if (this.$refs.finishConfiguration) {
          this.$refs.finishConfiguration.updateData(
            this.checkAvailabilityData,
            this.checkRegisterData,
          );
        }
      }
    },
    backStep() {
      this.e6 -= 1;
    },

    onVerify(response) {
      this.captcha = response;
    },

    updateCheckAvailabilityData(data) {
      this.checkAvailabilityData = data;
    },
    updateCheckAvailabilityValid(valid) {
      this.valid.checkAvailability = valid;
    },
    updateCheckRegisterData(data) {
      this.checkRegisterData = data;
    },
    updateCheckRegisterValid(valid) {
      this.valid.checkRegister = valid;
    },

    // function to remove spaces in the email for prevent error
    removeSpaces(string) {
      return string.split(' ').join('');
    },

    fastRegister() {
      if (!this.allValid) {
        return;
      }

      axios
        .post(
          `${process.env.VUE_APP_API_URL}affiliate/fast-register`,
          {
            name: this.checkRegisterData.userName,
            email: this.removeSpaces(this.checkRegisterData.email),
            // phone: this.personalData.phone,
            idDocument: this.checkRegisterData.idDocument,
            documentType: 'DNI',
            inscriptionType: 1,
            postalCode: this.checkRegisterData.postalCode,
            phone: this.checkRegisterData.phone,

            appointmentDto: {
              type: this.checkAvailabilityData.type,
              eventDate: this.checkAvailabilityData.eventTime,
              name: this.checkRegisterData.name,
              postalCode: 28025,
              direction: this.checkRegisterData.direction,
              priceForStats: 100,
            },
          },
          {
            headers: authHeader(),

          },
        )
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push('/inscripcion/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .grecaptcha-badge {
    visibility: hidden;
    opacity: 0.3;
}
</style>
