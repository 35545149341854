<template>
  <div>
    <!-- Add progress bar -->
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <div class="download mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on"
            @click="addNew()">
            <v-icon left>add</v-icon>
            Añadir idioma
          </v-btn>
        </template>
        <span>Añadir idioma importando fichero JSON de configuración</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isEdition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de" v-bind="attrs" v-on="on" @click="setEdition()">
            <v-icon left>edit</v-icon>
            Editar
          </v-btn>
        </template>
        <span>Pincha para eliminar códigos postales</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de" v-bind="attrs" v-on="on" @click="setEdition()">
            <v-icon left>clear</v-icon>
            Cancelar
          </v-btn>
        </template>
        <span>Pincha para salir de la edición</span>
      </v-tooltip>
    </div>
    <!-- Add content -->
    <v-data-table :color="$getColor('vuetifyColor1')" :headers="headers" :items="items" :items-per-page="-1"
      class="elevation-1">
      <template v-slot:[`item.iso2`]="{ item }">
        <span>{{  setUpperCase(item.iso2) }}</span>
      </template>
      <template v-slot:[`item.iso3`]="{ item }">
        <span>{{  setUpperCase(item.iso3) }}</span>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-icon v-if="item.active" color="green">check</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- Add edit button -->
        <v-tooltip bottom v-if="isEdition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab elevation="0" small @click="editItem(item.id)" v-bind="attrs" v-on="on">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar código postal</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  name: 'languages-view',
  data() {
    return {
      loading: false, // loading state for progress bar
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      isEdition: false,
      items: [], // data for v-data-table
      count: 0, // total count of data
      headers: [ // headers for v-data-table
        { text: 'Idioma', value: 'id' },
        { text: 'ISO2', value: 'iso2' },
        { text: 'ISO3', value: 'iso3' },
        { text: 'Activo', value: 'active' },
        { text: 'Acciones', value: 'actions', sortable: false },

      ],
    };
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']); // check if user has super admin role
    if (!this.adminAvailable) { // if user doesn't have super admin role, redirect to access denied page
      this.$router.push('/acceso-denegado');
    } else {
      this.getItems(); // load data
    }
  },

  methods: {
    setEdition() {
      this.isEdition = !this.isEdition;
    },
    setUpperCase(value) {
      return value.toUpperCase();
    },
    editItem(id) {
      console.log('edit item', id);
    },
    getItems() {
      this.loading = true;
      // wait 2 seconds to simulate loading
      setTimeout(() => {
        this.items = [{
          id: 'Español',
          iso2: 'es',
          iso3: 'esp',
          active: true,
        },
        {
          id: 'Inglés',
          iso2: 'en',
          iso3: 'eng',
          active: false,
        },
        {
          id: 'Francés',
          iso2: 'fr',
          iso3: 'fra',
          active: false,
        }];
        this.loading = false;
      }, 2000);
    },

    addNew() {
      // push by component name
      console.log('add new');
    },
  },

};
</script>
