<template>
  <div style="height: 82%;" class="calendar-container">
    <div class="dashboard_box " style="height:100%">

      <div style="display: flex; padding: 1rem">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Hoy
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <!-- Start traslated month from calendar title -->
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Día</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Semana</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Mes</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress" indeterminate>
      </v-progress-linear>
      <v-calendar ref="calendar" v-model="focus" :color="$getColor('vuetifyColor2')" :events="events"
        :event-color="getEventColor" :type="type" locale="es" :weekdays="weekdays" @click:event="showEvent"
        @click:more="viewDay" @click:date="viewDay" @change="updateRange" :interval-count="0">
        <template v-slot:event="{ event }">
          <div class="pl-1 black--text">
            <strong>{{ event.namePartners }}</strong>
            ( {{ event.name }} )
          </div>
          <div class="pl-1 black--text" v-if="event.fastRegister">
            <strong>Reserva rápida</strong>
            <v-icon :color="$getColor('vuetifyColor1')" small>bolt</v-icon>
            ( {{ event.name }} )
          </div>
          <div class="pl-1 black--text" v-if="event.manualRegister">
            <strong>{{ event.namePartners }}</strong>
            <v-icon :color="$getColor('vuetifyColor1')" small>handyman</v-icon>
            ( {{ event.name }} )
          </div>
        </template></v-calendar>
      <!-- template v-slot for day-body -->

      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
        <v-card color="grey lighten-4" min-width="450px" flat>
          <v-toolbar :color="selectedEvent.color" :dark="false">
            <v-btn icon @click="selectedOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title> {{ selectedEvent.type }}</v-toolbar-title>

            <v-icon class="ml-4">{{ selectedEvent.icon }}</v-icon>

          </v-toolbar>
          <v-card-text class="p-4">
            <!-- Description title -->
            <div class="input-field">
              <div class="input-field--label">
                Nombre de la pareja:
              </div>
              <!-- Start Description info -->
              <v-text-field v-if="selectedEvent.namePartners" solo dense flat color="black" label="" v-model="selectedEvent.namePartners" disabled
                hide-details></v-text-field>
              <v-text-field v-else solo dense flat color="black" label="" value="Sin configurar" disabled
                hide-details></v-text-field>

            </div>
            <!-- Participant title -->
            <v-divider></v-divider>
            <div class="input-field">
              <div class="input-field--label mb-0">
                Configuración:
              </div>
              <!-- Start Description info -->
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Photocall</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedEvent.photocall ? selectedEvent.photocall : 'Sin configurar'
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Neón <v-icon v-if="selectedEvent.neon && selectedEvent.neonInStock"
                        color="teal accent-3">mdi-check-circle-outline</v-icon>
                      <v-icon v-if="selectedEvent.neon && !selectedEvent.neonInStock"
                        color="grey">mdi-close-circle-outline</v-icon></v-list-item-title>
                    <v-list-item-subtitle>{{ selectedEvent.neon ? selectedEvent.neon : 'Sin configurar'
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Álbum<v-icon v-if="selectedEvent.albumInStock"
                        color="teal accent-3">mdi-check-circle-outline</v-icon>
                      <v-icon v-if="!selectedEvent.albumInStock"
                        color="grey">mdi-close-circle-outline</v-icon></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ selectedEvent.album ? selectedEvent.album : 'Sin configurar' }}
                      </v-list-item-subtitle>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Plantilla</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedEvent.plantilla ? selectedEvent.plantilla : 'Sin configurar'
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Extras</v-list-item-title>
                    <template v-if="(selectedEvent.extras && selectedEvent.extras.length > 0) ">
                      <v-list-item-subtitle v-for="extra in selectedEvent.extras" :key="extra.id">
                        {{ extra.name }}
                      </v-list-item-subtitle>
                    </template>
                    <v-list-item-subtitle v-else>
                      Sin configurar
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Extras con descuento</v-list-item-title>
                    <template v-if="(selectedEvent.extrasWithDiscount && selectedEvent.extrasWithDiscount.length > 0)">
                      <v-list-item-subtitle v-for="extra in selectedEvent.extrasWithDiscount" :key="extra.id">
                        {{ extra.name }}
                      </v-list-item-subtitle>
                    </template>
                    <v-list-item-subtitle v-else>
                      Sin configurar
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>

            <v-divider></v-divider>
            <!-- Start time info-->
            <v-row>
              <v-col cols="12" sm="6">
                <div class="input-field">
                  <div class="input-field--label mb-0">
                    Fecha del evento:
                  </div>
                  <v-text-field solo dense flat color="black" label="" :value="formatDateWithTime(selectedEvent.start)"
                    disabled hide-details></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="input-field">
                  <div class="input-field--label mb-0">
                    Hora aproximada:
                  </div>
                  <v-text-field v-if="selectedEvent.time" solo dense flat color="black" label=""
                    :value="(selectedEvent.time)" disabled hide-details></v-text-field>
                  <v-text-field v-else solo dense flat color="black" label="" value="Sin configurar" disabled
                    hide-details></v-text-field>
                </div>

              </v-col>
            </v-row>
            <!-- End time info-->
          </v-card-text>
          <v-card-actions class="pl-6 pr-6">
            <!-- <v-btn :color="selectedEvent.color" @click="goToEvent(selectedEvent.id)" x-large rounded>
              Abrir evento
            </v-btn> -->
            <div class="navigation-buttons">
              <primary-button appearance="text" text="Cerrar" size="small"  @click.native="selectedOpen = false"/>

              <primary-button appearance="solid" text="Abrir Evento" size="small"
                @click.native="goToEvent(selectedEvent.id)" />
            </div>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import AppointmentService from '@/services/appointment.service';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';

export default {
  name: 'calendar-view',
  data() {
    return {

      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Dia',
      },
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],

      items: null,
      loading: false,
      loaded: false,

    };
  },
  mounted() {
    this.getItems();
    this.$refs.calendar.checkChange();
  },

  methods: {
    getDayStyle(date) {
      const event = this.events.find((e) => e.start === date);
      if (event) {
        return {
          backgroundColor: event.color,
        };
      }
      return {};
    },
    formatDateWithTime(date) {
      const d = new Date(date);
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      return `${day}/${month}/${d.getFullYear()}`;
    },
    goToEvent(id) {
      this.fetchAffiliate(id);
    },
    goToEventPanel(id) {
      this.$router.push({ name: 'Appointments Panel', params: { id } });
    },
    async fetchAffiliate(id) {
      await AppointmentService.getAffiliateWithAppointmentId(id).then((res) => this.goToEventPanel(res.id));
    },
    getItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/all`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.items = response.data.content;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.loaded = true;
          this.updateRange();
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // eslint-disable-next-line no-return-assign
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      if (this.loaded) {
        const events = [];
        // items foreach
        this.items.forEach((item) => {
          events.push({
            name: item.name,
            namePartners: item.namePartners,
            description: item.description,
            photocall: item.photocall ? item.photocall.name : null,
            album: item.album ? item.album.name : null,
            neon: item.neon ? item.neon.name : null,
            plantilla: item.plantilla ? item.plantilla.name : null,
            extras: item.extras,
            extrasWithDiscount: item.extrasWithDiscount,
            time: item.time,
            id: item.id,
            type: this.$getAppointmentTypeValues(item.type).label,
            icon: this.$getAppointmentTypeValues(item.type).icon,
            // add two hours to item.eventDate
            start: new Date(item.eventDate),
            end: new Date(item.eventDate),
            // color: this.colors[this.rnd(0, this.colors.length - 1)],
            color: this.$getAppointmentTypeValues(item.type).color,
            participants: null,
            timed: false,
            albumInStock: item.albumInStock,
            neonInStock: item.neonInStock,
          });
        });

        this.events = events;
      }
    },

  },

  watch: {

  },

  components: {
    PrimaryButton,
  },
};
</script>
<style lang="scss">
.calendar-container {

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary {
    color: #fff;
    background-color: #FF0000;
  }

  v-sheet.v-list {
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
  }

  .theme--light.v-list {
    background-color: rgb(var(--bodyBg));
  }

  .v-list-item {}

}

.v-list-item__title {
  display: flex;
  align-items: center;
  gap: calc(var(--base)*1);

}
</style>
<style lang="scss" scoped>
.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}
.v-toolbar__title{
    padding-left: calc(var(--base)*0.5) !important;
  }
</style>
