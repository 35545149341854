<template>
  <div>
    <!-- Add loading -->
    <v-progress-linear
      v-if="loading"
      indeterminate
      :color="$getColor('vuetifyColor1')"
      class="mb-2"
    ></v-progress-linear>
    <v-dialog ref="dialog" v-model="startDateMenu" :return-value.sync="filter.startDate"
      persistent width="450px"
      origin="top center">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="filter.startDate" label="Fecha inicio"
        prepend-inner-icon="mdi-calendar" readonly class="mt-3"
          v-bind="attrs" v-on="on" outlined :color="$getColor('vuetifyColor1')"></v-text-field>
      </template>
      <v-date-picker v-model="filter.startDate" scrollable full-width
      :header-color="$getColor('vuetifyColor2')" locale="es"  first-day-of-week="1"
        :landscape="false">
        <v-spacer></v-spacer>
        <v-btn text @click="startDateMenu = false">
          Cancel
        </v-btn>
        <v-btn text :color="$getColor('vuetifyColor1')"
        @click="$refs.dialog.save(filter.startDate)">
          OK
        </v-btn>
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-dialog>
    <!-- Add end date input for filter -->
    <v-dialog ref="dialogEnd" v-model="endDateMenu" :return-value.sync="filter.endDate"
      persistent width="450px"
      origin="top center">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="filter.endDate" label="Fecha fin"
        prepend-inner-icon="mdi-calendar" readonly class="mt-3"
          v-bind="attrs" v-on="on" outlined :color="$getColor('vuetifyColor1')"></v-text-field>
      </template>
      <v-date-picker v-model="filter.endDate" scrollable full-width
      :header-color="$getColor('vuetifyColor2')" locale="es"  first-day-of-week="1"
        :landscape="false">
        <v-spacer></v-spacer>
        <v-btn text @click="endDateMenu = false">
          Cancel
        </v-btn>
        <v-btn text :color="$getColor('vuetifyColor1')"
        @click="$refs.dialogEnd.save(filter.endDate)">
          OK
        </v-btn>
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-dialog>
    <v-divider></v-divider>
    <!-- Add button for download -->
    <v-tooltip bottom v-if="filter.startDate && filter.endDate">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large v-bind="attrs" v-on="on"
            @click="downloadClick('.xlsx')">
              <v-icon left>download</v-icon>
              Descargar
          </v-btn>
        </template>
        <span>Exportar extras en fichero Excel</span>
    </v-tooltip>
  </div>
</template>
<script>
import * as XLSX from 'xlsx';

// eslint-disable-next-line import/no-cycle
import axios from '../../services/http-common';
import authHeader from '../../services/auth-header';

export default {
  name: 'export-extras-data',
  props: {
    exportFileName: {
      type: String,
      default: 'extras.xlsx',
    },
  },
  data() {
    return {
      tableData: null,
      filter: {
        startDate: null,
        endDate: null,
      },
      loading: false,
      dataExport: null,
      count: null,
      startDateMenu: false,
      endDateMenu: false,
    };
  },

  methods: {

    downloadClick(extension) {
      if (extension) {
        this.getItemsAndDownload(extension);
        this.loading = true;
      }
      this.loading = false;
    },
    download(extension) {
      const Heading = [
        ['UNIDADES', 'DISEÑO', 'NEÓN PERSONALIZADO', 'NOMBRES Y/O INICIALES en orden',
          'FECHA BODA', 'NEÓN EN ALMACÉN', 'Anotaciones extra'],
      ];

      this.dataExport = this.tableData.map((item) => {
        const outItem = JSON.parse(JSON.stringify(item));
        return outItem;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.dataExport);
      XLSX.utils.sheet_add_aoa(ws, Heading);
      XLSX.utils.book_append_sheet(wb, ws, this.exportFileName);

      // const date = new Date();
      // format startDate
      const startDateString = new Date(this.filter.startDate).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const startDateTitle = startDateString.split('/').reverse().join('-');
      // format endDate
      const endDateString = new Date(this.filter.endDate).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const endDateTitle = endDateString.split('/').reverse().join('-');
      const fileName = `${this.exportFileName}_${startDateTitle}_${endDateTitle}.${extension}`;
      XLSX.writeFile(wb, fileName);
      this.loading = false;
    },

    getRequestParams() {
      const params = {};
      // format startDate with timezone = "Europe/Madrid"
      params.startDate = new Date(this.filter.startDate).toLocaleString('en-GB', { timeZone: 'Europe/Madrid' });
      params.endDate = new Date(this.filter.endDate).toLocaleString('en-GB', { timeZone: 'Europe/Madrid' });
      params.type = 'extras';
      return params;
    },

    getItemsAndDownload(extension) {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/export`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.tableData = response.data.content;
          // map tableData
          this.tableData = response.data.content.map((item) => ({
            Unidades: 1,
            Diseño: `Neón ${item.neon.name}`,
            neonPersonalizado: item.neonPersonalizado,
            namePartners: item.namePartners,
            date: item.eventDate.split('T')[0],
            neon: item.neonInStock ? 'Sí' : 'No',
          }));

          this.loading = false;
          this.download(extension);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled');
          } else {
            console.log(error);
            this.errored = true;
            this.loading = false;
          }
        });
    },
  },
};
</script>
