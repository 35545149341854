<template>
  <div class="full-height">
    <div class="page-layout">
      <section class="main-container">
        <div class="main-container--inner">
          <section-header first="Lo sentimos..." second="El proceso de reserva ha sido cancelado" />
          <div class="configuration-menu">
            <v-card :elevation="$getCardOptions('elevation')"  color="transparent">
              <div class="text__grid">
                <div class="text-muted">
                  <p>
                    Si necesitas más información, puedes ponerte en contacto con nosotros a través de:
                  </p>
                    <section-header first="" second="info@funpicfotomaton.com" />
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </section>
      <section class="aside-container p-0">
        <div class="logo-container">
          <h1 class="first-line">Funpic<sup>®</sup></h1>
        </div>
        <div class="aside-image">
          <img
            src="@/assets/images/basic-desktop_big.jpeg"
            alt="" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'register-completed',
  data: () => ({

  }),
  components: {
    SectionHeader,
  },
};
</script>

<style lang="scss" scoped>
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

</style>
