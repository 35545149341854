<template>
  <v-dialog v-model="dialog" class="dialog-wrapper" width="450px">
    <form id="createAdministrator" @submit.prevent="validateUser">
      <v-card class="dialog-box">
        <v-card-title class="dialog-title" :color="$getColor('vuetifyColor1')">
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text class="input-field">
          <label class="input-field--label" v-if="showPasswordRecovery"> Tu email: </label>
          <label class="input-field--label" v-else> Usuario: </label>
          <v-text-field class="mb-4" :color="$getColor('vuetifyColor1')" outlined id="username"
            placeholder="nombre@ejemplo.com" hide-details v-model="passwordRecoveryEmail" type="text" :disabled="sending"
            :value="passwordRecoveryEmail" name="username" v-if="showPasswordRecovery">
          </v-text-field>
          <v-text-field class="mb-4" :color="$getColor('vuetifyColor1')" outlined id="username"
            placeholder="nombre@ejemplo.com" hide-details v-model="$v.input.username.$model" type="text"
            :disabled="sending" name="username" v-else>
          </v-text-field>
          <label class="input-field--label" v-if="!showPasswordRecovery"> Contraseña: </label>
          <v-text-field class="mb-4" :color="$getColor('vuetifyColor1')" outlined id="password" v-model="input.password"
            :type="showPassword ? 'text' : 'password'" :disabled="sending" name="password" placeholder="*******"
            hide-details :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword" v-if="!showPasswordRecovery">
          </v-text-field>
          <div class="error-message" :class="color" v-if="message"> {{ message }}</div>
          <a @keydown="togglePasswordRecovery" @click="togglePasswordRecovery" v-if="!showPasswordRecovery">Recuperar
            contraseña</a>

          <v-progress-linear v-if="sending" class="mt-3 mb-3" :color="$getColor('vuetifyColor1')"
            :indeterminate="loading">
          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <div style="display: flex;flex-direction: column; width: 100%; gap: 1rem;">
            <!-- <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
              type="submit" x-large block>
              Iniciar sesión
            </v-btn> -->
            <template v-if="showPasswordRecovery">
              <primary-button appearance="outlined" text="Volver" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'"
                v-on:click.native="togglePasswordRecovery" />
              <primary-button appearance="solid" text="Recuperar contraseña" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'" type="submit"
                @click.native="handleEmailRecovery" />
            </template>
            <template v-else>
              <primary-button appearance="outlined" text="Cancelar" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'"
                v-on:click.native="openModal" />
              <primary-button appearance="solid" text="Iniciar Sesión" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'" type="submit" />
            </template>
          </div>
        </v-card-actions>

      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';

export default {
  name: 'login-modal-view',
  mixins: [validationMixin],
  components: {
    PrimaryButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      message: '',
      sending: false,
      showPasswordRecovery: false,
      passwordRecoveryEmail: '',
      dialog: false,
      showPassword: false,
      color: 'error-color',
    };
  },
  validations: {
    input: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    togglePasswordRecovery() {
      this.showPasswordRecovery = !this.showPasswordRecovery;
      this.message = '';
    },

    openModal() {
      this.showPasswordRecovery = false;

      this.dialog = !this.dialog;
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.handleLogin();
      }
    },
    submit() {
      this.$refs.form.$el.submit();
    },
    handleLogin() {
      this.sending = true;
      const user = {};
      user.username = this.input.username;
      user.password = this.input.password;

      this.loading = true;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          if (error.response.status === 403) {
            this.message = 'Usuario o contraseña no válidos';
            this.color = 'error-color';
          } else {
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
          }
          this.sending = false;
        },
      );
    },
    handleEmailRecovery() {
      console.log(this.passwordRecoveryEmail, 'email');
      // Comprobamos si el campo de correo electrónico está vacío
      if (!this.passwordRecoveryEmail) {
        this.message = 'Por favor, introduce tu correo electrónico';
        this.color = 'error-color';
        return;
      }

      this.sending = true;
      this.loading = true;

      this.$store
        .dispatch('auth/recoverPassword', this.passwordRecoveryEmail)
        .then(
          () => {
            this.loading = false;
            // Establecemos un mensaje para indicar al usuario que si el correo electrónico existe, se enviará un correo con las instrucciones para recuperar la contraseña.
            this.message = 'Si tu correo electrónico existe en nuestro sistema, habrás recibido un mensaje con las instrucciones para recuperar tu contraseña. Por favor, revisa tu bandeja de entrada.';
            this.sending = false;
            this.color = 'success-color';
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            this.loading = false;
            this.sending = false;
          },
        );
    },
  },
  mounted() {
    this.showPasswordRecovery = false;
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  &.error-color {
    color: red;
  }
  &.success-color {
    color: green;
  }
}

.add_user-btn {
  max-width: 15em;
  margin-top: 0;
  margin-bottom: 1em;
}

.dialog-title {
  margin-bottom: 1em;

  span {
    color: var(--bodyColor);
  }
}

.dialog-wrapper {
  max-width: 250px;

  .v-dialog--active {
    max-width: 250px !important;
  }
}

.dialog-box {
  padding: 1em;
}
</style>
