import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

import VueLayers from 'vuelayers';
import 'vuelayers/dist/vuelayers.css';

import Vuelidate from 'vuelidate';
import vuetify from '@/plugins/vuetify';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import props from './utils/config';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './assets/css/vars.scss';
import './assets/css/style.scss';
import './assets/css/global.scss';
import './assets/css/global_components.scss';

Vue.use(Vuelidate);
Vue.use(VueLayers);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueMaterial);

Vue.use(props);
Vue.directive('scrollable', {
  inserted(el) {
    const isOverflowing = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

    if (isOverflowing) {
      el.classList.add('scrollable');
    }
  },
});

new Vue({
  vuetify,
  validations: {},
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
vuetify.preset.theme.themes.light.primary = '#f40000';
