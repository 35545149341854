<!-- Vue template view for accesibility -->
<template>
    <v-container class="accesibility-container">
        <!-- vuetify container for accesibility long text (texto en español) -->
        <v-row>
            <v-col cols="12" md="12">
                <h2>Accesibilidad</h2>
<p>
    Funpic Fotomatón, en su esfuerzo continuo por la accesibilidad digital, ha realizado un gran trabajo para hacer que su sitio web, https://app.funpicfotomaton.com, sea accesible, de acuerdo con el Real Decreto 1112/2018, de 7 de septiembre, sobre accesibilidad de los sitios web y aplicaciones para dispositivos móviles del sector público.
</p>
<p>
    Esta declaración de accesibilidad se aplica a https://app.funpicfotomaton.com:
</p>

<h3>Estado de cumplimiento</h3>
<p>
    Este sitio web cumple en gran medida con el RD 1112/2018, con una excepción que se describe a continuación.
</p>

<h3>Contenido no accesible</h3>
<p>
    Existe una área específica del sitio web que aún no es completamente accesible debido a lo siguiente:
</p>

<h4>No conformidad con el RD 1112/2018</h4>
<p>
    Se ha identificado que el sitio web puede tener un problema con "Sugerencias ante errores". Estamos trabajando para resolver este problema y mejorar la experiencia de todos los usuarios.
</p>

<h4>Carga desproporcionada</h4>
<p>
    No aplica.
</p>

<h4>Contenido fuera del alcance de la legislación aplicable</h4>
<p>
    No aplica.
</p>

<h3>Preparación de la próxima declaración de accesibilidad</h3>
<p>
    Esta declaración se preparó el 28 de junio de 2021. Para preparar esta declaración, se ha realizado una autoevaluación por parte de nuestra entidad.
</p>

<h3>Observaciones y detalles de contacto</h3>
<p>
    Nos gustaría recibir tus comentarios y preguntas sobre la accesibilidad de nuestro sitio web (según el artículo 10.2.a del RD 1112/2018), tales como:
</p>
<ul>
    <li>Informar sobre cualquier posible incumplimiento por parte de este sitio web.</li>
    <li>Comunicar otras dificultades de acceso al contenido.</li>
    <li>Realizar cualquier otra consulta o sugerencia de mejora con respecto a la accesibilidad del sitio web.</li>
</ul>
<p>
    Teléfono: 644744466<br>
    Correo electrónico: info@funpicfotomaton.com
</p>

<h3>Actualizaciones y revisiones</h3>
<p>
    Esta declaración de accesibilidad será revisada y actualizada regularmente para garantizar que se mantiene su conformidad y para mejorar aún más la accesibilidad de nuestro sitio web.
</p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Accesibility-view',
};
</script>

<style scoped></style>
