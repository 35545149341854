<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Para terminar" second="Completa la siguiente información" />
        <v-form action="">
          <div class="field-group">
            <!-- add v-icon  -->
            <v-icon class="field-group--icon mr-1 mb-2" color="black" size="24">mdi-account</v-icon>
            <label for="" class="field-group--label">
              Datos personales:
            </label>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> Nombre: </label>
            <v-text-field v-model="itemData.userName" :rules="[$v.itemData.userName.required]"
              placeholder="Nombre completo..." outlined dense color="black" hide-details></v-text-field>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> DNI/CIF: </label>
            <v-text-field v-model="itemData.idDocument" :rules="[$v.itemData.idDocument.required]"
              placeholder="Documento de identidad..." outlined dense color="black" hide-details></v-text-field>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> Domicilio: </label>
            <v-text-field v-model="itemData.direction" :rules="[$v.itemData.direction.required]"
              placeholder="Dirección personal..." outlined dense color="black" hide-details></v-text-field>
          </div>
          <v-divider class="mt-10 mb-10"></v-divider>

          <div class="field-group">
            <!-- add v-icon for contact -->
            <v-icon class="field-group--icon mr-1 mb-2" color="black" size="24">mdi-phone</v-icon>
            <label for="" class="field-group--label">
              Datos de contacto:
            </label>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> Correo electrónico: </label>
            <v-text-field v-model="itemData.email" :rules="[$v.itemData.email.required, $v.itemData.email.email]"
              placeholder="Email..." outlined dense color="black" hide-details></v-text-field>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> Teléfono: </label>
            <v-text-field v-model="itemData.phone" :rules="[$v.itemData.phone.required]" placeholder="Teléfono..."
              outlined dense color="black" type="number" hide-details hide-spin-buttons></v-text-field>
          </div>
          <v-divider class="mt-10 mb-10"></v-divider>
          <div class="field-group">
            <!-- add v-icon for contact -->
            <v-icon class="field-group--icon mr-1 mb-2" color="black" size="25">mdi-calendar</v-icon>
            <label for="" class="field-group--label">
              Datos del evento:
            </label>
          </div>
          <div class="input-field field-group">
            <label class="input-field--label"> Lugar del evento: </label>
            <v-text-field v-model="itemData.name" :rules="[$v.itemData.name.required]"
              placeholder="Nombre / dirección de la finca..." outlined dense color="black" hide-details></v-text-field>
          </div>
          <!-- <div class="input-field field-group">
            <label class="input-field--label"> Código postal: </label>
            <v-text-field v-model="itemData.postalCode" placeholder="Código postal de la finca..."
              outlined dense color="black" :hint="distanceText" persistent-hint></v-text-field>
            <span v-if="!$v.distance.lessThan">
              Lo sentimos... La distancia es mayor de 200km.
            </span>
          </div> -->
          <div class="field-group">
            <v-checkbox class="checkbox-field" color="accent" v-model="itemData.termsAccepted">
              <template v-slot:label>
                <div>
                  Acepto los
                  <a class="link" @click.stop target="_blank" href="https://funpicfotomaton.com/privacidad/">
                    términos y condiciones
                  </a>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-form>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button appearance="solid" text="Confirmar" size="small" v-on:click.native="nextStep"
            :enabled="!this.$v.$invalid && !buttonClicked" />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop-2.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';
// import authHeader from '@/services/auth-header';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeaderSmall.vue';
// import dniValidation from '../../../utils/validations/dniValidation';

const axios = require('axios');

export default {
  name: 'check-register',
  mixins: [validationMixin],
  data: () => ({
    itemData: {
      name: null,
      postalCode: 28025,
      direction: null,
      postalCodeItem: null,
      distance: 0,
      userName: null,
      email: null,
      idDocument: null,
      phone: null,
      termsAccepted: false,
    },
    modal: false,
    loading: false,
    originCoordinates: {
      latitude: -3.8752786669764077,
      longitude: 40.489787823760636,
    },
    options: null,
    priorityOptions: null,
    submitClicked: false,

  }),
  mounted() {
    this.getItems();
    this.getPriorityItems();
  },
  components: {
    SectionHeader,
    PrimaryButton,
  },
  computed: {
    distance() {
      if (this.itemData.postalCodeItem) {
        if (this.itemData.postalCodeItem.distance) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.itemData.distance = this.itemData.postalCodeItem.distance.toFixed(0);
          return this.itemData.postalCodeItem.distance.toFixed(0);
        }
        const distance = this
          .getDistanceFromLatLonInKm(
            this.itemData.postalCodeItem.latitude,
            this.itemData.postalCodeItem.longitude,
            this.originCoordinates.latitude,
            this.originCoordinates.longitude,
          );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.itemData.distance = distance.toFixed(0);
        return distance.toFixed(0);
      }
      return null;
    },
    distanceText() {
      if (this.itemData.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }
      return null;
    },
  },
  watch: {
    // watch changes on itemData.assemblyType
    'itemData.postalCode': {
      handler() {
        this.checkRegister();
      },
      deep: true,
    },
  },
  validations: {
    distance: {
      // should be less than 200
      lessThan(value) {
        return value < 200;
      },
    },
    itemData: {
      name: {
        required,
      },
      userName: {
        required,
      },
      // termsAccepted must be true
      termsAccepted: {
        sameAs: sameAs(() => true),
      },
      email: { required, email },
      idDocument: { required },
      // Add validation for postalCode (count 5 digits)
      // postalCode: {

      //   postalCode(value) {
      //     if (value) {
      //       return value.length === 5;
      //     }
      //     return true;
      //   },
      //   available(value) {
      //     if (value && value.length === 5) {
      //       // get postalCode in this.options
      //       try {
      //         const postalCode0 = this.priorityOptions.find((item) => item.id === Number(value));
      //         // if postalCode is in priorityOptions
      //         if (postalCode0) {
      //           this.itemData.postalCodeItem = postalCode0;
      //           return true;
      //         }
      //         const postalCode = this.options.find((item) => item.codigopostalid === value);
      //         this.itemData.postalCodeItem = postalCode;
      //         // console.log(postalCode);
      //         return !!(postalCode);
      //       } catch (error) {
      //         return false;
      //       }
      //     }
      //     return false;
      //   },
      // },
      direction: {
        required,
      },
      phone: {
        required,
      },
    },
  },
  methods: {
    nextStep() {
      this.checkRegister();
      if (!this.buttonClicked) {
        this.buttonClicked = true;
        this.$emit('nextStep', true);
        // Aquí puedes agregar cualquier lógica adicional para el manejo del clic
      }
    },
    backStep() {
      this.$emit('backStep', true);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    updateData() {
      this.$emit('updateData', this.itemData);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    updatePostalCode(value) {
      this.itemData.postalCode = value;
    },
    checkRegister() {
      this.updateData();
    },
    getPriorityItems() {
      axios.get(
        `${process.env.VUE_APP_API_URL}postal-code-distance/all`,
      )
        .then((response) => {
          this.priorityOptions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // check availability
    getItems() {
      axios.get(
        `${process.env.VUE_APP_API_URL}postal-code?size=-1`,
      )
        .then((response) => {
          this.options = response.data.content;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}

.main-container--inner {
  .field-group:last-of-type {
    margin-bottom: auto;
  }

  .field-group {
    margin-bottom: calc(var(--base) * 1.25);
  }
}

.aside-container {
  padding: 0 !important;
}

.main-container {
  overflow-y: hidden;
  background-color: var(--bodyBg);

  &::after {
    position: absolute;
    content: 'Scroll para ver más';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
