<template>
  <div>
    <canvas id="historic-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'appointments-historic-chart',
  props: {
    language: {
      type: String,
      default: 'es',
    },
  },

  data() {
    return {
      chartObj: null,
      inscriptionHistoricData: {
        totalAfiliados: [],
        totalSimpatizantes: [],
        totalColaboradores: [],
        totalNull: [],
      },
      historicData: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          aspectRatio: 2,
          scales: {
            x: {
              title: {
                display: false,
                text: 'Month',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Total population',
              },
              min: 0,
              ticks: {
                // forces step size to be 50 units
                stepSize: 1,
              },
            },
          },
        },
      },
      dataLoadad: false,
    };
  },

  mounted() {
    const ctx = document.getElementById('historic-chart');
    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.historicData);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      if (window.innerWidth < 768 && this.historicData.options.aspectRatio === 2.1) {
        this.historicData.options.aspectRatio = 1;
        this.charObj.update();
      } else if (window.innerWidth > 768
      && this.historicData.options.aspectRatio === 1) {
        this.historicData.options.aspectRatio = 2.1;
        this.charObj.update();
      }
    },

    updateDatasets(newDataset) {
      const spalabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

      const engLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];

      const labels = this.language === 'eng' ? engLabels : spalabels;

      const currentMonth = new Date().getMonth() + 1;

      // update labels
      this.historicData.data.labels = this
        .moveArray(labels, currentMonth);

      this.historicData.data.datasets = newDataset;
      setTimeout(() => {
        this.charObj.update('show');
      }, 10);
    },
    moveArray(arr, offset) {
      return arr.slice(offset).concat(arr.slice(0, offset));
    },
  },
};
</script>
