<template>
  <div>
    <div class="page-layout">
      <section class="main-container mt-5 pt-5">
        <div class="main-container--inner">
          <section-header first="Ahora toca" second="Crear tu cuenta en Funpic" />
          <div class="configuration-menu">

            <v-card :elevation="$getCardOptions('elevation')" class="mt-5 pt-5" color="transparent">
              <div>
                <div class="text-center text-muted mb-5">
                  <small>Código de validación de usuario</small>
                  <h6 class="val-code mt-2 mb-4">{{ this.$route.query.cod }}</h6>
                </div>
                <hr class="my-4" />
              </div>
              <div class="text__grid mt-5">
                <div class="text-center text-muted mt-5 mb-5 ">
                  <small>Introduce tu contraseña</small>
                  <small> para crear tu usuario y acceder a la plataforma.</small>
                </div>
                <div>
                  <user-data-register @updateUser="updateValidationUser"></user-data-register>
                  <div class="login-user-btn mb-5">
                    <v-btn :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
                          :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                          block @click="validateUser" large>
                      GUARDAR
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </section>
      <section class="aside-container">
        <div class="logo-container">
          <h1 class="first-line">Funpic<sup>®</sup></h1>
        </div>
        <div class="aside-image">
          <img
            src="@/assets/images/basic-desktop_big.jpeg"
            alt="" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import UserDataRegister from '@/components/Register/User/UserPasswordRegister.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

const axios = require('axios');

export default {
  name: 'user-register-validation',
  data: () => ({
    user: {
      name: null,
      password: null,
    },
  }),
  components: {
    UserDataRegister,
    SectionHeader,
  },
  methods: {
    updateValidationUser(val) {
      this.user = val;
    },
    validateUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}users`,
          {
            username: this.user.name,
            password: this.user.password,
            validationCode: this.$route.query.cod,
            affiliateId: this.$route.query.id,
          },
        )
        .then(() => {
          this.$router.push('/login');
        })
        .catch(() => {
          this.$router.push('/error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom:auto;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}
</style>
