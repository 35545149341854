<template>
  <div>
    <canvas :id="containerId"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'standard-chart',

  props: {
    colors: {
      type: Array,
      default: () => ['#4caf50', '#ff9800'],
    },
    borderColor: {
      type: String,
      default: '#fff',
    },
    label: {
      type: String,
      default: 'Total',
    },
    containerId: {
      type: String,
      default: 'standard-chart',
    },
  },

  data() {
    return {
      chartObj: null,
      totalNoticias: 0,
      loading: false,
      chartData: {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [
            {
              label: this.label,
              data: [0, 0],
              backgroundColor: this.colors,
              borderColor: this.borderColor,
              borderWidth: 0,
            },
          ],
        },
        options: {
          layout: {
            padding: {
              left: 14,
              right: 14,
              top: 14,
              bottom: 14,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          autoPadding: false,
          lineTension: 1,
          scales: {},
        },
      },
    };
  },

  methods: {

    updateValues(values, labels, colors) {
      this.chartData.data.datasets[0].data = values;
      this.chartData.data.labels = labels;
      this.chartData.data.datasets[0].backgroundColor = colors;
      const ctx = document.getElementById(this.containerId);
      if (this.chartObj) {
        this.chartObj.destroy();
      }
      this.chartObj = new Chart(ctx, this.chartData);
    },

    updateParent() {
      this.$emit('updateParent', this.stats);
    },
  },
};
</script>
