<template>
  <div style="margin-bottom: 2rem;">

    <header-filter @updateParent="updateFilter" @downloadClick="downloadClickData" icon="people" ref="headerFilter">
    </header-filter>
    <standard-table ref="table" @updateTotal="updateTotal">
    </standard-table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import HeaderFilter from '../../components/Filter/AppointmentHeaderFilter.vue';
import StandardTable from '../../components/Tables/AppointmentsTable.vue';

// eslint-disable-next-line import/no-cycle
import axios from '../../services/http-common';
import authHeader from '../../services/auth-header';

export default {
  name: 'appointments-data',
  props: {
    exportFileName: {
      type: String,
      default: 'population.xlsx',
    },
  },
  data() {
    return {
      tableData: null,
      filter: {},
      loading: false,
      dataExport: {},
      count: null,
    };
  },
  mounted() {
    this.getAlerts();
  },

  methods: {
    updateAlerts(val) {
      this.$refs.headerFilter.updateAlerts(val);
    },
    updateTable() {
      this.$refs.table.updateFilter(this.filter);
    },
    updateTotal(val) {
      this.count = val;
    },
    updateFilter(val) {
      this.filter = val;
    },
    downloadClick(extension) {
      if (extension) {
        this.getItemsAndDownload(extension);
        this.loading = true;
      }
      this.loading = false;
    },
    downloadClickData() {
      this.$refs.table.downloadData();
    },
    download(extension) {
      this.dataExport = this.tableData.map((item) => {
        const outItem = JSON.parse(JSON.stringify(item));
        return outItem;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.dataExport);
      XLSX.utils.book_append_sheet(wb, ws, this.exportFileName);

      const date = new Date();
      const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const fileName = `${this.exportFileName}_${dateString}.${extension}`;
      XLSX.writeFile(wb, fileName);
      this.loading = false;
    },
    getAlerts() {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}assemblyUnique/alerts`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
        })
        .then((response) => {
          this.updateAlerts(response.data);
          this.loading = false;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled');
          } else {
            console.log(error);
            this.errored = true;
            this.loading = false;
          }
        });
    },

    getRequestParams(page, pageSize) {
      const params = {};

      params.idDocument = this.filter.idDocument;
      params.name = this.filter.name;
      params.assemblyType = this.filter.assemblyType;
      params.assemblyId = this.filter.assembly;
      params.lastName1 = this.filter.lastName1;
      params.email = this.filter.email;
      params.sex = this.filter.sex;
      params.inscriptionType = this.filter.inscriptionType;
      params.city = this.filter.city;
      params.district = this.filter.district;
      params.fee = this.filter.fee;
      params.aportacion = this.filter.aportacion;
      params.sort = this.filter.currentSort;
      params.direction = this.filter.currentSortOrder;
      params.onlyNext = this.filter.onlyNext;
      params.fastRegister = this.filter.fastRegister;

      if (page) {
        params.page = page - 1;
      }

      if (pageSize) {
        params.size = pageSize;
      }

      return params;
    },

    getItemsAndDownload(extension) {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}affiliate`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.tableData = response.data.content;
          this.loading = false;
          this.download(extension);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled');
          } else {
            console.log(error);
            this.errored = true;
            this.loading = false;
          }
        });
    },
  },

  watch: {
    filter: {
      handler() {
        this.updateTable();
      },
      deep: true,
    },
  },

  components: {
    HeaderFilter,
    StandardTable,
  },
};
</script>
