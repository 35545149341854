<template>
  <div class="page-layout">
    <section class="main-container">
      <div class="main-container--inner no-scrollbar">
        <section-header first="Y ahora" second="¿Dónde será el evento?" />
        <v-form action="">
          <div class="input-field field-group">
            <label class="input-field--label field-group--label"> Lugar del evento: </label>
            <v-text-field v-model="itemData.name" :rules="[$v.itemData.name.required]" placeholder="Por ejemplo: La Casita" outlined
              dense color="black" hide-details></v-text-field>
          </div>
          <!--
            <div class="input-field field-group">
            <label class="input-field--label field-group--label"> Dirección de la finca: </label>
            <v-text-field v-model="itemData.direction" :rules="[$v.itemData.direction.required]" placeholder="Por ejemplo: C/ La Casita, 1"
              outlined dense color="black" hide-details></v-text-field>
          </div>
          -->

          <!-- <div class="input-field field-group">
            <label class="input-field--label field-group--label"> Código postal: </label>
            <v-text-field v-model="itemData.postalCode" :rules="[$v.itemData.postalCode.required, $v.itemData.postalCode.postalCode,
            $v.itemData.postalCode.available, $v.distance.lessThan]" placeholder="Por ejemplo: 28225" outlined dense
              color="black" :hint="distanceText" persistent-hint></v-text-field>
            <span v-if="!$v.distance.lessThan">
              Lo sentimos... La distancia es mayor de 200km.
            </span>
          </div> -->
        </v-form>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Atrás" size="small" v-on:click.native="backStep" />
          <primary-button appearance="solid" text="Siguiente" size="small" v-on:click.native="nextStep"
            :enabled="!this.$v.$invalid" />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop-2.jpeg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import authHeader from '@/services/auth-header';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

const axios = require('axios');

export default {
  name: 'check-register',
  mixins: [validationMixin],
  data: () => ({
    itemData: {
      name: null,
      postalCode: 28025,
      direction: null,
      postalCodeItem: null,
      distance: null,
    },
    modal: false,
    loading: false,
    originCoordinates: {
      latitude: -3.8752786669764077,
      longitude: 40.489787823760636,
    },

  }),
  mounted() {
    this.getItems();
  },
  components: {
    SectionHeader,
    PrimaryButton,
  },
  computed: {
    distance() {
      if (this.itemData.postalCodeItem) {
        const distance = this
          .getDistanceFromLatLonInKm(
            this.itemData.postalCodeItem.latitude,
            this.itemData.postalCodeItem.longitude,
            this.originCoordinates.latitude,
            this.originCoordinates.longitude,
          );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.itemData.distance = distance.toFixed(0);
        return distance.toFixed(0);
      }
      return null;
    },
    distanceText() {
      if (this.itemData.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }

      return null;
    },
  },
  watch: {
    // watch changes on itemData.assemblyType
    'itemData.postalCode': {
      handler() {
        this.checkRegister();
      },
      deep: true,
    },
  },
  validations: {
    // distance: {
    //   required,
    //   // should be less than 200
    //   lessThan(value) {
    //     return value < 200;
    //   },
    // },
    itemData: {
      name: {
        required,
      },
      // Add validation for postalCode (count 5 digits)
      // postalCode: {
      //   required,
      //   postalCode(value) {
      //     if (value) {
      //       return value.length === 5;
      //     }
      //     return true;
      //   },
      //   available(value) {
      //     if (value && value.length === 5) {
      //       // get postalCode in this.options
      //       try {
      //         const postalCode = this.options.find((item) => item.codigopostalid === value);
      //         this.itemData.postalCodeItem = postalCode;
      //         return !!(postalCode);
      //       } catch (error) {
      //         return false;
      //       }
      //     }
      //     return false;
      //   },
      // },
    },
  },
  methods: {
    nextStep() {
      this.checkRegister();
      this.$emit('nextStep', true);
    },
    backStep() {
      this.$emit('backStep', true);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    updateData() {
      this.$emit('updateData', this.itemData);
      this.updateValid();
    },
    updateValid() {
      this.$emit('updateValid', this.$v);
    },
    updatePostalCode(value) {
      this.itemData.postalCode = value;
    },
    checkRegister() {
      this.updateData();
    },
    // check availability
    getItems() {
      axios.get(
        `${process.env.VUE_APP_API_URL}postal-code?size=-1`,
        { headers: authHeader() },
      )
        .then((response) => {
          this.options = response.data.content;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}

.aside-container {
  padding: 0 !important;
}
.main-container {
  overflow-y: hidden;
  background-color: var(--bodyBg);

  &::after {
    position: absolute;
    content: 'Scroll para ver más';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    }
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
</style>
