<template>
  <div >

          <v-card outlined class="pb-3" :elevation="$getCardOptions('elevation')">
            <v-card-title>
              <span class="">Nuevo Código Postal</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" class="p-2">
                <!-- Add v-text-area for code -->
                <v-text-field v-model="formData.id" label="Código Postal" type="number"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="local_offer">
                </v-text-field>
                <!-- Add v-text-area for description -->
                <v-text-field v-model="formData.distance" label="Distancia" type="number"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="description">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large @click="newRegister">
                Guardar Código Postal
              </v-btn>
            </v-card-actions>
          </v-card>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'appointment-prop-register',
  data: () => ({
    mixins: [validationMixin],

    formData: {
      id: null,
      distance: null,
    },
    dialog: false,
    activeOptions: [{ text: 'Active', value: true }, { text: 'No Active', value: false }],
    loading: false,

  }),

  validations() {
    const valObj = {
      formData: {
        id: { required },
        distance: { required },
      },

    };
    return valObj;
  },
  methods: {
    redirectToPostalCodes() {
      this.$router.push({ name: 'PostalCodes' });
    },

    newRegister() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios.post(
        `${process.env.VUE_APP_API_URL}postal-code-distance`,
        // cast to number all the fields
        {
          distance: Number(this.formData.distance),
          id: Number(this.formData.id),
        },
        {
          headers: authHeader(),
        },
      )
        .then((response) => {
          console.log(response);
          this.redirectToPostalCodes();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>
