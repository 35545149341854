<template>
  <div>

          <v-card outlined class="pb-3" :elevation="$getCardOptions('elevation')">
            <v-card-title>
              <span class="">Nuevo elemento del configurador</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" class="form">
                <property-type-select propertyValue="appointmentPropsType" label="Tipo" icon="work"
                  @updateParent="updateType" :showHint="true">
                </property-type-select>
                <!-- <div v-if="!$v.formData.dType.required">Required field *</div> -->
                <v-text-field v-model="formData.name" label="Nombre"
                  :rules="[$v.formData.name.required]" :color="$getColor('vuetifyColor1')" outlined
                  prepend-inner-icon="title" hint="Campo obligatorio" persistent-hint>
                </v-text-field>
                <!-- <div v-if="!$v.formData.name.required">Required field *</div> -->
                <!-- Add text input for group -->
                <v-file-input show-size counter outlined :color="$getColor('vuetifyColor1')"
                label="Imagen" v-model="imageFile" prepend-inner-icon="image"
                prepend-icon="">
                </v-file-input>
                <!-- Add number input for price-->
                <v-text-field v-model="formData.price" label="Precio" type="number"
                  :rules="[$v.formData.price.required]" :color="$getColor('vuetifyColor1')" outlined
                  prepend-inner-icon="attach_money" hint="Campo obligatorio" persistent-hint>
                </v-text-field>
                <!-- <div v-if="!$v.formData.price.required">Required field *</div> -->
                <!-- Add number input for discount -->
                <v-text-field v-model="formData.discount" label="Descuento" type="number"
                  v-if="formData.dType === 'Extras' || formData.dType === 'Neon'"
                  :color="$getColor('vuetifyColor1')" outlined
                  prepend-inner-icon="attach_money">
                </v-text-field>
                <!-- Add v-select for size when dType is Plantilla -->
                <v-select v-model="formData.size" :items="sizes" label="Tamaño"
                  v-if="formData.dType === 'Plantilla'" :color="$getColor('vuetifyColor1')" outlined
                  prepend-inner-icon="format_size">
                </v-select>
                <!-- Add v-text-area for description -->
                <v-textarea v-model="formData.description" label="Descripción"
                  v-if="formData.dType === 'Extras'"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="description">
                </v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')" :elevation="0"
                     :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large @click="newRegister">
                Guardar nuevo elemento
              </v-btn>
            </v-card-actions>
          </v-card>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import authHeader from '@/services/auth-header';
import PropertyTypeSelect from '@/components/Forms/PropertiesSelect.vue';

const axios = require('axios');

export default {
  name: 'appointment-prop-register',
  data: () => ({
    mixins: [validationMixin],
    formData: {
      dType: null,
      name: null,
      description: null,
      imageUrl: null,
      price: null,
      discount: null,
      size: null,
    },
    imageFile: null,
    sizes: [{
      text: '5 x 15',
      value: '5x15',
    },
    {
      text: '10 x 15',
      value: '10x15',
    }],
  }),
  components: {
    PropertyTypeSelect,
  },
  validations() {
    const valObj = {
      formData: {
        dType: { required },
        name: { required },
        imageUrl: { required },
        price: { required },
        discount: {
          required,
        },
      },

    };
    return valObj;
  },
  methods: {
    newRegister() {
      const formData = new FormData();
      const data = {
        name: this.formData.name,
        description: this.formData.description,
        imageUrl: this.formData.imageUrl,
        price: this.formData.price,
        discount: this.formData.discount,
        type: this.formData.dType,
        size: this.formData.size,

      };
      const json = JSON.stringify(data);
      const blob = new Blob([json], {
        type: 'application/json',
      });

      formData.append('prop', blob);
      formData.append('image', this.imageFile);

      const config = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .post(
          `${process.env.VUE_APP_API_URL}appointment-prop/create`,
          formData,
          config,
        )
        .then(() => {
          this.$router.push({ name: 'Appointment Props' });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateType(val) {
      this.formData.dType = val;
    },

  },
};
</script>
<style lang="scss" scoped>
.form{
  display: flex;
  flex-direction: column;
  gap: calc(var(--base)*2);
}</style>
