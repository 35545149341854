<template>
  <div>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="450px">
      <template v-slot:activator="{ on, attrs }">
        <!--
          <v-btn :color="$getColor('vuetifyColor1')" v-if="!fabButton"
           v-bind="attrs" v-on="on" text>
            <v-icon left v-if="icon"> {{icon}} </v-icon>
            {{title}}
          </v-btn>
        <v-btn elevation="0" fab small outlined dark v-bind="attrs" v-on="on" v-if="fabButton">
            <v-icon :color="$getColor('vuetifyColor1')">chat</v-icon>
          </v-btn>
         -->
        <selection-button title="Añadir comentario" v-on="on" v-bind="attrs" enabled v-on:click.native="dialog = true" />
      </template>
      <v-card class="dialog-box">
        <v-card-title class="dialog-title" :color="$getColor('vuetifyColor1')">
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field outlined :color="$getColor('vuetifyColor1')" label="Usuario" v-model="adminUser" class="mb-2"
            disabled></v-text-field>
          <v-textarea outlined :color="$getColor('vuetifyColor1')" label="Comentario" hide-details
            placeholder="Añadir contenido del comentario..." v-model="description" required filled auto-grow rows="4"
            row-height="30" class="mb-2"></v-textarea>
        </v-card-text>
        <v-card-actions class="pb-5">
          <div class="navigation-buttons">
            <primary-button appearance="text" text="Cancelar" size="small"  @click.native="dialog = false"/>

            <primary-button appearance="solid" text="Enviar" size="small" @click.native="addComment()"/>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';

const axios = require('axios');

export default {
  name: 'AddChatComment',
  props: {
    fabButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    admin: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      name: null,
      description: null,
      date: new Date().toISOString().split('T')[0],
      dialog: false,
    };
  },
  methods: {
    addComment() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}chatComment/add`,
          {
            description: this.description,
            creationTime: this.date,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.$router.go();
        });
    },
  },
  computed: {
    adminUser() {
      return `${authService.getDecodedJwtToken(this.$store.state.auth.user).sub} ( ${this.date} )`;
    },
    affiliateFrom() {
      if (authService.getDecodedJwtToken(this.$store.state.auth.user).affiliate) {
        return authService.getDecodedJwtToken(this.$store.state.auth.user).affiliate;
      }
      return null;
    },
  },
  components: {
    SelectionButton,
    PrimaryButton,
  },
};
</script>

<style lang="scss" scoped>
.add_user-btn {
  max-width: 15em;
  margin-top: 0;
  margin-bottom: 1em;
}

.dialog-title {
  margin-bottom: 1em;

  span {
    color: var(--bodyColor);
  }
}

.dialog-wrapper {
  max-width: 250px;

  .v-dialog--active {
    max-width: 250px !important;
  }
}
.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}
</style>
