<template>
  <div >
    <v-card outlined class="pb-3" :elevation="$getCardOptions('elevation')">
      <v-card-title>
        <span class="">Nuevo cupón de descuento</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" class="p-2">
          <!-- Add v-text-area for code -->
          <v-text-field dense v-model="formData.code" label="Codigo" :color="$getColor('vuetifyColor1')" outlined
            prepend-inner-icon="local_offer">
          </v-text-field>
          <!-- Add v-text-area for description -->
          <v-text-field dense v-model="formData.description" label="Nombre" :color="$getColor('vuetifyColor1')" outlined
            prepend-inner-icon="description">
          </v-text-field>
          <!-- Add number input for discount -->
          <v-text-field dense v-model="formData.discount" label="Descuento" type="number" :color="$getColor('vuetifyColor1')"
            outlined prepend-inner-icon="attach_money">
          </v-text-field>
           <!-- Add number input for quantity -->
            <v-text-field dense v-model="formData.remainingUses" label="Cupones disponibles" type="number" :color="$getColor('vuetifyColor1')"
            outlined prepend-inner-icon="folder">
          </v-text-field>

          <!-- Add date picker with v-dialog for expirationTime -->
          <v-dialog v-model="dialog" width="450" ref="dialog">

            <template v-slot:activator="{ on }">
              <v-text-field dense v-model="formData.expirationTime" label="Expiración" :color="$getColor('vuetifyColor1')"
                outlined prepend-inner-icon="date_range" v-on="on">
              </v-text-field>
            </template>
            <v-card>
              <v-card-title class="dialog-title" :color="$getColor('vuetifyColor1')">
                <div class="field-group mb-0">
                  <label for="" class="field-group--label ">
                    Fecha de expiración
                  </label>
                </div>

              </v-card-title>
              <v-card-text>
            <v-date-picker :color="$getColor('vuetifyColor2')" locale="es" first-day-of-week="1"
              v-model="formData.expirationTime" scrollable>
              <v-spacer></v-spacer>

            </v-date-picker>
          </v-card-text>
          <v-card-actions>
            <div class="navigation-buttons">
              <primary-button appearance="text" text="Cancelar" size="small"  @click.native="dialog = false"/>
              <primary-button appearance="solid" text="Guardar" size="small"
                @click.native="$refs.dialog.save(formData.expirationTime)"/>
            </div>
          </v-card-actions>
          </v-card>
          </v-dialog>

          <!-- Add v-select for active/no active -->
          <v-select dense v-model="formData.active" :items="activeOptions" label="Estado" :color="$getColor('vuetifyColor1')"
            outlined prepend-inner-icon="check">
          </v-select>
           <!-- add a vuetify switch to onlyAdmin -->
           <v-switch v-model="formData.onlyAdmin" label="Solo para administradores" :color="$getColor('vuetifyColor1')">
          </v-switch>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
          :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" x-large @click="newRegister">
          Guardar cupón
        </v-btn>

      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';

import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'appointment-prop-register',
  components: {
    PrimaryButton,
  },
  data: () => ({
    mixins: [validationMixin],

    formData: {
      code: null,
      discount: null,
      remainingUses: null,
      description: null,
      expirationTime: null,
      active: null,
      onlyAdmin: null,
    },
    dialog: false,
    activeOptions: [{ text: 'Active', value: true }, { text: 'No Active', value: false }],

  }),

  validations() {
    const valObj = {
      formData: {
        discount: { required },
        description: { required },
        reaminingUses: { required },
        expirationTime: { required },
        active: { required },
        code: { required },
        onlyAdmin: { required },
      },

    };
    return valObj;
  },
  methods: {
    redirectToCoupons() {
      this.$router.push({ name: 'Coupons' });
    },
    newRegister() {
      axios.post(
        `${process.env.VUE_APP_API_URL}discount-coupon`,
        {
          expirationTime: new Date(this.formData.expirationTime).toLocaleString('en-GB', { timeZone: 'Europe/Madrid' }),
          discount: Number(this.formData.discount),
          description: this.formData.description,
          remainingUses: Number(this.formData.remainingUses),
          active: this.formData.active,
          code: this.formData.code,
          onlyAdmin: this.formData.onlyAdmin,
        },
        {
          headers: authHeader(),
        },
      )
        .then(() => {
          this.redirectToCoupons();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}</style>
