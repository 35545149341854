<template>
    <div class="field-group">
    <div :class="priceClass()">
          <div class="price-summary--item" v-if="basePrice">
              <span class="price-summary--concept">Base:</span>
              <span class="price-summary--description">{{ basePrice.description }}</span>
              <span class="price-summary--price">{{ basePrice.price }} €</span>
            </div>
            <div class="price-summary--item" v-if="extraPrice && extraPrice.price">
              <span class="price-summary--concept">Transporte:</span>
              <!-- <span class="price-summary--description">{{ extraPrice.description}}</span> -->
              <span class="price-summary--price">+ {{ extraPrice.price }} €</span>
            </div>
            <div class="price-summary--item" v-if="this.prices.coupon && this.prices.coupon.code">
              <span class="price-summary--concept">Cupón de descuento:</span>
              <span class="price-summary--description"> {{this.prices.coupon.code}} </span>
              <span class="price-summary--price">- {{this.prices.coupon.discount}} €</span>
            </div>
            <div class="price-summary--total" v-if="totalPrice">
              <span class="price-summary--concept">Total:</span>
              <span class="price-summary--description" v-if="isPaid">(-100€ de reserva)</span>
              <span class="price-summary--price">{{totalPrice}} €</span>
            </div>
            <div class="price-summary--item" v-if="!isPaid">
              <span class="price-summary--concept">Reserva fecha</span>
              <span class="price-summary--price">100€ (Se descontará del total)</span>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  name: 'Prices-Component-Basic',
  props: {
    eventDate: {
      type: String,
      default: '',
    },
    postalCodeItem: {
      type: Object,
      default: () => {},
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      originCoordinates: {
        latitude: -3.8752786669764077,
        longitude: 40.489787823760636,
      },
    };
  },
  methods: {
    updatePrices(data) {
      this.prices = data;
    },
    priceClass() {
      if (this.isMobile) {
        return 'price-summary--container sumatorio-group';
      }
      return 'price-summary--container';
    },
    isWeekend(date) {
      const calcDate = new Date(date);
      const day = calcDate.getDay();
      const month = calcDate.getMonth();
      return !!(day === 6 && month >= 4 && month <= 9);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
  },

  computed: {

    basePrice() {
      const basePrice = {
        description: null,
        price: null,
      };
      if (this.eventDate) {
        if (this.isWeekend(this.eventDate)) {
          basePrice.description = 'Temporada Alta';
          basePrice.price = 485;
        } else {
          basePrice.description = 'Temporada Baja';
          basePrice.price = 465;
        }
      }
      return basePrice;
    },

    extraPrice() {
      const base = 0.27;
      const extraPrice = {};
      if (this.distance) {
        if (this.distance < 50) {
          extraPrice.description = 'Distancia menor a 50km';
          extraPrice.price = 0;
        } else {
          extraPrice.price = (this.distance * base).toFixed(2);
          extraPrice.description = `${this.distance}km * 0.27€/km`;
        }
      }
      return extraPrice;
    },

    totalPrice() {
      let total = 0;
      if (this.basePrice.price) {
        total += parseFloat(this.basePrice.price);
      }
      if (this.extraPrice.price) {
        total += parseFloat(this.extraPrice.price);
      }
      if (this.isPaid) {
        // total = total - 100
        total -= 100;
      }
      return total.toFixed(2);
    },

    prices() {
      const prices = {};
      if (this.eventDate && this.postalCodeItem) {
        if (this.basePrice.price) {
          prices.basePrice = this.basePrice;
        }
        if (this.extraPrice.price) {
          prices.extraPrice = this.extraPrice;
        }
        if (this.user && this.user.appointment && this.user.appointment.photocall) {
          prices.photocall = this.user.appointment.photocall;
        }
        if (this.user && this.user.appointment && this.user.appointment.neon) {
          prices.neon = this.user.appointment.neon;
        }
        if (this.user && this.user.appointment && this.user.appointment.album) {
          prices.album = this.user.appointment.album;
        }
        if (this.user && this.user.appointment && this.user.appointment.plantilla) {
          prices.plantilla = this.user.appointment.plantilla;
        }
        if (this.user && this.user.appointment && this.user.appointment.extras && this.user.appointment.extras.length > 0) {
          prices.extras = this.user.appointment.extras;
        }
        if (this.user && this.user.appointment && this.user.appointment.extrasWithDiscount && this.user.appointment.extrasWithDiscount.length > 0) {
          prices.extrasWithDiscount = this.user.appointment.extrasWithDiscount;
        }
        if (this.user && this.user.appointment && this.user.appointment.discountCoupon) {
          prices.coupon = this.user.appointment.discountCoupon;
        }
        if (this.totalPrice) {
          prices.totalPrice = this.totalPrice;
        }
      }

      return prices;
    },
    distance() {
      if (this.postalCodeItem) {
        if (this.postalCodeItem.distance) {
          return this.postalCodeItem.distance;
        }
        const distance = this
          .getDistanceFromLatLonInKm(
            this.postalCodeItem.latitude,
            this.postalCodeItem.longitude,
            this.originCoordinates.latitude,
            this.originCoordinates.longitude,
          );
        return distance.toFixed(0);
      }
      return null;
    },
    distanceText() {
      if (this.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }
      return null;
    },
  },
};
</script>
