<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <nav class="stepNav" :class="activeStep">
    <div class="stepNav--line-progress">
      <span
        class="progress-step"
        :class="[
          activeStep === 1
            ? 'current'
            : activeStep === 2
            ? 'active'
            : activeStep === 3
            ? 'active'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
      ></span>
      <span
        class="progress-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? 'current'
            : activeStep === 3
            ? 'active'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
      ></span>
      <span
        class="progress-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? ''
            : activeStep === 3
            ? 'current'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
      ></span>
      <span
        class="progress-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? ''
            : activeStep === 3
            ? ''
            : activeStep === 4
            ? 'current'
            : '',
        ]"
      ></span>
    </div>
    <div class="stepNav--numbers">
      <span
        class="number-step"
        :class="[
          activeStep === 1
            ? 'current'
            : activeStep === 2
            ? 'active'
            : activeStep === 3
            ? 'active'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
        >1</span
      >
      <span
        class="number-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? 'current'
            : activeStep === 3
            ? 'active'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
        >2</span
      >
      <span
        class="number-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? ''
            : activeStep === 3
            ? 'current'
            : activeStep === 4
            ? 'active'
            : '',
        ]"
        >3</span
      >
      <span
        class="number-step"
        :class="[
          activeStep === 1
            ? ''
            : activeStep === 2
            ? ''
            : activeStep === 3
            ? ''
            : activeStep === 4
            ? 'current'
            : '',
        ]"
        >4</span
      >
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    activeStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.stepNav {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  margin-top: 12.5%;
  top:0;
  right: 0;
  width: 60%;
  // @media (min-width: 768px) {
  //   // top: calc(var(--base) * 16);
  //   width: 60%;
  // }
  @media (min-width: 1024px) {
    margin-top: 10%;
  }
  .stepNav--line-progress {
    width: 100%;
    position: relative;
    //background-color: rgb(var(--bodyColor));
    height: var(--base);
    border-radius: calc(var(--base) * 4);
    position: relative;
    margin-bottom: calc(var(--base) * 1.25);
    // overflow:hidden;
    .progress-step {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: var(--base);
      border-radius: calc(var(--base) * 4);
      display: block;
      width: 0%;
      background-color: rgb(var(--bodyColor));

      @media (min-width: 1024px) {
        &.current {
          background-color: rgb(var(--bodyBg)) !important;
          transform: translateX(-1px);
        }
      }
      &:nth-of-type(1) {
        width: 25%;
        z-index: 4;
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorGreen);
        }
      }
      &:nth-of-type(2) {
        width: 50%;
        z-index: 3;
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorPurple);
        }
      }
      &:nth-of-type(3) {
        width: 75%;
        z-index: 2;
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorPink);
        }
      }
      &:nth-of-type(4) {
        width: 100%;
        z-index: 1;
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorRed);
        }
      }
    }
  }
  .stepNav--numbers {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 9%;
    .number-step {
      font-size: calc(var(--base) * 1.75);
      display: block;
      border-radius: 5000px;
      background-color: #d9d9d9;
      width: calc(var(--base) * 2.75);
      height: calc(var(--base) * 2.75);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (min-width: 1024px) {
        &.current {
          background-color: rgb(var(--bodyBg)) !important;
          color: rgb(var(--bodyColor)) !important;
        }
      }

      &:nth-of-type(1) {
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorGreen);
        }
      }
      &:nth-of-type(2) {
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorPurple);
        }
      }
      &:nth-of-type(3) {
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorPink);
        }
      }
      &:nth-of-type(4) {
        transition: all ease-out 0.3s;
        &.active,
        &.current {
          background-color: var(--colorRed);
        }
      }
    }
  }
}
</style>
