<!-- Vue template view for accesibility -->
<template>
    <v-container class="sitemap-container">
        <!-- vuetify container for accesibility long text (texto en español) -->
        <v-row>
            <v-col cols="12" md="12">
                <h2>Sitemap</h2>
                <div class="link-list">
                    <router-link to="/login">Login</router-link>
                    <router-link to="/nueva-reserva">Nueva Reserva</router-link>
                    <router-link to="/accesibilidad">Accesibilidad</router-link>
                    <router-link to="/kit-digital">KitDigital</router-link>
                    <router-link to="/sitemap">Sitemap</router-link>
                </div>
                <router-link to="/login">Volver al login</router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'Sitemap-view',
};
</script>

<style scoped lang="scss">
h2{
    margin-bottom: 1rem;
}
.link-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}
</style>
