<template>
  <button class="selection-button" :class="{ enabled: enabled }">
    <div class="first-line">
      {{ title }}
    </div>
    <div v-if="subtitle" class="second-line">
      {{ subtitle }}
    </div>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },

};
</script>

<style lang="scss" scoped>
.selection-button {
  width: 100%;
  height: calc(var(--base) * 8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(var(--base) * 2);
  border-radius: 50000px;
  transition: all ease-out 0.3s;
  // margin-top: calc(var(--base) * 1);
  // margin-bottom: calc(var(--base) * 2);
  pointer-events: none;

  background-color: rgba(var(--bodyColor), 0.06);
  color: rgba(var(--bodyColor), 0.2);
  border: 1px solid rgba(var(--bodyColor), 0);

  .first-line {
    font-size: calc(var(--base) * 2.25);
  }
  .second-line {
    font-size: calc(var(--base) * 1.75);
    font-weight: 300;
    opacity: 0.6;
  }
  //States
  &.enabled {
    background-color: rgba(var(--bodyBg), 1);
    border: solid 2px rgba(var(--bodyColor), 0.1);
    color: rgba(var(--bodyColor), 1);
    pointer-events: all;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
      // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
      background-color: rgba(var(--bodyColor), 0.1);

      // color: rgba(var(--bodyColor), 0.2);
      // border: 1px solid rgba(var(--bodyColor), 0);
    }
    &.selected {
      background-color: rgba(var(--bodyColor), 1);
      color: rgba(var(--bodyBg), 1);
      &.customGreen {
        background-color: var(--colorGreen);
        color: rgba(var(--bodyColor), 1);
      }
      &.customPurple {
        background-color: var(--colorPurple);
        color: rgba(var(--bodyColor), 1);
      }
      &.customPink {
        background-color: var(--colorPink);
        color: rgba(var(--bodyColor), 1);
      }
      &.customRed {
        background-color: var(--colorRed);
        color: rgba(var(--bodyColor), 1);
      }
    }
  }
}
</style>
