<template>
  <header class="section-header">
    <div class="first-line">{{first}}</div>
    <h2 class="second-line">{{second}}</h2>
  </header>
</template>

<script>
export default {
  props: {
    first: {
      type: String,
      default: 'Title',
    },
    second: {
      type: String,
      default: 'Subtitle',
    },
  },

};
</script>

<style lang="scss" scoped>
//Functions

.section-header{
    width:100%;
    margin-top:10%;
    margin-bottom:calc(var(--base)*5);
    letter-spacing: -0.02em;
    @media (min-width: 768px) {
        margin-top:calc(var(--base)*15);
        margin-bottom:calc(var(--base)*6);
    }
    @media (min-width: 1024px) {
        margin-top:calc(var(--base)*8);
        margin-top: 10%;
        margin-bottom:calc(var(--base)*4);
        // margin-bottom: auto;
    }
    .first-line{
        font-size: calc(var(--base) * 2);
        line-height: calc(var(--base) * 3);
        font-weight: 300;
    }
    .second-line{
        margin:0;
        padding:0;
        font-size:calc(var(--base) * 3);
        line-height:calc(var(--base) * 4);
        font-weight: 500;
    }
}
</style>
