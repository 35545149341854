<template>
  <div>
    <!-- Add progress bar -->

    <div class="download mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on"
            @click="addNew()">
            <v-icon left>add</v-icon>
            Añadir cupón
          </v-btn>
        </template>
        <span>Crear nuevo cupón de descuento</span>
      </v-tooltip>
    </div>
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <!-- Add content -->
    <v-data-table :color="$getColor('vuetifyColor1')" :headers="headers" :items="items" :items-per-page="5"
      class="elevation-1">
      <template v-slot:[`item.active`]="{ item }">
        <v-btn v-if="item.active" icon @click="toggleActiveCoupon(item)">
          <v-icon color="teal accent-3">mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else icon @click="toggleActiveCoupon(item)">
        <v-icon  color="grey">mdi-close-circle-outline</v-icon>
      </v-btn>
      </template>
      <template v-slot:[`item.onlyAdmin`]="{ item }">
        <v-btn v-if="item.onlyAdmin" icon >
          <v-icon color="teal accent-3">mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else icon>
        <v-icon  color="grey">mdi-close-circle-outline</v-icon>
      </v-btn>
      </template>
      <template v-slot:[`item.expirationTime`]="{ item }">
       {{ new Date(item.expirationTime).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit'}) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'coupons-view',
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      count: 0,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
          width: '80px',
        },
        { text: 'Codigo', value: 'code' },
        { text: 'Descripción', value: 'description', width: '150px' },
        { text: 'Activo', value: 'active', width: '100px' },
        { text: 'Descuento', value: 'discount', width: '120px' },
        { text: 'Fecha de Expiración', value: 'expirationTime', width: '200px' },
        { text: 'Cupones restantes', value: 'remainingUses', width: '180px' },
        { text: 'Solo administradores', value: 'onlyAdmin', width: '180px' },
      ],
    };
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ENLACE']);
    if (!this.adminAvailable) {
      this.$router.push('/acceso-denegado');
    } else {
      this.tabMain = 0;
      this.getItems();
    }
  },
  methods: {
    addNew() {
      this.$router.push({ name: 'Create Coupon' });
    },
    getItems() {
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_API_URL}discount-coupon/all`,
        { headers: authHeader() },
      )
        .then((response) => {
          this.items = response.data.map((item) => ({
            ...item,
            // expirationTime: this.formatDate(item.expirationTime),
          }));
          this.count = response.data.totalElements;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    toggleActiveCoupon(val) {
      this.loading = true;
      axios.patch(
        `${process.env.VUE_APP_API_URL}discount-coupon/active/${val.code}`,
        { active: !val.active },
        { headers: authHeader() },
      )
        .then(() => {
          this.getItems();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatDate(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hours = (`0${date.getHours()}`).slice(-2);
      const minutes = (`0${date.getMinutes()}`).slice(-2);
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
  },
};
</script>
