<template>
  <div class="page-layout">
    <section class="main-container">
      <!--<sides-gradient color="customGrey"></sides-gradient> -->
      <div class="main-container--inner no-scrollbar">
        <!-- -->
        <section-header first="Aquí tienes" second="Tu reserva" />
        <div class="field-group chosen-config-group">
          <label for="" class="field-group--label"> Configuración elegida: </label>
          <div
            ref="swiperFinal"
            class="option-selector--swiper swiper"
            :class="{ loading: !this.swiperLoaded }"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper" v-if="user.appointment">
              <selection-card
                v-if="user.appointment.photocall"
                :name="user.appointment.photocall.name"
                class="swiper-slide customGreen"
                size="small"
                :price="user.appointment.photocall.price"
                :img="user.appointment.photocall.imageUrl"
                v-on:click.native="setValue(user.appointment.photocall.imageUrl)"
                selected
              />
              <selection-card
                v-if="user.appointment.neon"
                :name="user.appointment.neon.name"
                class="swiper-slide customGreen"
                size="small"
                :price="user.appointment.neon.price"
                :img="user.appointment.neon.imageUrl"
                v-on:click.native="setValue(user.appointment.neon.imageUrl)"
                selected
              />
              <selection-card
                v-if="user.appointment.album"
                :name="user.appointment.album.name"
                class="swiper-slide customPurple"
                size="small"
                :price="user.appointment.album.price"
                :img="user.appointment.album.imageUrl"
                v-on:click.native="setValue(user.appointment.album.imageUrl)"
                selected
              />
              <selection-card
                v-if="user.appointment.plantilla"
                :name="user.appointment.plantilla.name"
                class="swiper-slide customPink"
                size="small"
                :price="user.appointment.plantilla.price"
                :img="user.appointment.plantilla.imageUrl"
                v-on:click.native="setValue(user.appointment.plantilla.imageUrl)"
                selected
              />
              <selection-card
                v-for="item in user.appointment.extras"
                :key="item.id"
                :name="item.name"
                class="swiper-slide customRed"
                size="small"
                :price="item.price"
                :img="item.imageUrl"
                v-on:click.native="setValue(item.imageUrl)"
                selected
              />
              <selection-card
                v-for="item in user.appointment.extrasWithDiscount"
                :key="item.id"
                :name="item.name"
                class="swiper-slide customRed"
                size="small"
                :img="item.imageUrl"
                v-on:click.native="setValue(item.imageUrl)"
                selected
                :price="item.price"
                :priceWithDiscount="item.price - item.discount"
              />
            </div>
          </div>
        </div>
        <div class="field-group" v-if="user.appointment">
          <label for="" class="field-group--label"> Resumen: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Fecha:</span>
              <span class="data--value">{{ dateOfAppointment }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Horario:</span>
              <span class="data--value">{{ this.getTypeLabel(user.appointment.type) }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Lugar del evento:</span>
              <span class="data--value">{{ user.appointment.name }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Dirección de la finca:</span>
              <span class="data--value">{{ user.appointment.streetPlace }}</span>
            </div>
            <!-- <div class="data--item" v-if="user.appointment.postalCode">
              <span class="data--label">Código Postal:</span>
              <span class="data--value">{{ user.appointment.postalCode }}</span>
            </div> -->
          </div>
        </div>
        <div class="field-group" v-if="user.appointment && user.appointment.discountCoupon">
          <label for="" class="field-group--label"> Código de descuento: </label>
          <div class="discount-code--container">
            <v-text-field
              :placeholder="
                user.appointment.discountCoupon.code +
                ' (' +
                user.appointment.discountCoupon.discount +
                '€ de descuento)'
              "
              outlined
              dense
              color="black"
              class="discount-code--input"
              :disabled="true"
            ></v-text-field>
          </div>
        </div>
        <v-divider v-if="isMobile && this.prices"></v-divider>
        <div class="field-group" v-if="isMobile && this.prices">
          <label class="field-group--label" v-if="isMobile && this.prices">Presupuesto:</label>
          <prices-component
            v-if="isMobile && this.prices"
            :prices="prices"
            :isPaid="isPaid"
          ></prices-component>
        </div>
        <!-- Datos personales -->
        <div class="field-group mt-5 pt-5" v-if="isMobile && user.appointment">
          <label for="" class="field-group--label"> Datos Personales: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre:</span>
              <span class="data--value">{{ user.name }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Correo electrónico:</span>
              <span class="data--value">{{ user.email }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Teléfono:</span>
              <span class="data--value">{{ user.phone }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">DNI:</span>
              <span class="data--value">{{ user.idDocument }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Domicilio:</span>
              <span class="data--value">{{ user.appointment.direction }}</span>
            </div>
          </div>
        </div>
        <!-- Text neon personalizado -->
        <div
          class="selection-button--container field-group"
          v-if="user.appointment && checkCustomNeon"
        >
          <label class="selection-button--label field-group--label" for=""
            >Neón personalizado:</label
          >
          <div class="discount-code--container">
            <v-text-field
              :placeholder="user.appointment.neonPersonalizado || 'Sin configurar'"
              outlined
              dense
              color="black"
              class="discount-code--input"
              :disabled="
                user.appointment.neonPersonalizado !== null &&
                user.appointment.neonPersonalizado !== ''
              "
              hide-details
              v-model="neonCustomTemp"
            ></v-text-field>
            <primary-button
              v-if="
                user.appointment.neonPersonalizado == null ||
                user.appointment.neonPersonalizado == ''
              "
              appearance="outlined"
              text="Aplicar"
              size="small"
              class="discount-code--button"
              v-on:click.native="updateUserInfo()"
            />
          </div>
        </div>

        <!-- Image album personalizado -->
        <div
          class="selection-button--container field-group"
          v-if="checkImageAlbumUploaded === 'not loaded'"
        >
          <label class="selection-button--label field-group--label" for=""
            >Álbum personalizado:</label
          >
          <v-file-input
            show-size
            counter
            outlined
            :color="$getColor('vuetifyColor1')"
            label="Adjuntar imagen"
            v-model="imageFileAlbum"
            hint="Tamaño mínimo 1100*1100px"
            persistent-hint
            :dense="true"
          >
          </v-file-input>
          <primary-button
            v-if="imageFileAlbum"
            appearance="outlined"
            text="Subir Imagen"
            size="small"
            v-on:click.native="uploadImageAlbum"
            enabled
          />
        </div>
        <div
          class="selection-button--container field-group"
          v-if="checkImageAlbumUploaded === 'loaded'"
        >
          <label class="selection-button--label field-group--label" for=""
            >Álbum personalizado:</label
          >
          <selection-button
            title="Descargar imagen"
            v-on:click.native="downloadAlbumImage"
            enabled
          />
        </div>
        <!-- Image plantilla personalizada -->
        <div
          class="selection-button--container field-group"
          v-if="checkImagePlantillaUploaded === 'not loaded'"
        >
          <label class="selection-button--label field-group--label" for=""
            >Plantilla personalizada:</label
          >
          <v-file-input
            show-size
            counter
            outlined
            :color="$getColor('vuetifyColor1')"
            label="Adjuntar imagen"
            v-model="imageFilePlantilla"
            hint="Tamaño mínimo 500*500px"
            persistent-hint
            :dense="true"
          >
          </v-file-input>
          <primary-button
            v-if="imageFilePlantilla"
            appearance="outlined"
            text="Subir Imagen"
            v-on:click.native="uploadImagePlantilla"
            enabled
          />
        </div>
        <div
          class="selection-button--container field-group"
          v-if="checkImagePlantillaUploaded === 'loaded'"
        >
          <label class="selection-button--label field-group--label" for=""
            >Plantilla personalizada:</label
          >
          <selection-button
            title="Descargar imagen"
            v-on:click.native="downloadPlantillaImage"
            enabled
          />
        </div>
        <v-divider></v-divider>

        <div class="field-group" v-if="user.appointment">
          <label for="" class="field-group--label"> Datos del evento: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre de la pareja:</span>
              <span class="data--value">{{ user.appointment.namePartners }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Apellido de la novia:</span>
              <span class="data--value">{{ user.lastName1 }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Lugar del evento:</span>
              <span class="data--value">{{ user.appointment.name }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Dirección de la finca:</span>
              <span class="data--value">{{ user.appointment.streetPlace }}</span>
            </div>

            <div class="data--item">
              <span class="data--label">Teléfono de contacto el día de la boda:</span>
              <span class="data--value">{{ user.appointment.phone }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Número aproximado de invitados:</span>
              <span class="data--value">{{ user.appointment.guestsNumber }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Hora aproximada:</span>
              <span class="data--value">{{ user.appointment.time }}</span>
            </div>
          </div>
        </div>

        <!-- Start update time -->
        <div class="field-group" v-if="user.appointment && !user.appointment.time">
          <v-divider></v-divider>
          <label for="" class="field-group--label"> Hora aproximada: </label>
          <div class="discount-code--container">
            <v-text-field
              v-if="!user.appointment.time"
              v-model="newTime"
              placeholder="Hora de comienzo"
              outlined
              dense
              color="black"
              class="discount-code--input"
              hide-details
            ></v-text-field>
            <primary-button
              v-if="!user.appointment.time"
              appearance="outlined"
              text="Enviar"
              size="small"
              class="discount-code--button"
              @click.native="updateUserInfo()"
              :enabled="true"
            />
          </div>
        </div>
        <!-- End update time -->
        <v-divider></v-divider>
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for="">Añade más extras:</label>
          <div class="field-grid">
            <selection-card
              v-for="item in optionsExtras"
              :key="item.id"
              :name="item.text"
              size="small"
              :price="item.price"
              :priceWithDiscount="item.priceWithDiscount"
              :img="item.img"
              v-on:click.native="setExtra(item)"
              :selected="checkExtra(item)"
            />
          </div>
          <selection-button
            title="Añadir extras"
            v-on:click.native="addNewExtras()"
            :enabled="checkNewExtras"
            class="mt-4"
          />
        </div>
        <v-divider></v-divider>
        <!-- Comentarios -->
        <div class="selection-button--container field-group mb-12">
          <label class="selection-button--label field-group--label" for="">Comentarios:</label>
          <div class="pt-2 pb-2" v-if="comments">
            <v-list-item v-for="message in comments" :key="message.id">
              <v-list-item-content class="left-message" v-if="user.email != message.from">
                <v-list-item-subtitle> FUNPIC </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ message.creationTime }}
                </v-list-item-subtitle>
                <p class="pt-2">
                  {{ message.description }}
                </p>
              </v-list-item-content>
              <v-list-item-content class="right-message" v-else>
                <v-list-item-subtitle>
                  {{ message.from }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption">
                  {{ message.creationTime }}
                </v-list-item-subtitle>
                <p class="pt-2">
                  {{ message.description }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </div>
          <add-user-comment title="Añadir comentario" icon="chat" :fabButton="false">
          </add-user-comment>
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="field-group" v-if="user.appointment">
        <label for="" class="field-group--label"> Datos Personales: </label>
        <div class="data--container">
          <div class="data--item">
            <span class="data--label">Nombre:</span>
            <span class="data--value">{{ user.name }}</span>
          </div>
          <div class="data--item">
            <span class="data--label">Correo electrónico:</span>
            <span class="data--value">{{ user.email }}</span>
          </div>
          <div class="data--item">
            <span class="data--label">Teléfono:</span>
            <span class="data--value">{{ user.phone }}</span>
          </div>
          <div class="data--item">
            <span class="data--label">DNI:</span>
            <span class="data--value">{{ user.idDocument }}</span>
          </div>
          <div class="data--item">
            <span class="data--label">Domicilio:</span>
            <span class="data--value">{{ user.appointment.direction }}</span>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="field-group mb-0">
          <label for="" class="field-group--label mb-2"> Presupuesto: </label>

        <prices-component
          v-if="this.prices"
          :prices="prices"
          :isPaid="isPaid"
          class="mt-2"
        ></prices-component>
        <div class="flex-div">
            <label for=""> Estado del pago: </label>
            <v-chip
              v-if="user.appointment"
              :color="$getAppointmentPaidStatusValues(user.appointment.paidStatus).color"
              :dark="$getAppointmentPaidStatusValues(user.appointment.paidStatus).dark"
              style="margin-left: auto"
            >
              {{ $getAppointmentPaidStatusValues(user.appointment.paidStatus).label }}
            </v-chip>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

import AddUserComment from '@/components/Forms/AddUserComment.vue';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
import SelectionButton from '@/components/Buttons/SelectionButton.vue';
// import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
// import SidesGradient from '@/components/Decorations/SidesGradient.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';
import PricesComponent from '@/components/Cards/Prices.vue';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';

import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const axios = require('axios');

export default {
  name: 'Panel-Usuario',
  props: {
    cardUserImage: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/logo.png'),
    },
  },
  components: {
    AddUserComment,
    SectionHeader,
    // PreviewCanvas,
    // SidesGradient,
    SelectionButton,
    SelectionCard,
    PricesComponent,
    PrimaryButton,
  },
  data() {
    return {
      adminAvailable: false,
      superAdminAvailable: false,
      totalRecords: null,
      totalComments: null,
      totalAttendances: null,
      userId: null,
      user: [],
      userInfo: {
        namePartners: null,
        lastName1: null,
        namePlace: null,
        streetPlace: null,
        phone2: null,
        guestsNumber: null,
        time: null,
      },
      records: [],
      comments: [],
      attendances: [],
      userActivityId: null,

      // new data
      tab: 0,
      tab2: 0,
      loading: false,
      disabled: true,
      imageFileAlbum: null,
      imageFilePlantilla: null,

      previewImg: null,
      swiperLoaded: false,
      postalCodeItem: null,
      originCoordinates: {
        latitude: -3.8752786669764077,
        longitude: 40.489787823760636,
      },
      optionsExtras: null,
      newExtras: [],
      isPaid: true,
      newTime: null,
      neonCustomTemp: null,
    };
  },
  computed: {
    checkNewExtras() {
      return this.newExtras.length > 0;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    checkImageAlbumUploaded() {
      if (!this.user.appointment) {
        return null;
      }
      if (this.user.appointment.album && this.user.appointment.album.name === 'Personalizado') {
        if (this.user.appointment.customImageAlbumName) {
          return 'loaded';
        }
        return 'not loaded';
      }
      return null;
    },
    checkImagePlantillaUploaded() {
      if (!this.user.appointment) {
        return null;
      }
      if (
        this.user.appointment.plantilla
        && this.user.appointment.plantilla.name === 'Personalizado'
      ) {
        if (this.user.appointment.customImagePlantillaName) {
          return 'loaded';
        }
        return 'not loaded';
      }
      return null;
    },
    checkCustomNeon() {
      if (!this.user.appointment) {
        return null;
      }
      return !!(this.user.appointment.neon && this.user.appointment.neon.name === 'Personalizado');
    },
    checkTime() {
      if (!this.user.appointment) {
        return null;
      }
      return this.user.appointment.time;
    },
    basePrice() {
      const basePrice = {
        description: null,
        price: null,
      };
      if (this.user.appointment) {
        if (this.isWeekend(this.user.appointment.eventDate)) {
          basePrice.description = 'Temporada Alta';
          basePrice.price = 485;
        } else {
          basePrice.description = 'Temporada Baja';
          basePrice.price = 465;
        }
      }
      return basePrice;
    },

    extraPrice() {
      const base = 0.27;
      const extraPrice = {};
      if (this.distance) {
        if (this.distance < 50) {
          extraPrice.description = 'Distancia menor a 50km';
          extraPrice.price = 0;
        } else {
          extraPrice.price = (this.distance * base).toFixed(2);
          extraPrice.description = `${this.distance}km * 0.27€/km`;
        }
      }
      return extraPrice;
    },

    totalPrice() {
      let total = 0;
      if (this.basePrice.price) {
        total += parseFloat(this.basePrice.price);
      }
      if (this.extraPrice.price) {
        total += parseFloat(this.extraPrice.price);
      }
      if (this.user.appointment.photocall) {
        total += parseFloat(this.user.appointment.photocall.price);
      }
      if (this.user.appointment.neon) {
        total += parseFloat(this.user.appointment.neon.price);
      }
      if (this.user.appointment.album) {
        total += parseFloat(this.user.appointment.album.price);
      }
      if (this.user.appointment.plantilla) {
        total += parseFloat(this.user.appointment.plantilla.price);
      }
      if (this.user.appointment.extras && this.user.appointment.extras.length > 0) {
        this.user.appointment.extras.forEach((extra) => {
          total += parseFloat(extra.price);
        });
      }
      if (
        this.user.appointment.extrasWithDiscount
        && this.user.appointment.extrasWithDiscount.length > 0
      ) {
        this.user.appointment.extrasWithDiscount.forEach((extra) => {
          total += parseFloat(extra.price) - extra.discount;
        });
      }
      if (this.user.appointment.discountCoupon) {
        total -= parseFloat(this.user.appointment.discountCoupon.discount);
      }
      if (this.isPaid) {
        // total = total - 100
        total -= 100;
      }
      return total.toFixed(2);
    },

    prices() {
      const prices = {};
      if (this.user.appointment) {
        if (this.basePrice.price) {
          prices.basePrice = this.basePrice;
        }
        if (this.extraPrice.price) {
          prices.extraPrice = this.extraPrice;
        }
        if (this.user.appointment.photocall) {
          prices.photocall = this.user.appointment.photocall;
        }
        if (this.user.appointment.neon) {
          prices.neon = this.user.appointment.neon;
        }
        if (this.user.appointment.neonPersonalizado) {
          // Añadimos el texto personalizado al nombre del neón
          prices.neonPersonalizado = this.user.appointment.neonPersonalizado;
        }
        if (this.user.appointment.album) {
          prices.album = this.user.appointment.album;
        }
        if (this.user.appointment.plantilla) {
          prices.plantilla = this.user.appointment.plantilla;
        }
        if (this.user.appointment.extras && this.user.appointment.extras.length > 0) {
          prices.extras = this.user.appointment.extras;
        }
        if (
          this.user.appointment.extrasWithDiscount
          && this.user.appointment.extrasWithDiscount.length > 0
        ) {
          prices.extrasWithDiscount = this.user.appointment.extrasWithDiscount;
        }
        if (this.user.appointment.discountCoupon) {
          prices.coupon = this.user.appointment.discountCoupon;
        }
        if (this.totalPrice) {
          prices.totalPrice = this.totalPrice;
        }
        this.updatePriceForStats(this.isPaid ? parseFloat(this.totalPrice) + 100 : this.totalPrice);
      }

      return prices;
    },
    dateOfInscription() {
      if (this.user && this.user.creationTime) {
        return new Date(this.user.creationTime).toLocaleDateString();
      }
      return 'No disponible';
    },
    dateOfLastUpdate() {
      if (this.user && this.user.lastUpdateTime) {
        return new Date(this.user.lastUpdateTime).toLocaleDateString();
      }
      return 'No disponible';
    },
    dateOfAppointment() {
      if (this.user && this.user.appointment) {
        return new Date(this.user.appointment.eventDate).toLocaleDateString();
      }
      return 'No disponible';
    },
    userChar() {
      if (this.user.name) {
        return (
          this.user.name.substring(1, 0).toUpperCase()
          + this.user.lastName1.substring(1, 0).toUpperCase()
        );
      }
      return 'X';
    },
    distance() {
      if (this.postalCodeItem) {
        if (this.postalCodeItem.distance) {
          return this.postalCodeItem.distance;
        }
        const distance = this.getDistanceFromLatLonInKm(
          this.postalCodeItem.latitude,
          this.postalCodeItem.longitude,
          this.originCoordinates.latitude,
          this.originCoordinates.longitude,
        );
        return distance.toFixed(0);
      }
      return null;
    },
    distanceText() {
      if (this.postalCodeItem) {
        return `Distancia: ${this.distance} km`;
      }
      return null;
    },
  },
  watch: {
    // watch immagePreview
    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiperFinal();
      }
    },
  },
  mounted() {
    if (authService.getDecodedJwtToken(this.$store.state.auth.user).affiliate) {
      this.$store.dispatch('affiliates/getLoggedAffiliate').then(() => {
        if (this.$store.state.affiliates.loggedAffiliate.fastRegister) {
          this.$router.push({ name: 'UserPanelFastRegister' });
        }
        this.loading = true;
        this.getAffiliate();
      });
    } else {
      this.$router.push({ name: 'Not Allowed' });
    }
  },
  methods: {
    updateNeonText() {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/neon-custom-text/${this.user.appointment.id}`,
          { neonPersonalizado: this.neonCustomTemp },
          { headers: authHeader() },
        )
        .then(() => {
          // Manejar la respuesta si es necesario
          // console.log(response);
          this.getAffiliate();
        })
        .catch((error) => {
          console.error('Error al actualizar el texto del neón:', error);
        });
    },
    setValue(value) {
      this.previewImg = value;
    },
    isWeekend(date) {
      const calcDate = new Date(date);
      const day = calcDate.getDay();
      const month = calcDate.getMonth();
      return !!(day === 6 && month >= 4 && month <= 9);
    },
    initSwiperFinal() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiperFinal, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        pagination: {
          el: '.swiper-pagination',
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Inicio antes de las 19:15)';
        case 'evening':
          return 'Boda de noche (Inicio después de 23:29)';
        default:
          return 'No disponible';
      }
    },
    downloadAlbumImage() {
      const url = `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/get-custom-image-album`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: authHeader(),
      }).then((response) => {
        const newurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = newurl;
        link.setAttribute(
          'download',
          `${this.user.appointment.namePartners}_${this.user.appointment.customImageAlbumName}`,
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    downloadPlantillaImage() {
      const url = `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/get-custom-image-plantilla`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: authHeader(),
      }).then((response) => {
        const newurl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = newurl;
        link.setAttribute(
          'download',
          `${this.user.appointment.namePartners}_${this.user.appointment.customImagePlantillaName}`,
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    uploadImageAlbum() {
      const formData = new FormData();
      formData.append('file', this.imageFileAlbum);
      const header = authHeader();
      header['Content-Type'] = 'multipart/form-data';

      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/upload-custom-image-album`,
          formData,
          { headers: header },
        )
        .then(() => {
          this.getAffiliate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadImagePlantilla() {
      const formData = new FormData();
      formData.append('file', this.imageFilePlantilla);
      const header = authHeader();
      header['Content-Type'] = 'multipart/form-data';

      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/upload-custom-image-plantilla`,
          formData,
          { headers: header },
        )
        .then(() => {
          this.getAffiliate();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAffiliate() {
      this.$store
        .dispatch('affiliates/getLoggedAffiliate')
        .then(() => {
          this.user = this.$store.state.affiliates.loggedAffiliate;
          this.userInfo = {
            namePartners: this.user.appointment.namePartners,
            lastName1: this.user.lastName1,
            namePlace: this.user.appointment.namePlace,
            streetPlace: this.user.appointment.streetPlace,
            phone2: this.user.appointment.phone,
            guestsNumber: this.user.appointment.guestsNumber,
            time: this.user.appointment.time,
          };
          if (this.user.manualRegister) {
            this.isPaid = false;
          }
          this.getPostalCode(this.user.appointment.postalCode);
          this.getComments();
          this.getExtrasItems();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    getComments() {
      axios
        .get(`${process.env.VUE_APP_API_URL}chatComment/get`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.comments = response.data.content.map((ele) => ({
            id: ele.id,
            admin: ele.admin,
            to: ele.emailTo,
            from: ele.emailFrom,
            // format date with time
            creationTime: new Date(ele.creationTime).toLocaleString(),
            description: ele.description,
            deleteHide: true,
          }));
          this.totalComments = this.comments.length;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPostalCode(id) {
      const idInt = parseInt(id, 10);
      axios
        .get(`${process.env.VUE_APP_API_URL}postal-code-distance/${idInt}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.postalCodeItem = response.data;
          this.postalCodeItem = {
            postalCodeId: id,
            distance: response.data,
          };
        })
        .catch((error) => {
          console.log(error);
          this.getPostalCodeSource(id);
        });
    },
    getPostalCodeSource(id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}postal-code/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.postalCodeItem = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(this.deg2rad(lat1))
          * Math.cos(this.deg2rad(lat2))
          * Math.sin(dLon / 2)
          * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    getExtrasItems() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Extras',
          },
        })
        .then((response) => this.setOptions(response.data.content));
    },
    setOptions(response) {
      // exclude the options that are already selected
      const options1 = response.filter(
        (option) => !this.user.appointment.extras.some((prop) => prop.id === option.id),
      );
      // exclude the options that are already selected in user.appointment.extras OR user.appointment.extrasWithDiscount
      const options = options1.filter(
        (option) => !this.user.appointment.extrasWithDiscount.some((prop) => prop.id === option.id),
      );
      // map response to options
      this.optionsExtras = options.map((item) => ({
        text: item.name,
        value: item.id,
        img: item.imageUrl,
        id: item.id,
        price: item.price,
        priceWithDiscount: item.price - item.discount,
      }));
    },
    setExtra(item) {
      if (this.newExtras.includes(item)) {
        this.newExtras = this.newExtras.filter((i) => i !== item);
      } else {
        this.newExtras.push(item);
      }
    },
    checkExtra(item) {
      return !!(this.newExtras && this.newExtras.includes(item));
    },
    addNewExtras() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/add-extras-with-discount`,
          // transform newExtras to array of ids and push
          this.newExtras.map((item) => item.id),
          { headers: authHeader() },
        )
        .then(() => {
          this.getAffiliate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUserInfo() {
      this.user.appointment.neonPersonalizado = this.neonCustomTemp;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}assemblyUnique/${this.user.appointment.id}/add-user-info`,
          {
            time: this.newTime,
            neonPersonalizado: this.user.appointment.neonPersonalizado,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.getAffiliate();
          // console.log('updateUserInfo');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatePriceForStats(price) {
      axios
        .patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/change-price-for-stats/${this.user.appointment.id}`,
          {
            priceForStats: parseFloat(price),
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {});
    },
  },
};
</script>
<style scoped>
.preview-canvas {
  max-height: 40%;
  margin-bottom: calc(var(--base));
}

::v-deep .v-treeview-node__root {
  cursor: pointer;
}

.left-message {
  text-align: left;
  background-color: var(--colorPurple);
  border-radius: 10px;
  border-left: 3px solid #9e9e9e;
  /* border left without radius */
  border-top-left-radius: 0;
  padding: 10px;
  margin: 10px;
  width: 60%;
  margin-right: calc(var(--base) * 6);
  margin-left: 0;
}

.right-message {
  text-align: right;
  background-color: var(--colorGreen);
  border-radius: 10px;
  border-right: 3px solid #4caf50;
  /* border right without radius */
  border-top-right-radius: 0;
  padding: 10px;
  margin: 10px;
  width: 60%;
  margin-right: 0;
  margin-left: calc(var(--base) * 6);
}

.user-message {
  text-align: left;
  margin-left: 0;
}
</style>
<style lang="scss" scoped>
.aside-container {
  padding-bottom: 0;
}

.field-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--base) * 2);

  & > * {
    max-width: 40%;
  }
}

.main-container {
  overflow-y: hidden;
  background: var(--bodyBg);

  &::after {
    position: absolute;
    content: "Scroll para ver más";
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);
  }

  .main-container--inner {
    overflow-y: auto;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}
.flex-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
</style>
