<template>
  <div>
    <div class="users_panel-filters">
          <div class="filters-text" style="white-space: nowrap;">Filtrar por:</div>
          <v-text-field
            v-model="filter.username"
            label="Usuario"
            :color="$getColor('vuetifyColor1')"
            placeholder="email"
            outlined
            dense
            hide-details
          ></v-text-field>
          <v-select
            v-model="filter.role"
            :items="RoleOptions"
            outlined
            label="Rol"
            :color="$getColor('vuetifyColor1')"
            dense
            hide-details

          ></v-select>
          <v-select
            v-model="filter.action"
            :items="acciones"
            outlined
            label="Acción"
            :color="$getColor('vuetifyColor1')"
            dense
            hide-details
          ></v-select>
          <v-btn text color="rgba(0,0,0,0.6)" @click="resetFilter()">
            <v-icon class="mr-2"> mdi-close</v-icon>Borrar Filtros</v-btn
          >
        </div>
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" indeterminate>
    </v-progress-linear>
    <log-standard-table
    :username="filter.username"
    :role="filter.role"
    :action="filter.action"
    >
    </log-standard-table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import authHeader from '@/services/auth-header';
import LogStandardTable from '../../components/Tables/Activity/LogStandardTable.vue';

export default {
  name: 'activity-data',
  data() {
    return {
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 10,
      pageSizes: [5, 10, 20],

      filter: {
        username: null,
        role: null,
        action: null,
      },
      loading: false,
      exportItems: null,
      RoleOptions: [
        { value: 0, text: 'ROLE_USER' },
        { value: 4, text: 'ROLE_SUPER_ADMIN' },
      ],
      acciones: [
        'Etiquetado de noticia',
        'Guardado de noticia como favorita',
        'Eliminado de noticia como favorita',
        'Consulta de noticias',
        'Consulta de estadísticas',
        'Login',
        'Búsqueda de usuario',
        'Búsqueda filtrada de usuarios',
        'Consulta de estadísticas de roles',
        'Consulta de estadísticas filtrada',
        'Consulta filtrada de actividad',
        'Consulta de Projects',
      ],
    };
  },
  methods: {
    updateLog(val) {
      this.filter = val;
    },
    resetFilter() {
      this.filter.username = null;
      this.filter.role = null;
      this.filter.action = null;
    },
    downloadClick(extension) {
      if (extension) {
        this.loading = true;
        this.getItems(extension);
      }
    },
    download(extension) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.exportItems);
      XLSX.utils.book_append_sheet(wb, ws, 'Actividad');

      const date = new Date();
      const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const fileName = `Actividad_${dateString}.${extension}`;
      XLSX.writeFile(wb, fileName);
      this.loading = false;
    },
    getRequestParams() {
      const params = {};
      params.username = this.filter.username;
      params.role = this.filter.role;
      params.action = this.filter.action;
      params.size = -1;
      params.page = 0;

      return params;
    },
    getItems(extension) {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}log`, { headers: authHeader(), params: this.getRequestParams() })
        .then((response) => {
          this.items = response.data.content;
          this.exportItems = this.items.map((item) => {
            const outItem = {};
            outItem.id = item.id;
            outItem.username = item.userModel.username;
            outItem.role = item.userModel.role;
            outItem.action = item.action;
            outItem.creationDate = item.creationDate;
            return outItem;
          });

          this.count = response.data.totalElements;
          this.download(extension);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    // HeaderFilter,
    LogStandardTable,
  },
};
</script>
<style lang="scss" scoped>
.users_panel-filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  .filters-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--bodyColor);
    margin-right: 1em;
  }
}
</style>
