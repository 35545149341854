<template>
        <div class="md-layout m-2 mt-4 mb-5">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <!-- Add v-text field for user.password -->
            <v-text-field
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              :color="$getColor('vuetifyColor1')"
              outlined
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </div>
        </div>
</template>
<script>

export default {
  name: 'user-data-register',
  data: () => ({
    user: {
      name: null,
      password: null,
    },
    showPassword: false,
  }),
  created() {
    this.updateUserData();
  },
  methods: {
    updateUserData() {
      this.$emit('updateUser', this.user);
    },
  },
};
</script>
