<template>
  <div>
    <!-- Add progress bar -->
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <div class="download mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on"
            @click="addNew()">
            <v-icon left>add</v-icon>
            Añadir C.Postal
          </v-btn>
        </template>
        <span>Añadir código postal y distancia</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isEdition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de" v-bind="attrs" v-on="on" @click="setEdition()">
            <v-icon left>edit</v-icon>
            Editar
          </v-btn>
        </template>
        <span>Pincha para eliminar códigos postales</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :elevation="0" :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
            color="#d9f1de" v-bind="attrs" v-on="on" @click="setEdition()">
            <v-icon left>clear</v-icon>
            Cancelar
          </v-btn>
        </template>
        <span>Pincha para salir de la edición</span>
      </v-tooltip>
    </div>
    <!-- Add content -->
    <v-data-table :color="$getColor('vuetifyColor1')" :headers="headers" :items="items" :items-per-page="-1"
      class="elevation-1">
      <template v-slot:[`item.distance`]="{ item }">
        <span>{{ item.distance }} km</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- Add edit button -->
        <v-tooltip bottom v-if="isEdition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab elevation="0" small @click="deleteItem(item.id)" v-bind="attrs" v-on="on">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar código postal</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'postal-codes-view',
  data() {
    return {
      loading: false, // loading state for progress bar
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      isEdition: false,
      items: [], // data for v-data-table
      count: 0, // total count of data
      headers: [ // headers for v-data-table
        { text: 'Código Postal', value: 'id' },
        { text: 'Distancia', value: 'distance' },
        { text: 'Acciones', value: 'actions', sortable: false },

      ],
    };
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']); // check if user has super admin role
    if (!this.adminAvailable) { // if user doesn't have super admin role, redirect to access denied page
      this.$router.push('/acceso-denegado');
    } else {
      this.getItems(); // load data
    }
  },

  methods: {
    setEdition() {
      this.isEdition = !this.isEdition;
    },
    getItems() {
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_API_URL}postal-code-distance/all`,
        { headers: authHeader() },
      )
        .then((response) => {
          console.log(response);
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteItem(id) {
      this.loading = true;
      axios.delete(
        `${process.env.VUE_APP_API_URL}postal-code-distance/${id}`,
        { headers: authHeader() },
      )
        .then((response) => {
          console.log(response);
          this.getItems();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addNew() {
      // push by component name
      this.$router.push({ name: 'PostalCodeRegister' });
    },
  },

};
</script>
