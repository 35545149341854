<template>
  <section class="preview-canvas" :style="previewImgStyle">
    <span class="corner left top"><svg width="15" height="15" viewBox="0 0 15 15" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V1H14" stroke="black" stroke-width="2" stroke-linecap="round" />
      </svg>
    </span>
    <span class="corner right top">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.999999L14 1L14 14" stroke="black" stroke-width="2" stroke-linecap="round" />
      </svg>
    </span>
    <span class="corner right bottom">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 1L14 14L1 14" stroke="black" stroke-width="2" stroke-linecap="round" />
      </svg>
    </span>
    <span class="corner left bottom">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 14L1 14L1 1" stroke="black" stroke-width="2" stroke-linecap="round" />
      </svg>
    </span>
    <div class="preview-text" v-show="!previewImg && !overlayImg">
      Vista Previa<br />Elige una opción para previsualizarla
    </div>
    <div class="overlay-img" v-show="overlayImg">
      <img :src="overlayImg" alt="vista previa">
    </div>

  </section>
</template>

<script>
export default {
  props: {
    previewImg: {
      type: String,
      default: null,
    },
    overlayImg: {
      type: String,
      default: null,
    },
  },
  computed: {
    previewImgStyle() {
      if (this.previewImg) {
        return {
          backgroundImage: `url(${this.previewImg})`,
        };
      }
      return null;
    },
  },

};
</script>

<style lang="scss" scoped>
.preview-canvas {
  // height:calc(var(--base) * 20);
  // width: calc(100% - 2 * var(--base));
  // left: calc(var(--base));
  // aspect-ratio: 3/2;
  height: 100%;
  background: rgba(var(--bodyBg), 1);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(var(--base) * 2);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
  min-height: auto;
  height: 100%;

  @media (min-width: 1024px) {
    // position: absolute;
    max-height: 50%;
    width: 100%;
    // aspect-ratio: 16/9;
    margin-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .preview-text {
    font-size: calc(var(--base) * 1.75);
    line-height: calc(var(--base) * 2);
    color: rgba(var(--bodyBg), 1);
    mix-blend-mode: difference;
    text-align: center;
    font-weight: 300;
  }

  .overlay-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    z-index: 2;
  }

  .corner {
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;

    &.left {
      left: calc(var(--base) * -1);
    }

    &.right {
      right: calc(var(--base) * -1);
    }

    &.top {
      top: calc(var(--base) * -1);
    }

    &.bottom {
      bottom: calc(var(--base) * -1);
    }
  }
}
</style>
