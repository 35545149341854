<template>
  <v-dialog v-model="modal" class="dialog-wrapper" width="75%" scrollable max-width="450px">

    <v-card>
      <v-card-title>
        <span class="text-h5 mb-2">Editar configuración</span></v-card-title>
      <!-- <section-header first="Editar" second="Los datos del evento" /> -->
      <v-card-text style="height: 400px;">
        <v-form action="">
          <div class="input-field">
            <label class="input-field--label">Fondo photocall: </label>
            <v-select placeholder="Seleccione un fondo" outlined dense color="black" v-model="appointment.photocall"
              :items="optionsPhotocall" item-text="name" item-value="name" :rules="[$v.appointment.photocall.required]"
              :disabled="disabled" hide-details return-object></v-select>
          </div>
          <div class="input-field">
            <label class="input-field--label">Neón: </label>
            <v-select placeholder="Seleccione un neón" outlined dense color="black" v-model="appointment.neon"
              :items="optionsNeon" item-text="name" item-value="name"
              :disabled="disabled" hide-details return-object></v-select>

          </div>
          <div class="input-field" v-if="appointment.neon && appointment.neon.name === 'Personalizado'">
            <label class="input-field--label">Neón personalizado: </label>

            <v-text-field v-model="appointment.neonCustom" placeholder="Elige el texto" outlined dense color="black"
              class="discount-code--input" hint="Máximo 25 caracteres" :rules="[
                v => (v && v.length <= 25) || 'El texto no puede tener más de 25 caracteres.',
              ]" persistent-hint></v-text-field>
          </div>

          <div class="input-field">
            <label class="input-field--label">Álbum: </label>
            <v-select placeholder="Seleccione un álbum" outlined dense color="black" v-model="appointment.album"
              :items="optionsAlbum" item-text="name" item-value="name" :rules="[$v.appointment.album.required]"
              :disabled="disabled" hide-details return-object></v-select>
          </div>
          <!-- <div class="input-field" >
            <label class="input-field--label">Formato Fotos: </label>
            <v-select placeholder="Seleccione un formato" outlined dense color="black"
              v-model="appointment.plantilla.size" :items="optionsPhotos" item-text="size" item-value="size"
              :rules="[$v.appointment.plantilla.required]" :disabled="disabled" hide-details></v-select>
          </div> -->
          <div class="input-field" >
            <label class="input-field--label">Diseño Fotos: </label>
            <v-select placeholder="Seleccione un diseño" outlined dense color="black" v-model="appointment.plantilla"
              :items="optionsPhotos"  :item-text="getItemText" item-value="id" :rules="[$v.appointment.plantilla.required]"
              :disabled="disabled" hide-details return-object></v-select>
          </div>
          <div class="input-field">
            <label class="input-field--label">Extras: </label>
            <v-select placeholder="Seleccione extras" multiple chips outlined dense color="black"
              v-model="appointment.extras" :items="optionsExtras" item-text="name" item-value="name" :disabled="disabled"
              hide-details return-object></v-select>
          </div>
          <div class="input-field">
            <label class="input-field--label">Extras con descuento: </label>
            <v-select placeholder="Seleccione extras" multiple chips outlined dense color="black"
              v-model="appointment.extrasWithDiscount" :items="optionsExtras?.filter(extra => extra.discount > 0)" item-text="name" item-value="name" :disabled="disabled"
              hide-details return-object></v-select>
          </div>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="navigation-buttons">
          <primary-button appearance="text" text="Cancelar" size="small" v-on:click.native="openModal" />
          <primary-button appearance="solid" text="Guardar" size="small" v-on:click.native="updateInfo"
             />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
// import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'update-configuration-register',
  mixins: [validationMixin],
  props: {
    appointmentValues: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // SectionHeader,
    PrimaryButton,
  },
  data: () => ({
    modal: false,
    appointment: {
      photocall: null,
      neon: null,
      neonCustom: null,
      album: null,
      plantilla: {
        name: null,
        size: null,
      },
      extras: null,
    },
    optionsPhotocall: null,
    optionsNeon: null,
    optionsAlbum: null,
    optionsPhotos: null,
    optionsSize: null,
    optionsExtras: null,
  }),
  validations() {
    const valObj = {
      appointment: {
        photocall: { required },
        neonCustom: {
          lessThanTwenty(value) {
            return !(value && value.length > 20);
          },

        },
        album: { required },
        plantilla: { required },
      },
    };
    return valObj;
  },
  async mounted() {
    if (this.appointmentValues) {
      this.appointment = this.appointmentValues;
    }
    this.getPhotocallOptions();
    this.getNeonOptions();
    this.getAlbumOptions();
    this.getPlantillaOptions();
    this.getExtrasOptions();
  },
  watch: {
    // appointment: {
    //   handler() {
    //     this.updateAppointmentData();
    //   },
    //   deep: true,
    // },
    // 'appointment.plantilla.size': {
    //   handler() {
    //     // Aquí estamos observando los cambios en 'appointment.plantilla.size'
    //     // Cada vez que 'appointment.plantilla.size' cambia, se llama a la función 'updateOptions'
    //     this.appointment.plantilla.name = null;
    //     // La función 'initSwiper1' ha sido comentada, si se necesita, se puede descomentar
    //     // this.initSwiper1();
    //   },
    //   deep: true, // Esta opción permite observar los cambios en las propiedades anidadas de 'appointment.plantilla.size'
    // },
    appointmentValues: {
      handler() {
        this.appointment = this.appointmentValues;
      },
      deep: true,
    },
  },

  methods: {
    getItemText(item) {
      return `${item.name} - ${item.size}`;
    },

    openModal() {
      this.modal = !this.modal;
    },
    nextStep() {
      this.$emit('nextStep', true);
      this.modal = false;
    },
    backStep() {
      this.$emit('backStep', true);
    },
    updateAppointmentData() {
      this.$emit('updateData', this.appointment);
      this.updateAppointmentValid();
    },
    updateAppointmentValid() {
      this.$emit('updateValid', this.$v);
    },
    getPhotocallOptions() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Photocall',
            size: -1,
          },
        })
        .then((response) => {
          this.optionsPhotocall = response.data.content.map((item) => ({
            name: item.name,
            value: item.id,
            img: item.imageUrl,
            id: item.id,
            price: item.price,
          }));
        });
    },
    getNeonOptions() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Neon',
            size: -1,
          },
        })
        .then((response) => {
          this.optionsNeon = response.data.content.map((item) => ({
            name: item.name,
            value: item.id,
            img: item.imageUrl,
            id: item.id,
            price: item.price,
          }));
        });
    },
    getAlbumOptions() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Album',
            size: -1,
          },
        })
        .then((response) => {
          this.optionsAlbum = response.data.content.map((item) => ({
            name: item.name,
            value: item.id,
            img: item.imageUrl,
            id: item.id,
            price: item.price,
          }));

          // Crea el objeto del álbum personalizado
          const albumPersonalizado = {
            name: 'Personalizado',
            value: response.data.content.id,
            img: response.data.content.imageUrl,
            id: response.data.content.id,
            price: response.data.content.price,
            // Agrega las demás propiedades que necesites
          };

          // Agrega el álbum personalizado a optionsAlbum
          this.optionsAlbum.push(albumPersonalizado);

          if (this.appointment.album && this.appointment.album.name === 'Personalizado') {
            // Selecciona el álbum personalizado
            this.appointment.album = albumPersonalizado;
          }
        });
    },
    getPlantillaOptions() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Plantilla',
            size: -1,
          },
        })
        .then((response) => {
          this.optionsPhotos = response.data.content.map((item) => ({
            name: item.name,
            value: item.id,
            img: item.imageUrl,
            id: item.id,
            price: item.price,
            size: item.size,
          }));
        });
    },
    getExtrasOptions() {
      axios
        .get(`${process.env.VUE_APP_API_URL}appointment-prop/by-type`, {
          headers: authHeader(),
          params: {
            type: 'Extras',
            size: -1,
          },
        })
        .then((response) => {
          this.optionsExtras = response.data.content.map((item) => ({
            name: item.name,
            value: item.id,
            img: item.imageUrl,
            id: item.id,
            price: item.price,
            discount: item.discount,
          }));
        });
    },

    async updateInfo() {
      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API_URL}assemblyUnique/configuration/${this.appointment.appointmentId}`,
          {
            photocallId: this.appointment.photocall?.id,
            neonId: this.appointment.neon?.id,
            neonPersonalizado: this.appointment.neon?.name === 'Personalizado' ? this.appointment.neonCustom : '',
            extrasId: this.appointment.extras?.map((extra) => extra.id),
            extrasWithDiscountId: this.appointment.extrasWithDiscount?.map((extra) => extra.id),
            albumId: this.appointment.album?.id,
            plantillaId: this.appointment.plantilla?.id,
          },
          {
            headers: authHeader(),
          },
        );

        // Supongamos que la respuesta contiene las nuevas URLs de las imágenes
        // const newPhotocallImageUrl = response.data.photocall?.imageUrl;
        // const newNeonImageUrl = response.data.neon?.imageUrl;
        // const newAlbumImageUrl = response.data.album?.imageUrl;
        // const newPlantillaImageUrl = response.data.plantilla?.imageUrl;
        // Como 'extras' es un array, necesitamos mapear cada elemento para obtener su 'imageUrl'
        // const newExtrasImageUrls = response.data.extras?.map((extra) => extra.imageUrl);
        // ...

        // Utiliza Vue.set para actualizar las URLs de las imágenes
        // this.$set(this.appointment.photocall, 'imageUrl', newPhotocallImageUrl);
        // this.$set(this.appointment.neon, 'imageUrl', newNeonImageUrl);
        // this.$set(this.appointment.album, 'imageUrl', newAlbumImageUrl);
        // this.$set(this.appointment.plantilla, 'imageUrl', newPlantillaImageUrl);
        this.appointment.neonCustom = response.data.neonPersonalizado;

        // Como 'extras' es un array, necesitamos mapear cada elemento para actualizar su 'imageUrl'
        // this.appointment.extras.forEach((extra, index) => {
        //   this.$set(extra, 'imageUrl', newExtrasImageUrls[index]);
        // });
        // ...
        // this.updateAppointmentData();
      } catch (error) {
        console.log(error);
        this.$router.push('/inscripcion/error');
      } finally {
        this.loading = false;
        this.$router.go();
      }
    },

  },
};
</script>
<style lang="scss" scoped>
.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.v-picker--date {
  margin-bottom: calc(var(--base) * 6);
}

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}

.navigation-buttons {
  padding-top: 0;
  margin-top: calc(var(--base) * 2);
  margin-bottom: calc(var(--base) * 2);
}
</style>
