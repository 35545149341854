<template>
  <v-app>
    <v-navigation-drawer
      v-if="menuVisible && this.$router.currentRoute.meta.auth && this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol != 'ROLE_USER'"
      v-model="drawer" :mini-variant="mini" permanent app
      :color="mini && menuVisible ? $getColor('vuetifyColor4') : 'white'">
      <v-list-item class="p-0 pl-2 pb-0" @click="mini = !mini">
        <v-list-item-avatar>
          <v-avatar :color="$getColor('vuetifyColor1')" size="30" style="margin:0">
            <span class="white--text text-h6">{{ userChar }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-title>
          <div>
            <span>{{
              this.authService.getDecodedJwtToken(this.$store.state.auth.user)
                .sub
            }}</span>
            <!-- <a href="javascript:;" @click="logout">Logout</a> -->
          </div>
        </v-list-item-title>
      </v-list-item>
      <!--

        <v-list-item class="sidebar-project-selector" @click="checkCollapse()">
        <v-icon>{{ this.$store.state.projectIcon }}</v-icon>
        <span class="subtitle-2 ml-3">{{ this.$store.state.project }}</span>
            <v-list>
            <project-selection-nav></project-selection-nav>
          </v-list>
      </v-list-item>

       -->

      <v-divider class="user-divider"></v-divider>
      <v-list nav dense>
        <v-list-item v-for="item in userRoutes" :key="item.title" :to="item.path" link
          :color="$getColor('vuetifyColor1')">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list nav dense>
        <div v-if="adminRoutes.length > 0" v-show="!mini" class="subtitle-2 ml-4 mb-2">Administración</div>
        <v-list-item v-for="item in adminRoutes" :key="item.title" :to="item.path" link
          :color="$getColor('vuetifyColor1')">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="this.$router.currentRoute.meta.auth"
      :color="mini && menuVisible ? 'white' : $getColor('vuetifyColor4')" dense light elevation="0" app>
      <v-btn v-if="menuVisible && this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol != 'ROLE_USER'"
        icon @click.stop="mini = !mini">
        <v-icon>{{ mini ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon>
      </v-btn>
      <v-btn v-if="this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol != 'ROLE_USER'" icon
        @click.stop="menuVisible = !menuVisible">
        <v-icon>{{ menuVisible ? "menu_open" : "menu" }}</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span><img alt="app-logo" width="40px" class="m-1" src="./assets/logo.png" />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="demo-badge">
        <span class="md-body-2 color6 md-alignment-right flex-right">
          {{
            this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub
          }}</span><!-- <a href="javascript:;" @click="logout" class="flex-right">Logout</a> -->
      </div>
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="logout" icon v-on="on">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Cerrar sesión</span>
        </v-tooltip>
      </template>
      <v-btn icon x-large @click="goToUserPanel()">
        <v-avatar :color="$getColor('vuetifyColor1')" size="40">
          <span class="white--text text-h5">{{ userChar }}</span>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main style="height:100%">
      <!-- Provides the application the proper gutter -->

      <v-navigation-drawer v-if="this.$router.currentRoute.meta.children.length > 0 ||
        this.$router.currentRoute.meta.parent
        " v-model="subdrawer" :mini-variant="submini" clipped width="300" :color="$getColor('vuetifyNavSecondary')">
        <v-list-item class="px-2">
          <v-list-item-content v-if="!submini">
            <v-list-item-title class="text-h6">
              {{ this.$router.currentRoute.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ parentName }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn icon @click.stop="submini = !submini">
            <v-icon>{{
              submini ? "mdi-chevron-right" : "mdi-chevron-left"
            }}</v-icon>
          </v-btn>
        </v-list-item>
        <v-list dense>
          <v-list-item v-for="item in childrenRoutes" :key="item.title" :to="item.path" link
            :color="$getColor('vuetifyColor1')">
            <v-list-item-icon>
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-container fluid v-if="this.$router.currentRoute.meta.auth" :class="{'public-app-content': this.$route.path === '/reserva-manual' || this.$route.path === '/mi-reserva' || this.$route.name === 'AppointmentEdit' || this.$route.path === '/mi-reserva/pendiente-de-personalizacion' || this.$route.path === '/mi-reserva/personalizar'}">
        <!-- Show breadcrumbs if view not equal to Home -->
        <div class="breadcrumbs-container" v-if="this.$router.currentRoute.name !== 'Home' && this.$router.currentRoute.name !== 'UserPanel' && this.$router.currentRoute.name !== 'UserPanelFastRegister'
          && this.$router.currentRoute.name !== 'Appointments Panel' && this.$router.currentRoute.name !== 'ManualRegister'
          && this.$router.currentRoute.name !== 'AppointmentConfiguration' && this.$router.currentRoute.name !== 'AppointmentEdit'">
          <v-breadcrumbs :items="breadcrumbsItems">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:itemt="{ itemt }">
              <v-breadcrumbs-item :to="{ name: itemt.name }" :disabled="itemt.disabled">
                {{ itemt }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
      <v-container fluid v-else class="public-app-content">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import ProjectSelectionNav from './components/Nav/ProjectSelectionNav.vue';
// import auth from './store/auth.module';

// eslint-disable-next-line no-unused-vars
import authService from './services/auth.service';

export default {
  components: {
    // ProjectSelectionNav,
  },
  data: () => ({
    drawer: true,
    mini: false,
    miniDyn: true,
    username: null,
    menuVisible: true,
    expandSingle: true,
    expandNews: false,
    authService,
    avatarClass: 'md-avatar-icon md-small',
    userrole: null,

    subdrawer: true,
    submini: true,
  }),
  computed: {
    breadcrumbsItems() {
      // get current route
      const route = this.$route;
      const breadcrumbs = [];
      // get route path
      const { path } = route;
      // get route path segments
      const segments = path.split('/');
      // get route path segments length
      const segmentsLength = segments.length;

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < segmentsLength - 1; i++) {
        const segment = segments[i];
        const segmentName = segment.charAt(0).toUpperCase() + segment.slice(1);
        const segmentTitle = segmentName.replace(/-/g, ' ');
        const to = `/${segments.slice(0, i + 1).join('/')}`;
        breadcrumbs.push({
          text: segmentTitle,
          disabled: true,
          to,
        });
      }
      breadcrumbs.push({
        text: route.meta.label,
        disabled: true,
        to: '/',
      });
      breadcrumbs.unshift({
        text: 'Inicio',
        disabled: false,
        to: '/',
      });
      return breadcrumbs;
    },
    userChar() {
      if (this.authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        const name = this.authService.getDecodedJwtToken(
          this.$store.state.auth.user,
        ).sub;
        return name.substring(1, 0).toUpperCase();
      }
      return 'A';
    },
    userRoutes() {
      // returb filter by nav_available & admin_role true
      return this.$router.options.routes.filter(
        (i) => i.nav_available === true && i.admin_role === false
          && this.$store.getters['auth/hasRole'](i.meta.roles),
      );
    },
    adminRoutes() {
      return this.$router.options.routes.filter(
        (i) => i.nav_available === true && i.admin_role === true
          && this.$store.getters['auth/hasRole'](i.meta.roles),
      );
    },
    parentName() {
      const { parent } = this.$route.meta;
      if (parent) {
        return parent;
      }
      return 'Submenu';
    },
    childrenRoutes() {
      // get current route
      const route = this.$route;
      // check if has parent in meta
      if (route.meta.parent) {
        // get parent route
        const parentRoute = this.$router.options.routes.find(
          (i) => i.name === route.meta.parent,
        );
        // get parent childrens
        const { children } = parentRoute.meta;
        // get children routes
        const childrenRoutes = children.map((child) => {
          const croute = this.$router.options.routes.find(
            (i) => i.name === child.name,
          );
          return {
            name: croute.meta.label,
            path: croute.path,
            icon: croute.icon,
          };
        });
        return childrenRoutes;
      }
      if (route.meta.children) {
        const childrenRoutes = route.meta.children.map((child) => {
          const croute = this.$router.options.routes.find(
            (i) => i.name === child.name,
          );
          return {
            name: croute.meta.label,
            path: croute.path,
            icon: croute.icon,
          };
        });
        return childrenRoutes;
      }
      return null;
    },
    currentRoute() {
      return this.$router.currentRoute;
    },
  },
  mounted() {
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      this.onResize();
    });
    window.addEventListener('resize', this.onResize);
  },

  methods: {
    setDocumentHeightVariable() {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`,
      );
    },
    onResize() {
      this.setDocumentHeightVariable();
    },
    goToUserPanel() {
      this.$router.push({ name: 'UserPanel' });
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('clearProject');
      this.$router.push('/login');
    },
    checkCollapse() {
      if (!this.menuVisible) {
        this.toggleMenu();
      }
    },
    toggleExpand() {
      this.expandNews = !this.expandNews;
    },

  },

  watch: {
    menuVisible(val) {
      if (val) {
        this.avatarClass = 'md-avatar-icon';
      } else {
        this.avatarClass = 'md-avatar-icon md-small';
        this.expandNews = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-main__wrap {
  height: var(--app-height);
}

// * {
//   border: solid 1px red;
// }
.v-list-item__avatar {
  height: calc(var(--base)*5);
  width: calc(var(--base)*5);
  margin: 0;
}

.user-divider {
  margin-top: 0;
}
</style>
