<template>
  <button
    v-bind:class="{ selected: isSelected, enabled: isEnabled }"
    class="check-selector"
  >
    <div class="icon" v-if="isSelected">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66669 8L6.66669 12L13.3334 4"
          stroke="#74A07D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="content">
      <div class="first-line">{{ title }}</div>
      <div class="second-line" v-if="isEnabled">{{ subtitle }}</div>
      <div class="second-line" v-else>No disponible</div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isSelected: this.selected,
      isEnabled: this.enabled,
    };
  },
  methods: {
    updateEnabled(val) {
      this.isEnabled = val;
    },
    updateSelected(val) {
      this.isSelected = val;
    },
  },

};
</script>

<style lang="scss" scoped>
.check-selector {
  width: auto;
  border-radius: 50000px;
  display: flex;
  column-gap: var(--base);
  justify-content: center;
  align-items: center;
  padding: calc(var(--base) * 2);
  padding-top:calc(var(--base)*1.6);
  pointer-events: none;
  background-color: rgba(var(--bodyColor), 0.06);
  color: rgba(var(--bodyColor), 0.2);
  border: 1px solid rgba(var(--bodyColor), 0);
  text-align: center;
  &.enabled {
    pointer-events: all;
    cursor: pointer;
    background-color: rgba(#090909, 0.03);
    color: rgba(var(--bodyColor), 0.6);
    border: 1px solid rgba(var(--bodyColor), 0.1);
  }
  &.selected {
    background-color: var(--colorGreen);
    color: var(--bodyColor);
    border: 1px solid rgba(var(--bodyColor), 0.1);
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .first-line {
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 2.5);
    }
    .second-line {
      margin: 0;
      padding: 0;
      font-size: calc(var(--base) * 1.5);
      line-height: 1;
    }
  }
}
</style>
