<template>
  <div class="page-layout">
    <section class="main-container">
      <primary-button
        class="top-button"
        appearance="solid"
        text="Guardar"
        size="small"
        v-on:click.native="nextStep"
        :enabled="!buttonClicked"
      />
      <div class="main-container--inner no-scrollbar">
        <section-header first="El resumen de" second="Tu pedido" />
        <preview-canvas :previewImg="previewImg" v-show="isMobile" />
        <div class="field-group chosen-config-group">
          <label for="" class="field-group--label"> Configuración elegida: </label>
          <div
            ref="swiperFinal"
            class="option-selector--swiper swiper"
            :class="{ loading: !this.swiperLoaded }"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <selection-card
                v-if="photocallData.photocall"
                :name="photocallData.photocall.name"
                class="swiper-slide customGreen"
                size="small"
                :price="photocallData.photocall.price"
                :img="photocallData.photocall.img"
                v-on:click.native="setValue(photocallData.photocall.img)"
                selected
              />
              <selection-card
                v-if="photocallData.neon"
                :name="photocallData.neon.name"
                class="swiper-slide customGreen"
                size="small"
                :price="photocallData.neon.price"
                :img="photocallData.neon.img"
                v-on:click.native="setValue(photocallData.neon.img)"
                selected
              />
              <selection-card
                v-if="albumData.album"
                :name="albumData.album.name"
                class="swiper-slide customPurple"
                size="small"
                :price="albumData.album.price"
                :img="albumData.album.img"
                v-on:click.native="setValue(albumData.album.img)"
                selected
              />
              <selection-card
                v-if="photosData.photos"
                :name="photosData.photos.name"
                class="swiper-slide customPink"
                size="small"
                :price="photosData.photos.price"
                :img="photosData.photos.img"
                v-on:click.native="setValue(photosData.photos.img)"
                selected
              />
              <selection-card
                v-for="item in extrasData.extras"
                :key="item.id"
                :name="item.name"
                class="swiper-slide customRed"
                size="small"
                :price="item.price"
                :img="item.img"
                v-on:click.native="setValue(item.img)"
                selected
              />
            </div>
          </div>
        </div>
        <v-divider> </v-divider>
        <div class="field-group">
          <label for="" class="field-group--label"> Resumen: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Fecha:</span>
              <span class="data--value">{{ this.checkAvailabilityData.eventTime }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Horario:</span>
              <span class="data--value">{{
                this.getTypeLabel(this.checkAvailabilityData.type)
              }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Lugar del evento:</span>
              <span class="data--value">{{ this.checkRegisterData.name }}</span>
            </div>
            <!-- <div class="data--item" v-if="this.checkRegisterData.postalCodeItem">
              <span class="data--label">Código Postal:</span>
              <span class="data--value">{{ this.checkRegisterData.postalCodeItem.codigopostalid ? this.checkRegisterData.postalCodeItem.codigopostalid :  this.checkRegisterData.postalCodeItem.id }}</span>
            </div> -->
          </div>
        </div>
        <v-divider v-if="isMobile && this.prices"> </v-divider>
        <div class="field-group" v-if="isMobile && this.prices">
          <label for="" class="field-group--label">Presupuesto:</label>
          <prices-component
            v-if="isMobile && this.prices"
            :prices="prices"
            :isPaid="isPaid"
          ></prices-component>
        </div>
        <v-divider v-if="isMobile && this.prices"> </v-divider>
        <div class="field-group mb-0">
          <label for="" class="field-group--label"> Código de descuento: </label>
          <div class="discount-code--container">
            <v-text-field
              v-if="coupon && coupon.code"
              :placeholder="coupon.code + ' (' + coupon.discount + '€ de descuento)'"
              outlined
              hide-details
              dense
              color="black"
              class="discount-code--input"
              :disabled="true"
            ></v-text-field>
            <v-text-field
              v-if="!coupon || !coupon.code"
              placeholder="Sin cupón de descuento"
              hide-details
              dense
              flat
              solo
              color="black"
              class="discount-code--input"
              :disabled="true"
            ></v-text-field>
          </div>
        </div>
        <v-divider> </v-divider>

        <div class="field-group">
          <label for="" class="field-group--label"> Datos Personales: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre:</span>
              <span class="data--value">{{ personalData.name }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Correo electrónico:</span>
              <span class="data--value">{{ personalData.email }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Teléfono:</span>
              <span class="data--value">{{ personalData.phone }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">DNI/CIF:</span>
              <span class="data--value">{{ personalData.idDocument }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Domicilio:</span>
              <span class="data--value">{{ personalData.streetName }}</span>
            </div>
          </div>
        </div>
        <v-divider> </v-divider>

        <div class="field-group">
          <label for="" class="field-group--label"> Datos del evento: </label>
          <div class="data--container">
            <div class="data--item">
              <span class="data--label">Nombre de la pareja:</span>
              <span class="data--value">{{ infoData.namePartners }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Apellido de la novia:</span>
              <span class="data--value">{{ infoData.lastName1 }}</span>
            </div>
            <!--
              <div class="data--item">
              <span class="data--label">Nombre de la finca:</span>
              <span class="data--value">{{ infoData.namePlace }}</span>
            </div>
             -->

            <div class="data--item">
              <span class="data--label">Dirección de la finca:</span>
              <span class="data--value">{{ infoData.streetPlace }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Teléfono de contacto el día de la boda:</span>
              <span class="data--value">{{ infoData.phone2 }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Número aproximado de invitados:</span>
              <span class="data--value">{{ infoData.guestsNumber }}</span>
            </div>
            <div class="data--item">
              <span class="data--label">Hora aproximada:</span>
              <span class="data--value">{{ infoData.time }}</span>
            </div>
          </div>
        </div>
        <div class="navigation-buttons">
          <primary-button
            appearance="text"
            text="Atrás"
            size="small"
            v-on:click.native="backStep"
          />
          <primary-button
            appearance="solid"
            text="Guardar"
            size="small"
            v-on:click.native="nextStep"
            :enabled="!buttonClicked"
          />
        </div>
      </div>
    </section>
    <section class="aside-container">
      <div class="logo-container">
        <h1 class="first-line">Funpic<sup>®</sup></h1>
      </div>

      <preview-canvas :previewImg="previewImg"></preview-canvas>
      <prices-component v-if="this.prices" :prices="prices" :isPaid="isPaid"></prices-component>
    </section>
  </div>
</template>
<script>
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
// import SelectionButton from '@/components/Buttons/SelectionButton.vue';
import PreviewCanvas from '@/components/Canvas/PreviewCanvas.vue';
import SelectionCard from '@/components/Cards/SelectionCard.vue';
import Swiper, { Navigation, Pagination } from 'swiper';
import PricesComponent from '@/components/Cards/Prices.vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'summary-configuration-view',
  components: {
    SectionHeader,
    PrimaryButton,
    PreviewCanvas,
    // SelectionButton,
    SelectionCard,
    PricesComponent,
  },
  watch: {
    // watch immagePreview
    previewImg() {
      if (this.previewImg && !this.swiperLoaded) {
        this.swiperLoaded = true;
        this.initSwiperFinal();
      }
    },
  },
  computed: {
    // computed property to check if window is mobile or not
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
  },
  methods: {
    setValue(value) {
      this.previewImg = value;
    },
    initSwiperFinal() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiperFinal, {
        modules: [Navigation, Pagination],
        grabCursor: true,
        draggable: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        pagination: {
          el: '.swiper-pagination',
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    getTypeLabel(type) {
      switch (type) {
        case 'morning':
          return 'Boda de comida (Inicio antes de las 19:15)';
        case 'evening':
          return 'Boda de noche (Inicio después de 23:29)';
        default:
          return 'No disponible';
      }
    },
    nextStep() {
      if (!this.buttonClicked) {
        this.buttonClicked = true;
        this.$emit('nextStep', true);
        // Aquí puedes agregar cualquier lógica adicional para el manejo del clic
      }
    },
    backStep() {
      this.$emit('backStep', true);
    },

    updateData(
      checkAvailabilityData,
      checkRegisterData,
      photocallData,
      albumData,
      photosData,
      extrasData,
      personalData,
      infoData,
      coupon,
    ) {
      this.checkAvailabilityData = checkAvailabilityData;
      this.checkRegisterData = checkRegisterData;
      this.photocallData = photocallData;
      this.albumData = albumData;
      this.photosData = photosData;
      this.extrasData = extrasData;
      this.personalData = personalData;
      this.infoData = infoData;
      this.coupon = coupon;
    },
    updatePrices(val) {
      this.prices = val;
    },
  },
  data() {
    return {
      buttonClicked: false,
      swiperLoaded: false,
      checkAvailabilityData: {
        type: null,
        eventTime: null,
        totalOnSelectedDate: null,
      },
      checkRegisterData: {
        name: null,
        postalCode: null,
        direction: null,
        postalCodeItem: null,
        distance: null,
      },
      photocallData: {
        photocall: null,
        neon: null,
      },
      albumData: {
        album: null,
      },
      photosData: {
        photos: null,
        size: null,
      },
      extrasData: {
        extras: null,
      },
      personalData: {
        name: null,
        email: null,
        phone: null,
        idDocument: null,
        streetName: null,
      },
      infoData: {
        namePartners: null,
        lastName1: null,
        namePlace: null,
        streetPlace: null,
        phone2: null,
        guestsNumber: null,
        time: null,
      },
      coupon: null,
      prices: null,
      previewImg: null,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
// *{
//   border: solid 1px red;
// }

.configuration-menu {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--base) * 1);
  margin-bottom: calc(var(--base) * 6);
}

.check-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: calc(var(--base) * 2);
  margin-bottom: auto;
}

.slider {
  max-height: calc(var(--base) * 20);
  width: 100%;
}

.option-selector--container {
  margin-bottom: calc(var(--base) * 3);
}

.page-layout {
  @media (min-width: 1024px) {
    background-color: var(--colorGrey);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 2);
}

.swiper {
  @media (min-width: 1024px) {
    &::after {
      background: linear-gradient(
        270deg,
        rgba(var(--colorGreyRGB), 1) 0%,
        rgba(var(--colorGreyRGB), 0) 80%
      );
    }

    &::before {
      background: rgba(var(--colorGreyRGB), 0.7) !important;
    }
  }
}

.main-container {
  overflow-y: hidden;
  position: relative;
  &::after {
    position: absolute;
    content: "Scroll para ver más";
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--base) * 10);
    background: linear-gradient(180deg, rgba(var(--bodyBg), 0) 0%, rgba(var(--bodyBg), 1) 70%);
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(var(--bodyColor), 0.5);
    padding-bottom: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      background: linear-gradient(
        180deg,
        rgba(var(--colorGreyRGB), 0) 0%,
        rgba(var(--colorGreyRGB), 1) 70%
      );
    }
  }

  .main-container--inner {
    overflow-y: scroll;
    padding-bottom: calc(var(--base) * 5) !important;
  }
}

.top-button {
  position: absolute;
  top: calc(var(--base) * 2);
  right: calc(var(--base) * 2);
  max-width: calc(var(--base) * 15);
  z-index: 20;
}
</style>
